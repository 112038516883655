import useCaminoStore from "hooks/useCaminoStore";
import { Navigate } from "react-router-dom";
import ROLE from "models/role";
import {
  MANAGER_ROUTES,
  ONBOARDER_ROUTES,
  PEOPLE_TEAM_ROUTES,
  SHARED_ROUTES,
} from "definitions/constants/routeConstants";

export default function Home() {
  const currentUser = useCaminoStore((state) => state.currentUser);

  if (currentUser?.permission?.includes(ROLE.PEOPLE_TEAM)) {
    return <Navigate to={PEOPLE_TEAM_ROUTES.dashboard} replace />;
  } else if (currentUser?.permission?.includes(ROLE.MANAGER)) {
    return <Navigate to={MANAGER_ROUTES.dashboard} replace />;
  } else if (currentUser?.permission?.includes(ROLE.ONBOARDER)) {
    return <Navigate to={ONBOARDER_ROUTES.dashboard} replace />;
  } else if (currentUser?.permission?.includes(ROLE.BASIC)) {
    return <Navigate to={SHARED_ROUTES.hangout} replace />;
  }
  return <Navigate to={SHARED_ROUTES.hangout} replace />;
}

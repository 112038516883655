import {
  Action,
  DayOfWeek,
  PathActionable,
  PathDependencyTriggerData,
  PathRelationTarget,
  PathTimingTriggerData,
  PathTrigger,
  PathTriggerType,
} from "models/automation";
import { TaskType } from "models/task";
import { capitalize } from "lodash";
import { GoMail, GoCommentDiscussion, GoChecklist, GoQuestion } from "react-icons/go";
import { TbBellCheck } from "react-icons/tb";
import { BsSendCheck, BsPersonPlusFill } from "react-icons/bs";
import { PiNotePencilDuotone } from "react-icons/pi";
import { IoTrailSignOutline } from "react-icons/io5";
import { RiChatForwardLine } from "react-icons/ri";
import { DeepPartial } from "react-hook-form";
import { TASK_TYPES } from "definitions/constants";
import { User } from "models/user";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { titleize } from "./string";
import { statusDateTime } from "./status";

const READABLE_REFERENCE_EVENT_MAPPER = {
  start_date: "Start Date",
  immediate: "Immediate",
  offer_sign_date: "Offer Sign Date",
};

const READABLE_UNITS_MAPPER = {
  week: "Week",
  month: "Month",
  day: "Day",
  specific_day: "",
};

export const RELATION_TARGET_MAPPER = {
  people_team_contact: "People Team",
  manager: "Manager",
  buddy: "Buddy",
  new_hire: "New Hire",
};

export const EMAIL_TYPE_MAPPER = {
  personal_email: "Personal",
  work_email: "Work",
};

const DAY_OF_WEEK_TO_INT = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

interface ActionableType {
  actionType: PathActionable["actionType"] | "task_notifier" | "task_reminder";
  taskType?: TaskType;
}

export function emailTypeConverter(emailType: keyof typeof EMAIL_TYPE_MAPPER) {
  return EMAIL_TYPE_MAPPER[emailType];
}

export function actionableTypeConverter(actionable: ActionableType) {
  const { actionType, taskType } = actionable;
  if (["task", "task_notifier"].includes(actionType) && taskType && taskType !== "custom") {
    if (taskType === "send_message") {
      return "Send Message Prompt";
    }
    return titleize(taskType);
  }
  return titleize(actionType);
}

actionableTypeConverter.defaultProps = {
  taskType: null,
};

export function relationTargetConverter(relationTarget: PathRelationTarget) {
  return RELATION_TARGET_MAPPER[relationTarget];
}

const offsetUnitConverter = (offset: number, offsetUnit: keyof typeof READABLE_UNITS_MAPPER, dayOfWeek?: DayOfWeek) => {
  const readableUnit = READABLE_UNITS_MAPPER[offsetUnit];
  return `${Math.abs(offset)} ${capitalize(dayOfWeek) || readableUnit}${Math.abs(offset) === 1 ? "" : "s"}`;
};

function timingTriggerText(timing: PathTimingTriggerData) {
  const { offset, offsetUnit } = timing;
  const dayOfWeek = offsetUnit === "specific_day" ? timing.dayOfWeek : undefined;
  const stringReferenceEvent = READABLE_REFERENCE_EVENT_MAPPER[timing.referenceEvent];
  if (offset === 0) {
    return `${timing.referenceEvent === "immediate" ? "" : "On "}${stringReferenceEvent}`;
  }
  return `${offsetUnitConverter(offset, offsetUnit, dayOfWeek)} ${
    offset < 0 ? "Before" : "After"
  } ${stringReferenceEvent}`;
}

export function automationTriggerText(trigger: PathTrigger) {
  switch (trigger?.type) {
    case "timing":
      return timingTriggerText(trigger.data);
    case "dependency":
      return "Task Completion";
    default:
      return "";
  }
}

export function taskTypeToIcon(taskType: TaskType) {
  switch (taskType) {
    case TASK_TYPES.ASSIGN_BUDDY:
      return BsPersonPlusFill;
    case TASK_TYPES.CREATE_EMAIL_ACCOUNT:
      return PiNotePencilDuotone;
    case TASK_TYPES.CREATE_ONBOARDING_PLAN:
      return IoTrailSignOutline;
    case TASK_TYPES.SCHEDULE_MEETING:
      return GoMail;
    case TASK_TYPES.SEND_MESSAGE_PROMPT:
      return RiChatForwardLine;
    default:
      return GoChecklist;
  }
}

export function actionableTypeToIcon(actionable: ActionableType) {
  const { actionType, taskType } = actionable;
  switch (actionType) {
    case "chat":
      return GoCommentDiscussion;
    case "email":
      return GoMail;
    case "task":
      return taskTypeToIcon(taskType);
    case "task_notifier":
      if (taskType && taskType !== "custom") {
        return taskTypeToIcon(taskType);
      }
      return BsSendCheck;
    case "task_reminder":
      return TbBellCheck;
    default:
      return GoQuestion;
  }
}

function timeOfDayToNumber(timeOfDay: string): number {
  if (!timeOfDay) {
    return 0;
  }
  const [hours = 0, minutes = 0, seconds = 0] = timeOfDay.split(":").map(Number);
  // Convert time to total seconds since midnight
  return hours * 3600 + minutes * 60 + seconds;
}

function dayOfWeekConverter(day: keyof typeof DAY_OF_WEEK_TO_INT) {
  return DAY_OF_WEEK_TO_INT[day];
}

function timingDataToNumber({ referenceEvent, offset, timeOfDay, ...timing }: PathTimingTriggerData) {
  const { offsetUnit } = timing;
  let startingNumber = 0;

  if (referenceEvent === "immediate") {
    // if its the signing date, they should be grouped together at the beginning, so minus 1000
    // to get them in a whole nother ballgame
    startingNumber -= 1000;
  }
  if (offsetUnit !== "specific_day" && offset === 0) {
    // if its exactly on the date, return it, bc it doesnt have anything else to calculate
    return startingNumber;
  }

  if (offsetUnit === "day") {
    startingNumber += offset;
  } else if (offsetUnit === "week") {
    startingNumber += offset * 7;
  } else if (offsetUnit === "month") {
    // not exact but fine bc this is just for sorting
    startingNumber += offset * 30;
  } else if (offsetUnit === "specific_day" && timing.dayOfWeek) {
    startingNumber += offset * 7;
    // there will be a bug if someone puts in Sunday for week 1 it will be the same value as no-specific day, week 1
    // dont want to deal with it now
    startingNumber += dayOfWeekConverter(timing.dayOfWeek);
  }

  // Add timeOfDay if it exists
  if (timeOfDay) {
    startingNumber += timeOfDayToNumber(timeOfDay) / (24 * 3600); // Normalize to fraction of a day
  }

  return startingNumber;
}

function triggerDataToNumber(trigger: PathTrigger) {
  switch (trigger?.type) {
    case "timing":
      return timingDataToNumber(trigger.data);
    case "dependency":
      return 1000;
    default:
      return 0;
  }
}

export function workflowTriggerSort<T>(a: T, b: T, transform: (value: T) => PathTrigger) {
  return triggerDataToNumber(transform(a)) < triggerDataToNumber(transform(b)) ? 1 : -1;
}

function fromPathTiming(timing: PathTimingTriggerData) {
  const { offset, ...rest } = timing;
  let offsetDirection = "exact";
  if (offset > 0) {
    offsetDirection = "after";
  } else if (offset < 0) {
    offsetDirection = "before";
  }

  return {
    ...rest,
    offsetDirection,
    offsetValue: Math.abs(offset),
  };
}

export type PartialPathTrigger = Pick<PathTrigger, "type"> & DeepPartial<Pick<PathTrigger, "data">>;

export function fromPathTrigger(trigger: PathTrigger): PartialPathTrigger {
  switch (trigger?.type) {
    case "timing":
      return { ...trigger, data: fromPathTiming(trigger.data) };
    default:
      return trigger;
  }
}

function toPathTiming(
  timing: Partial<
  Pick<PathTimingTriggerData, "referenceEvent" | "timeOfDay" | "timezone" | "offsetUnit"> & {
    offsetValue: number;
    offsetDirection: "before" | "exact" | "after";
    dayOfWeek: DayOfWeek;
  }
  >,
): Partial<PathTimingTriggerData> {
  const { offsetValue, offsetDirection, ...rest } = timing;
  return {
    ...rest,
    offset: offsetDirection === "exact" ? 0 : (offsetValue ?? 0) * (offsetDirection === "before" ? -1 : 1),
  };
}

type LocalPathTimingTrigger = {
  type: Extract<PathTriggerType, "timing">;
  data: Partial<
  Required<Pick<PathTimingTriggerData, "referenceEvent" | "timeOfDay" | "timezone" | "offsetUnit">> & {
    offsetValue: number;
    offsetDirection: "before" | "exact" | "after";
    dayOfWeek: DayOfWeek;
  }
  >;
};

type LocalPathDependencyTrigger = {
  type: Extract<PathTriggerType, "dependency">;
  data: DeepPartial<PathDependencyTriggerData>;
};

type LocalPathTrigger = LocalPathTimingTrigger | LocalPathDependencyTrigger;

type ReturnPathTrigger = Required<Pick<PathTrigger, "type">> & DeepPartial<Pick<PathTrigger, "data">>;

export function toPathTrigger(trigger: LocalPathTrigger): ReturnPathTrigger {
  switch (trigger.type) {
    case "timing":
      return { type: trigger.type, data: toPathTiming(trigger.data) };
    case "dependency":
      return trigger as ReturnPathTrigger;
    default:
      console.error("Invalid trigger type", trigger.type);
      return { type: "timing" };
  }
}

export function assignedActionTargetText(assignedAction: AssignedAction) {
  if (!assignedAction) {
    return null;
  }

  const { targetable } = assignedAction;
  const { relationTarget, specificTargetable } = assignedAction.workflowAction.actionable;
  if (relationTarget) {
    return `${(targetable as User)?.fullName || "tbd"} (${relationTargetConverter(relationTarget)})`;
  } else if (specificTargetable) {
    if ("fullName" in specificTargetable) {
      return specificTargetable.fullName;
    } else if ("channelType" in specificTargetable) {
      return `#${specificTargetable.name}`;
    }
  }
  return null;
}

interface TargetText {
  relationTarget: Action["actionable"]["relationTarget"];
  specificTargetable: Action["actionable"]["specificTargetable"];
}

export function targetText({ relationTarget, specificTargetable }: TargetText) {
  if (relationTarget) {
    return relationTargetConverter(relationTarget);
  } else if (specificTargetable) {
    if ("fullName" in specificTargetable) {
      return specificTargetable.fullName;
    } else if ("channelType" in specificTargetable) {
      return `#${specificTargetable.name}`;
    }
  }
  return null;
}

export function actionTargetText(action: Action) {
  if (!action) {
    return null;
  }
  const { relationTarget, specificTargetable } = action.actionable;
  return targetText({ relationTarget, specificTargetable });
}

function actionTypeToPlain(assignedAction: AssignedAction) {
  switch (assignedAction.actionType) {
    case "task_notifier":
      return "Notification";
    case "task_reminder":
      return "Reminder";
    default:
      return titleize(assignedAction.actionType);
  }
}

export function assignedActionStatusDisplay(assignedAction: AssignedAction) {
  const plainActionType = actionTypeToPlain(assignedAction);
  switch (assignedAction.status) {
    case "processed":
      return {
        plainActionType,
        status: "Received",
        backgroundColor: "gray",
        datetime: statusDateTime(assignedAction.statusUpdatedAt),
      };
    case "skipped":
      return {
        plainActionType,
        status: "Skipped",
        backgroundColor: "gray",
        datetime: statusDateTime(assignedAction.statusUpdatedAt),
      };
    case "error":
      return {
        plainActionType,
        status: "Errored",
        backgroundColor: "red",
        datetime: statusDateTime(assignedAction.statusUpdatedAt),
      };
    case "awaiting_info":
    case "needs_attention":
    case "ready":
      if (assignedAction.trigger?.data?.datetime) {
        return {
          plainActionType,
          status: "Scheduled",
          backgroundColor: "gray",
          datetime: statusDateTime(assignedAction.trigger?.data?.datetime),
        };
      }
      return { plainActionType, status: "Scheduling", backgroundColor: "gray", datetime: "TBD" };
    default:
      return {};
  }
}

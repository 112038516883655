import {
  Box,
  BoxProps,
  Flex,
  Stack,
  StackProps,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxGroupProps,
  UseCheckboxProps,
  useId,
  useStyleConfig,
} from "@chakra-ui/react";
import {
  Children, cloneElement, isValidElement, ReactElement, useMemo,
} from "react";

type CheckboxCardGroupProps = StackProps & UseCheckboxGroupProps;

export function CheckboxCardGroup({
  children, defaultValue, value, onChange, ...rest
}: CheckboxCardGroupProps) {
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue,
    value,
    onChange,
  });

  const cards = useMemo(
    () => Children.toArray(children)
      .filter<ReactElement<RadioCardProps>>(isValidElement)
      .map((card) => cloneElement(card, { checkboxProps: getCheckboxProps({ value: card.props.value }) })),
    [children, getCheckboxProps],
  );

  return <Stack {...rest}>{cards}</Stack>;
}

interface RadioCardProps extends BoxProps {
  value: string;
  // eslint-disable-next-line react/require-default-props
  checkboxProps?: UseCheckboxProps;
  containerProps?: object;
}

export function CheckboxCard(props: RadioCardProps) {
  const { checkboxProps, containerProps, children, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps } = useCheckbox(checkboxProps);
  const id = useId(undefined, "checkbox-card");
  const styles = useStyleConfig("RadioCard", props);

  return (
    <Box
      as="label"
      cursor="pointer"
      {...getLabelProps()}
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}
      {...containerProps}
    >
      <input {...getInputProps()} aria-labelledby={id} />
      <Flex align="stretch" sx={styles} {...getCheckboxProps()} {...rest}>
        {children}
      </Flex>
    </Box>
  );
}

CheckboxCard.defaultProps = {
  checkboxProps: {},
  containerProps: {},
};

import { create } from "zustand";

export interface Notification {
  id: string;
  title: string;
  message: string;
  action: string;
  createdAt: string;
  readAt: Date | null;
}

interface NotificationStore {
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
  markAsRead: (notification: Notification) => void;
  unreadCount: () => number;
}
const useNotificationStore = create<NotificationStore>((set, get) => ({
  notifications: [
    {
      id: "asdflkjbviouzxcvsdf",
      title: "Welcome to Camino!",
      message: "Check out what's waiting for you!",
      createdAt: "2023-01-01T00:00:00.000Z",
      action: "navigate.newhire-welcome-flow",
      readAt: null,
    },
  ],
  setNotifications: (notifications: Notification[]) => set({ notifications }),
  markAsRead: (notification: Notification) => set((state) => ({
    notifications: state.notifications.map((n) => (n.id === notification.id ? { ...n, readAt: new Date() } : n)),
  })),
  unreadCount: () => get().notifications.filter((notification) => !notification.readAt).length,
}));

export default useNotificationStore;

import {
  HStack, Card, CardBody, Text, LinkBox, LinkOverlay, Box, VStack, Icon,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { GrDuplicate } from "react-icons/gr";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { OnboardingPlan } from "models/onboarding";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { DUPLICATE_ONBOARDING_PLAN_FEATURE_FLAG } from "definitions/constants";

export default function NewOnboardingPlanStep() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate } = useMutation(() => apiClient.post(API_ROUTES.onboardingPlans.base, {}), {
    onSuccess: async (onboardingPlan: OnboardingPlan) => {
      queryClient.setQueryData(["onboardingPlans", onboardingPlan.id], onboardingPlan);
      navigate(generatePath(SHARED_COMPANY_ROUTES.buildOnboardingPlan, { id: onboardingPlan.id }));
    },
    onError: (mutateError) => {
      console.log("mutateError");
      console.log(mutateError);
    },
  });

  const createNewOnboardingPlan = () => {
    mutate();
  };

  return (
    <Box height="full" display="flex" alignItems="center" justifyContent="center">
      <HStack>
        <LinkBox _hover={{ bg: "background.tertiary" }} as="article">
          <Card size="lg" h={40}>
            <CardBody display="flex" alignItems="center" justifyContent="center">
              <VStack>
                <AddIcon boxSize="6" />
                <LinkOverlay as={Link} onClick={createNewOnboardingPlan}>
                  Create Onboarding Plan from Scratch
                </LinkOverlay>
              </VStack>
            </CardBody>
          </Card>
        </LinkBox>
        {DUPLICATE_ONBOARDING_PLAN_FEATURE_FLAG && (
          <Card size="lg" h={40}>
            <CardBody display="flex" alignItems="center" justifyContent="center" alignContent="center">
              <VStack>
                <Icon as={GrDuplicate} boxSize="6" />
                <Text>Duplicate Previous Onboarding Plan</Text>
              </VStack>
            </CardBody>
          </Card>
        )}
      </HStack>
    </Box>
  );
}

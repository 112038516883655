import {
  Avatar, Box, Center, SimpleGrid, GridItem, Heading, Text,
} from "@chakra-ui/react";
import { Connection } from "models/connection";
import { User } from "models/user";

function ConnectionCard({
  connection: {
    contact: { avatarUrl, fullName, title },
    description,
  },
}: {
  connection: Pick<Connection, "contact" | "description">;
}) {
  return (
    <Box p={4} border="1px solid #ccc" borderRadius="md" boxShadow="md" bg="chakra-subtle-bg" height={250}>
      {avatarUrl && <Avatar size="xl" src={avatarUrl} name={fullName} />}
      <Heading size="md">{fullName}</Heading>
      <Text fontStyle="italic">{title}</Text>
      <Text>{description}</Text>
    </Box>
  );
}

class Filler {
  id: string;

  constructor(id: string) {
    this.id = id;
  }
}

function Connections({ connections }: { connections: Connection[] }) {
  const remainder = connections.length % 3;
  const itemsToFill = remainder === 0 ? 0 : 3 - remainder;
  const updatedConnections = connections.map((connection) => ({ discriminator: "connection", ...connection }));

  // Create empty filler items for centering
  const fillerItems = Array.from({ length: itemsToFill }, (_, index) => new Filler(`filler-${index}`));

  const allConnections = [...updatedConnections, ...fillerItems];
  console.log(connections);
  return (
    // TODO: Make this more/better responsive
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={4}>
      {allConnections.map((connection) => (
        <GridItem key={connection.id}>
          {connection instanceof Filler ? (
            <Box visibility="hidden">Filler</Box>
          ) : (
            <ConnectionCard connection={connection} />
          )}
        </GridItem>
      ))}
    </SimpleGrid>
  );
}

function TeamOverview({ manager, connections }: { manager: User | undefined; connections: Connection[] }) {
  return (
    <Box p={4} color="chakra-body-text">
      <Heading textAlign="center" size="lg" mb={12}>
        Team Overview
      </Heading>
      {manager && (
        <Center mb={4} width="100%" alignItems="center">
          <Box width="50%">
            <ConnectionCard connection={{ contact: manager, description: "Your Manager" }} />
          </Box>
        </Center>
      )}
      <Connections connections={connections} />
    </Box>
  );
}

export default TeamOverview;

import { CreateToastFnReturn } from "@chakra-ui/react";
import { QUERY_KEYS } from "definitions/constants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { generatePath } from "react-router-dom";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useMemo } from "react";
import { BaseAssignedAction } from "models/automation";

interface UseSendNowAssignedAction {
  assignedAction: BaseAssignedAction | undefined;
  onSuccess?: () => void;
}

export default function useSendNowAssignedAction({ assignedAction, onSuccess }: UseSendNowAssignedAction) {
  const isReady = useMemo(() => !!assignedAction, [assignedAction]);
  const {
    performRequest: sendNow,
    state: { loading: isLoading },
  } = useDeferredApiCall(generatePath(API_ROUTES.assignedActions.forceSend, { id: assignedAction?.id || "" }), {
    method: "POST",
    onSuccess: (_first, innerQueryClient, toast) => {
      innerQueryClient.invalidateQueries([QUERY_KEYS.assignedPaths, assignedAction?.scheduledWorkflowId]);
      innerQueryClient.invalidateQueries([QUERY_KEYS.assignedActions, "issues"]);
      innerQueryClient.invalidateQueries([
        QUERY_KEYS.newHireJourney,
        assignedAction?.scheduledWorkflow?.onboardingJourneyId,
      ]);
      toast({ title: "Successfully sent", status: "success" });
      onSuccess?.();
    },
    onFailure: (_second, _third, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return { sendNow, isLoading, isReady };
}

/* eslint-disable object-curly-newline */
import { Center, Container, Divider, Text } from "@chakra-ui/react";
import { PlanStepProps } from "components/Form/typings";
import { Helpers } from "hooks/useStep";
import WizardCard, { CardContent, CardHeader } from "./Cardheader";
import ResourcesSection from "./ResourcesSection";

export default function Step3AddResources({
  goToPrevStep,
  goToNextStep,
  canGoToNextStep,
  canGoToPrevStep,
  onboardingPlan,
}: PlanStepProps & Partial<Helpers>) {
  return (
    <WizardCard>
      <CardHeader
        headerText="Add Important Resources"
        {...{ goToPrevStep, goToNextStep, canGoToNextStep, canGoToPrevStep }}
      />
      <Divider mb="4" />
      <CardContent>
        <Container mb="10">
          <Text color="fg.emphasized">
            Add important resources such as documentation, wiki pages, code repositories, guides, tools, applications,
            and other valuable resources.
          </Text>
        </Container>
        <Center>
          <ResourcesSection onboardingPlan={onboardingPlan} />
        </Center>
      </CardContent>
    </WizardCard>
  );
}

import {
  Menu, MenuButton, IconButton, MenuList, MenuButtonProps, MenuProps, Portal,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { IconType } from "react-icons";

interface IconMenuProps extends PropsWithChildren, MenuButtonProps {
  icon: IconType;
  ariaLabel: string;
  menuProps?: Omit<MenuProps, "children">;
  inPortal?: boolean;
}

export default function IconMenu({
  icon: Icon, ariaLabel, inPortal, children, menuProps, ...rest
}: IconMenuProps) {
  return (
    <Menu {...menuProps}>
      <MenuButton
        color="chakra-subtle-text"
        as={IconButton}
        aria-label={ariaLabel}
        icon={<Icon />}
        variant="ghost"
        {...rest}
      />
      {inPortal ? (
        <Portal>
          <MenuList>{children}</MenuList>
        </Portal>
      ) : (
        <MenuList>{children}</MenuList>
      )}
    </Menu>
  );
}

IconMenu.defaultProps = {
  menuProps: {},
  inPortal: false,
};

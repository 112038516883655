import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { Step } from "components/Steps";
import { useEffect } from "react";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import SingleFormColumn from "components/FormElements/SingleFormColumn";
import useSubmitNewHire from "./useSubmitNewHireUpdate";
import { StepContent } from "./Components";
import {
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PREFERRED_NAME_FIELD,
  PRONOUNS_FIELD,
  TITLE_FIELD,
  newHireSchema,
} from "./definition";

interface AddProfileInfoStepProps {
  newHireJourney: ExtendedNewHireJourney;
}

function AddProfileInfoStep(props: AddProfileInfoStepProps) {
  const { newHireJourney } = props;
  const { handleOnSubmit } = useSubmitNewHire({ newHireJourneyId: newHireJourney?.id || "" });
  const resolver = yupResolver(
    newHireSchema.pick([
      FIRST_NAME_FIELD.name as "firstName",
      LAST_NAME_FIELD.name as "lastName",
      PREFERRED_NAME_FIELD.name as "preferredName",
      TITLE_FIELD.name as "title",
      PRONOUNS_FIELD.name as "pronouns",
    ]),
  );
  const form = useForm<any>({
    mode: "onBlur",
    resolver,
    defaultValues: {
      firstName: newHireJourney?.user?.firstName || "",
      lastName: newHireJourney?.user?.lastName || "",
      preferredName: newHireJourney?.user?.preferredName || "",
      title: newHireJourney?.user?.title || "",
      pronouns: newHireJourney?.user?.pronouns || "",
    },
  });
  const {
    register,
    formState: { errors },
    reset,
  } = form;

  useEffect(() => {
    if (newHireJourney) {
      // Reset the form values when newHireJourney is updated
      reset({
        firstName: newHireJourney?.user?.firstName || "",
        lastName: newHireJourney?.user?.lastName || "",
        preferredName: newHireJourney?.user?.preferredName || "",
        title: newHireJourney?.user?.title || "",
        pronouns: newHireJourney?.user?.pronouns || "",
      });
    }
  }, [newHireJourney, reset]);

  return (
    <Step
      id="add-info"
      onClickNext={(callback) => {
        handleOnSubmit({
          callback,
          form,
        });
      }}
    >
      <StepContent title="Add profile info">
        <SingleFormColumn>
          <TextInputField required {...FIRST_NAME_FIELD} register={register} errors={errors} />
          <TextInputField required {...LAST_NAME_FIELD} register={register} errors={errors} />
          <TextInputField {...PREFERRED_NAME_FIELD} register={register} errors={errors} />
          <TextInputField {...PRONOUNS_FIELD} register={register} errors={errors} />
          <TextInputField required {...TITLE_FIELD} errors={errors} register={register} />
        </SingleFormColumn>
      </StepContent>
    </Step>
  );
}

export default AddProfileInfoStep;

/* eslint-disable react/jsx-no-useless-fragment */
import {
  Card,
  CardBody,
  Flex, FlexProps, Heading, Text, useColorModeValue,
} from "@chakra-ui/react";
import EmbeddedHtml from "components/DataDisplay/EmbeddedHtml";
import TaskExampleImage from "components/ExampleImage/TaskExampleImage";
import { TASK_TYPES } from "definitions/constants";
import { TaskType } from "models/task";
import { RecordTarget } from "models/record_target";
import { PropsWithChildren } from "react";
import { TargetTypeDisplay } from ".";

interface TaskProps {
  taskType: TaskType;
  description: string;
  includeExtra?: boolean;
}

interface MessageContentProps {
  taskRecipient?: string;
  messageBody?: string;
  messageTarget?: RecordTarget;
}

// Interface for a message task
interface MessageTaskProps extends TaskProps, Pick<MessageContentProps, "messageBody" | "messageTarget"> {
  taskType: typeof TASK_TYPES.SEND_MESSAGE_PROMPT; // Discriminator property
}

interface CustomTaskProps extends TaskProps {
  taskType: typeof TASK_TYPES.CUSTOM;
}

// Union type for all possible task props
type ExtendedTaskProps = MessageTaskProps | CustomTaskProps;

function AdditionalContentCard({
  title,
  subTitle,
  children,
  ...rest
}: { title?: string; subTitle?: string } & PropsWithChildren & FlexProps) {
  const bgColor = useColorModeValue("gray.50", "gray.600");
  return (
    <>
      {title ? (
        <Flex
          direction="column"
          pl="4"
          pr="14"
          pb="14"
          pt="2"
          gap="8"
          bg={bgColor}
          borderRadius="8px"
          width="80%"
          {...rest}
        >
          <Flex direction="column">
            <Heading size="xs">{title}</Heading>
            {subTitle && (
              <Text size="sm" fontWeight="medium" color="fg.muted">
                {subTitle}
              </Text>
            )}
          </Flex>
          <Flex direction="column" pl="10" gap="8">
            {children}
          </Flex>
        </Flex>
      ) : (
        <Flex direction="column" p="14" gap="8" bg={bgColor} borderRadius="8px" width="80%" {...rest}>
          {children}
        </Flex>
      )}
    </>
  );
}

AdditionalContentCard.defaultProps = {
  title: null,
  subTitle: null,
};

export function MessageContent({ taskRecipient, messageTarget, messageBody }: MessageContentProps) {
  return (
    <AdditionalContentCard title="Message Prompt" subTitle={`Task recipient ${taskRecipient ? `(${taskRecipient}) ` : ""}will be prompted to send this message`} my="4">
      <Card>
        <CardBody>
          <TargetTypeDisplay width="fit-content" targetType={messageTarget.targetType} record={messageTarget.target} />
          <Flex direction="column" align="left" gap="1" mt="2">
            <Text textStyle="md" fontWeight="medium" color="fg.muted">
              Message
            </Text>
            <EmbeddedHtml innerHtml={messageBody?.body} />
          </Flex>
        </CardBody>
      </Card>
    </AdditionalContentCard>
  );
}

MessageContent.defaultProps = {
  taskRecipient: null,
  messageBody: null,
  messageTarget: {},
};

export default function TaskContent({
  taskType,
  description,
  messageBody,
  messageTarget,
  includeExtra,
  ...rest
}: ExtendedTaskProps) {
  return (
    <Flex direction="column" gap="2" align="center" {...rest}>
      {[TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT, TASK_TYPES.SCHEDULE_MEETING].includes(taskType) ? (
        <EmbeddedHtml innerHtml={description} />
      ) : (
        <TaskExampleImage taskType={taskType} />
      )}
      {includeExtra && taskType === TASK_TYPES.SEND_MESSAGE_PROMPT && (
        <MessageContent messageBody={messageBody} messageTarget={messageTarget} />
      )}
    </Flex>
  );
}

TaskContent.defaultProps = {
  messageBody: null,
  messageTarget: {},
  includeExtra: true,
};

import { Button, Heading, HStack, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { useEffect } from "react";
import { TASK_TYPES } from "definitions/constants";
import { TRIGGER_DEFAULT_VALUES } from "definitions/constants/trigger";
import { isImmediateNoOffsetTrigger } from "components/Forms/PathActions/Shared/helpers";
import { schema, Schema } from "./schema";
import TaskReminderInput from "./TaskReminderInput";
import Content from "./Content";
import Trigger from "./Trigger";
import Meta from "./Meta";
import Scheduling from "./Trigger/Scheduling";

function addDefaultTimeIfNeeded(defaultValues: Partial<Schema>) {
  if (defaultValues.trigger?.type === "timing") {
    const { referenceEvent, offsetDirection } = defaultValues.trigger.data || {};
    if (!isImmediateNoOffsetTrigger({ referenceEvent, offsetDirection })) {
      const formattedTimeOfDay = defaultValues.trigger.data?.timeOfDay || TRIGGER_DEFAULT_VALUES.trigger.data.timeOfDay;
      const formattedTimezoneType = defaultValues.trigger.data?.timezoneType || TRIGGER_DEFAULT_VALUES.trigger.data.timezoneType;
      return {
        ...defaultValues,
        trigger: {
          ...defaultValues.trigger,
          data: {
            ...defaultValues.trigger.data,
            timeOfDay: formattedTimeOfDay,
            timezoneType: formattedTimezoneType,
          },
        },
      };
    }
  }
  return defaultValues;
}

const reminderText = [
  "🔔 Hi there! A gentle reminder to complete this outstanding task ASAP",
  "👋 Us again! Please complete this outstanding task ASAP! Other automated messages may depend on it",
  "🧐 Is there anyone out there? Please complete this onboarding task!",
  "🙏 Please complete this task, if there are 3 reminders already it must be important!",
  "🚶 Prolly don't want to hear from us again, but can you complete this task?",
];

export default function Form({
  workflowId,
  workflowActionId,
  onSubmit,
  onClose,
  proceedText,
  defaultValues,
  isUpdate,
  isLoading,
}: {
  workflowId: string;
  workflowActionId?: string;
  onSubmit: (data: Schema) => void;
  onClose: () => void;
  proceedText: string;
  defaultValues: Partial<Schema>;
  isUpdate: boolean;
  isLoading: boolean;
}) {
  const form = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver<Schema>(schema),
    defaultValues: addDefaultTimeIfNeeded(defaultValues),
  });
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    resetField,
    setFocus,
    setValue,
    watch,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "reminders",
  });

  useEffect(() => {
    setFocus("name");
    reset();
  }, [reset, setFocus]);

  const [actionType, triggerType, referenceEvent, targetType] = watch([
    "actionType",
    "trigger.type",
    "trigger.data.referenceEvent",
    "targetType",
  ]);

  useEffect(() => {
    let targetableType;
    if (targetType === "specific_user") {
      targetableType = "User";
    } else if (targetType === "message_service_channel") {
      targetableType = "MessageServiceChannel";
    }

    if (targetType !== "relation") {
      setValue("specificTargetableType", targetableType);
      setValue("specificTargetableId", undefined);
      setValue("relationTarget", undefined);
    }
  }, [setValue, targetType]);

  useEffect(() => {
    resetField("content", { defaultValue: actionType === "email" ? defaultValues.content : {} });
    setValue("content.body", undefined);
    resetField("taskType", { defaultValue: actionType === "task" ? TASK_TYPES.CUSTOM : undefined });
  }, [actionType, resetField, defaultValues, setValue]);

  useEffect(() => {
    if (triggerType === "timing") {
      resetField(
        "trigger.data.offsetDirection",
        referenceEvent && referenceEvent === "immediate"
          ? { defaultValue: "exact" }
          : { defaultValue: defaultValues.trigger?.data?.offsetDirection },
      );
    }
  }, [referenceEvent, triggerType, resetField, defaultValues]);

  const addReminder = () => append({
    message: reminderText[fields.length % 5],
    trigger: { type: "timing", data: { referenceEvent, offsetDirection: "exact" } },
  });

  return (
    <>
      <Stack alignItems="start">
        <Meta
          setValue={setValue}
          isUpdate={isUpdate}
          register={register}
          control={control}
          errors={errors}
          watch={watch}
        />
        <Content
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          resetField={resetField}
          setValue={setValue}
        />
        <Trigger form={form} workflowId={workflowId} workflowActionId={workflowActionId} />
      </Stack>
      {actionType === "task" && (
        <>
          <Heading size="xs" mt="4">
            Due Date
          </Heading>
          <Scheduling form={form} fieldName="dueDateTrigger" hideTimeOfDay />
        </>
      )}
      {actionType === "task" && (
        <>
          {fields.map((field, index) => (
            <TaskReminderInput
              // eslint-disable-next-line react/no-array-index-key
              key={`reminders.${index}.${JSON.stringify(field.trigger)}`}
              form={form}
              index={index}
              remove={remove}
              {...field}
            />
          ))}
          <Button mt={4} onClick={addReminder}>
            Add Reminder
          </Button>
        </>
      )}
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit, (e) => console.error(e))}
          onClose={onClose}
          proceedText={proceedText}
          proceedDisabled={!isDirty}
          isLoading={isLoading}
        />
      </HStack>
    </>
  );
}

Form.defaultProps = {
  workflowActionId: undefined,
};

import { Box, VStack } from "@chakra-ui/react";
import LoadingCard from "components/Card/LoadingCard";
import SimpleNameCard from "components/Card/SimpleNameCard";
import ImportantResources from "components/NewHireDashboard/ImportantResources";
import SupportTeam from "components/NewHireDashboard/SupportTeam";
import NewHireTaskTable from "components/Table/NewHireDashboardTaskTable";
import useApiClient from "hooks/useApiClient";
import useCaminoStore from "hooks/useCaminoStore";
import AppPage from "layouts/AppPage";
import { Company } from "models/company";
import { NewHireJourney } from "models/onboarding";
import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";

export default function OnboarderDashboardPage() {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const [company, setCompany] = useState<Company>();
  // This queries the onboardingJourneys
  const { loading, data, error } = useApiClient<NewHireJourney>(
    generatePath(API_ROUTES.newHireJourneys.show, { id: "current" }),
    { method: "GET" },
  );

  useEffect(() => {
    if (!company && user?.companyId) {
      apiClient
        .get(generatePath(API_ROUTES.companies.show, { id: "current" }))
        .then((response) => {
          setCompany(response);
        })
        .catch((apiClientError) => {
          console.log(apiClientError);
        });
    }
  }, [company, user]);

  if (loading) {
    return <LoadingCard />;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <AppPage>
      <SimpleNameCard user={user} />
      <VStack>
        <Box width="100%">
          <SupportTeam hrbp={data.peopleTeamContact} manager={data.manager} buddy={data.buddy} />
        </Box>
        <Box width="100%">
          <ImportantResources
            personalizedOnboardingPlanUrl={data.onboardingPlanUrl}
            newHireInformationPacketUrl={company?.newHireInfoPacketUrl}
            companyHandbookUrl={company?.companyHandbookUrl}
            hrisUrl={company?.hrisUrl}
            companyWikiUrl={company?.companyWikiUrl}
            teamWikiUrl={data.teamWikiUrl}
            teamGoalsUrl={data.teamGoalsUrl}
            companyGoalsUrl={company?.companyGoalsUrl}
            benefitsInfoUrl={company?.benefitsInfoUrl}
          />
        </Box>
        <Box width="100%" mt={8}>
          <NewHireTaskTable />
        </Box>
      </VStack>
    </AppPage>
  );
}

import { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Card, CardBody, Divider, Flex, HStack, Skeleton, Stack, useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { User } from "models/user";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { camelCase, pick } from "lodash";
import * as yup from "yup";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { Company } from "models/company";
import { LinkTitleCardAttribute } from "components/Card/TitleCard";
import { CgWebsite } from "react-icons/cg";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";

export const NEW_HIRE_INFO_PACKET_URL = {
  label: "New Hire Info Packet Url",
  name: "newHireInfoPacketUrl",
};
export const BENEFITS_INFO_URL = {
  label: "Benefits Info Url",
  name: "benefitsInfoUrl",
};
export const HRIS_URL = {
  label: "HRIS Url",
  name: "hrisUrl",
};
export const COMPANY_HANDBOOK_URL = {
  label: "Company Handbook Url",
  name: "companyHandbookUrl",
};
export const COMPANY_WIKI_URL = {
  label: "Company Wiki Url",
  name: "companyWikiUrl",
};
export const COMPANY_GOALS_URL = {
  label: "Company Goals Url",
  name: "companyGoalsUrl",
};
export const LOCATION_FIELD = {
  label: "Acronym Repository Url",
  name: "acronymRepositoryUrl",
};
export const BUDDY_EXPECTATIONS_URL = {
  label: "Buddy Expectations Url",
  name: "buddyExpectationsUrl",
};
export const BUDDY_SELECTION_URL = {
  label: "Buddy Selection Url",
  name: "buddySelectionUrl",
};
export const ONBOARDING_PLAN_TEMPLATES_URL = {
  label: "Onboarding Plan Templates Url",
  name: "onboardingPlanTemplatesUrl",
};

export interface CompanyUpdateFormValues {
  newHireInfoPacketUrl?: string;
  benefitsInfoUrl?: string;
  hrisUrl?: string;
  companyHandbookUrl?: string;
  companyWikiUrl?: string;
  companyGoalsUrl?: string;
  acronymRepositoryUrl?: string;
  buddyExpectationsUrl?: string;
  buddySelectionUrl?: string;
  onboardingPlanTemplatesUrl?: string;
}

export const newHireSchema = yup.object({
  newHireInfoPacketUrl: yup.string().label(NEW_HIRE_INFO_PACKET_URL.label).notRequired(),
  benefitsInfoUrl: yup.string().label(BENEFITS_INFO_URL.label).notRequired(),
  hrisUrl: yup.string().label(HRIS_URL.label).notRequired(),
  companyHandbookUrl: yup.string().label(COMPANY_HANDBOOK_URL.label).notRequired(),
  companyWikiUrl: yup.string().label(COMPANY_WIKI_URL.label).notRequired(),
  companyGoalsUrl: yup.string().label(COMPANY_GOALS_URL.label).notRequired(),
  acronymRepositoryUrl: yup.string().label(LOCATION_FIELD.label).notRequired(),
  buddyExpectationsUrl: yup.string().label(BUDDY_EXPECTATIONS_URL.label).notRequired(),
  buddySelectionUrl: yup.string().label(BUDDY_SELECTION_URL.label).notRequired(),
  onboardingPlanTemplatesUrl: yup.string().label(ONBOARDING_PLAN_TEMPLATES_URL.label).notRequired(),
});

const values = (company: Company | undefined) => ({
  newHireInfoPacketUrl: company?.newHireInfoPacketUrl || null,
  benefitsInfoUrl: company?.benefitsInfoUrl || null,
  hrisUrl: company?.hrisUrl || null,
  companyHandbookUrl: company?.companyHandbookUrl || null,
  companyWikiUrl: company?.companyWikiUrl || null,
  companyGoalsUrl: company?.companyGoalsUrl || null,
  acronymRepositoryUrl: company?.acronymRepositoryUrl || null,
  buddyExpectationsUrl: company?.buddyExpectationsUrl || null,
  buddySelectionUrl: company?.buddySelectionUrl || null,
  onboardingPlanTemplatesUrl: company?.onboardingPlanTemplatesUrl || null,
});

interface CompanyUpdateFormProps {
  title: string;
  cardLink?: string;
  fields: Array<keyof CompanyUpdateFormValues>;
  onSuccess: () => void;
  onClose: () => void;
}

export default function CompanyUpdateForm({
  fields, title, cardLink, onSuccess, onClose,
}: CompanyUpdateFormProps) {
  const camelFields = useMemo(() => fields.map((field) => camelCase(field)), [fields]);
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const { data: company, isLoading } = useQuery<Company>({
    queryKey: [QUERY_KEYS.companies, "current"],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.companies.show, { id: "current" })),
  });
  const defaultValues = useMemo(() => pick(values(company), camelFields), [company, camelFields]);

  const {
    formState: { errors, dirtyFields, isDirty },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(newHireSchema),
    defaultValues,
  });

  useEffect(() => {
    setFocus(camelFields[0] as keyof CompanyUpdateFormValues);
  }, [setFocus, camelFields]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  /* HandleOnSubmit */
  const { mutate: onSubmit, isLoading: submitIsLoading } = useMutation({
    mutationFn: (data: CompanyUpdateFormValues) => {
      const updateData = Object.fromEntries(
        Object.entries(data).filter(([key]) => dirtyFields[key as keyof CompanyUpdateFormValues] === true),
      );
      return apiClient.put<User>(generatePath(API_ROUTES.companies.show, { id: "current" }), {
        company: updateData,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([QUERY_KEYS.companies, "current"]);
      onSuccess();
      onClose();
      toast({
        title: "Success!",
      });
    },
    onError: (mutateError) => {
      console.log("userCreateError", mutateError);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });

  return (
    <>
      <Flex mb="2" width="100%" align="center" justify="center">
        <Card borderColor="brand.500" borderWidth="1px">
          <Skeleton isLoaded={!isLoading}>
            <CardBody>
              <LinkTitleCardAttribute
                to={cardLink as string}
                label={title}
                value={company?.name as string}
                icon={CgWebsite}
              />
            </CardBody>
          </Skeleton>
        </Card>
      </Flex>
      <Divider my="4" />
      <Stack>
        {camelFields.includes(NEW_HIRE_INFO_PACKET_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...NEW_HIRE_INFO_PACKET_URL} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(BENEFITS_INFO_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...BENEFITS_INFO_URL} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(HRIS_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...HRIS_URL} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(COMPANY_HANDBOOK_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...COMPANY_HANDBOOK_URL} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(COMPANY_WIKI_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...COMPANY_WIKI_URL} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(COMPANY_GOALS_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...COMPANY_GOALS_URL} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(LOCATION_FIELD.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...LOCATION_FIELD} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(BUDDY_EXPECTATIONS_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...BUDDY_EXPECTATIONS_URL} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(BUDDY_SELECTION_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...BUDDY_SELECTION_URL} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(ONBOARDING_PLAN_TEMPLATES_URL.name as keyof CompanyUpdateFormValues) && (
          <TextInputField {...ONBOARDING_PLAN_TEMPLATES_URL} errors={errors} register={register} disabled={isLoading} />
        )}
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText="Update"
          isLoading={submitIsLoading}
          proceedDisabled={!isDirty}
        />
      </HStack>
    </>
  );
}

CompanyUpdateForm.defaultProps = {
  cardLink: null,
};

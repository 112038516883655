import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { useEffect, useState } from "react";
import { Step } from "components/Steps";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import SingleFormColumn from "components/FormElements/SingleFormColumn";
import useSubmitNewHire from "./useSubmitNewHireUpdate";
import { StepContent } from "./Components";
import { BUDDY_FIELD, MANAGER_FIELD, PEOPLE_TEAM_CONTACT_FIELD, newHireSchema } from "./definition";

interface AddSupportTeamProps {
  newHireJourney: ExtendedNewHireJourney;
}

function AddSupportTeam(props: AddSupportTeamProps) {
  const { newHireJourney } = props;
  const { handleOnSubmit } = useSubmitNewHire({ newHireJourneyId: newHireJourney?.id || "" });
  const [userCacheKey, updateUserCacheKey] = useState("");
  const form = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(
      newHireSchema.pick([
        MANAGER_FIELD.name as "managerId",
        BUDDY_FIELD.name as "buddyId",
        PEOPLE_TEAM_CONTACT_FIELD.name as "peopleTeamContactId",
      ]),
    ),
    defaultValues: {
      managerId: newHireJourney?.user?.managerId || "",
      buddyId: newHireJourney?.buddyId || "",
      peopleTeamContactId: newHireJourney?.peopleTeamContactId || "",
    },
  });
  const {
    control,
    formState: { errors },
    reset,
  } = form;

  useEffect(() => {
    if (newHireJourney) {
      // Reset the form values when newHireJourney is updated
      reset({
        managerId: newHireJourney?.user?.managerId || "",
        buddyId: newHireJourney?.buddyId || "",
        peopleTeamContactId: newHireJourney?.peopleTeamContactId || "",
      });
    }
  }, [newHireJourney, reset]);

  return (
    <Step
      id="add-onboarding-info"
      onClickNext={(callback) => {
        handleOnSubmit({
          callback,
          form,
        });
      }}
    >
      <StepContent title="Add support team">
        <SingleFormColumn>
          <UserSelectField
            cacheKey={userCacheKey}
            updateCacheKey={updateUserCacheKey}
            control={control}
            {...MANAGER_FIELD}
            errors={errors}
            includeCreateNewUser
          />
          <UserSelectField
            cacheKey={userCacheKey}
            updateCacheKey={updateUserCacheKey}
            control={control}
            {...PEOPLE_TEAM_CONTACT_FIELD}
            errors={errors}
            includeCreateNewUser
          />
          <UserSelectField
            cacheKey={userCacheKey}
            updateCacheKey={updateUserCacheKey}
            control={control}
            {...BUDDY_FIELD}
            errors={errors}
            includeCreateNewUser
          />
        </SingleFormColumn>
      </StepContent>
    </Step>
  );
}

export default AddSupportTeam;

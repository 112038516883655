import { HStack, Stack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";
import { API_ROUTES } from "definitions/constants/routeConstants";
import AddNewEmployeeButton from "components/Popover/AddNewEmployeeButton";
import apiClient from "services/ApiClient";
import { useState } from "react";
import { ResponseModel } from "services/ApiClient/responseModel";
import { generatePath } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { OnboardingPlan } from "models/onboarding";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import TextAreaField from "components/FormElements/Fields/TextAreaField";

const CONNECTION_TYPE_FIELD = {
  type: "SELECT",
  label: "Connection Type",
  name: "connectionType",
  helperText: "The type of connection, such as mentor, teammate, skip-level, cross functional partner, etc.",
  options: [
    "Teammate",
    "People Team",
    "Leadership",
    "Skip Level",
    "Cross Functional Partner",
    "Mentor",
    "Subject Matter Expert",
    "Colleague",
    "Other",
  ].map((value) => ({ label: value, value })),
};

const CONTACT_FIELD = {
  type: "SELECT",
  label: "Connection",
  name: "contactId",
};

const DESCRIPTION_FIELD = {
  type: "TEXT",
  label: "Connection Description",
  name: "description",
  helperText:
    "A brief blurb providing context about the connection, such as what to discuss or what they can help the new hire with.",
};

const EXPECTED_FIRST_MEETING_FIELD = {
  type: "SELECT",
  label: "Expected First Meeting",
  name: "expectedFirstMeeting",
  helperText:
    "When should your new hire/connection plan to meet initially? REMEMBER to space out meetings over the first few months, prioritizing the most important",
  options: ["Day 1", "Day 2", "Week 1", "Week 2", "Week 3", "Week 4", "Month 1", "Month 2", "Month 3", "Month 4+"].map(
    (value) => ({ label: value, value }),
  ),
};

const connectionFormSchema = yupObject({
  connectionType: yupString().label(CONNECTION_TYPE_FIELD.label).required(),
  contactId: yupString().label(CONTACT_FIELD.label).required(),
  description: yupString().label(DESCRIPTION_FIELD.label).required(),
  expectedFirstMeeting: yupString().label(EXPECTED_FIRST_MEETING_FIELD.label),
});

interface ConnectionFormProps {
  onClose: () => void;
  onboardingPlan: OnboardingPlan;
}

export default function NewConnectionForm({ onboardingPlan, onClose }: ConnectionFormProps) {
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(connectionFormSchema),
  });
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  // this is a workaround to bust the cache of react select when
  // a new user is added to the backend for the Select Manager
  // dropdown
  const [cacheKey, updateCacheKey] = useState("");

  /* HandleOnSubmit */
  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const res = await apiClient.performRequest<ResponseModel<{ id: string }>>(
        generatePath(API_ROUTES.onboardingPlans.createConnection, { id: onboardingPlan.id }),
        {
          method: "POST",
          body: JSON.stringify({ connection: data }),
          headers: { "Content-Type": "application/json" },
        },
      );
      setIsLoading(false);
      const responseData = res.payload.data;
      // update the onboarding plan cache directly
      queryClient.setQueryData(["onboardingPlans", responseData.id], responseData);
      onClose();
      toast({
        title: "New Connection Added.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      // log the newly created item to the console
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <Stack spacing="5">
        <SingleSelectField required {...CONNECTION_TYPE_FIELD} errors={errors} control={control} />
        <HStack>
          <UserSelectField
            cacheKey={cacheKey}
            control={control}
            {...CONTACT_FIELD}
            errors={errors}
            excludedUserIds={(onboardingPlan.connections || []).map((connection) => connection?.contact?.id)}
          />
          <AddNewEmployeeButton isModal updateCacheKey={updateCacheKey} />
        </HStack>
        <TextAreaField required {...DESCRIPTION_FIELD} errors={errors} register={register} />
        <SingleSelectField {...EXPECTED_FIRST_MEETING_FIELD} errors={errors} control={control} />
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText="Add New Connection"
          proceedDisabled={!isDirty}
          isLoading={isLoading}
        />
      </HStack>
    </>
  );
}

import { orderBy, remove } from "lodash";
import { BaseTask } from "models/task";
import moment, { Moment } from "moment";

interface DateSection {
  key: string;
  unit: string;
  start: number;
  end: number;
}

interface ReturnObject {
  [key: string]: BaseTask[];
}

function allTasksBetweenDates(tasks: BaseTask[], startDate: Date | Moment, endDate: Date | Moment) {
  // this is destructive, it ruins the original array
  return remove(
    tasks,
    (task) => moment(task.dueDate).startOf("day").toDate() >= moment(startDate).startOf("day").toDate()
      && moment(task.dueDate).startOf("day").toDate() <= moment(endDate).endOf("day").toDate(),
  );
}

export default function breakIntoSectionsByDate(startDate: Date, tasks: BaseTask[]) {
  const sortedTasks = orderBy(tasks, (task) => task.dueDate, "asc");
  // returns a list of js objects separated by dates
  const allSections: DateSection[] = [
    {
      key: "Day 1",
      unit: "days",
      start: 1,
      end: 1,
    },
    {
      key: "Day 2",
      unit: "days",
      start: 2,
      end: 2,
    },
    {
      key: "Week 1",
      unit: "days",
      start: 3,
      end: 7,
    },
    {
      key: "Week 2",
      unit: "days",
      start: 8,
      end: 14,
    },
    {
      key: "Week 3",
      unit: "days",
      start: 15,
      end: 21,
    },
    {
      key: "Month 1",
      unit: "months",
      start: 1,
      end: 2,
    },
    {
      key: "Month 2",
      unit: "months",
      start: 2,
      end: 3,
    },
    {
      key: "Month 3",
      unit: "months",
      start: 3,
      end: 4,
    },
    {
      key: "Months 4 - 6",
      unit: "months",
      start: 4,
      end: 6,
    },
  ];
  const returnObj: ReturnObject = {};
  const momentStart = moment(startDate).startOf("day");
  allSections.forEach((section) => {
    const sectionStart = moment(momentStart).add(section.start - 1, section.unit);
    const sectionEnd = moment(momentStart).add(section.end - 1, section.unit);
    returnObj[section.key] = allTasksBetweenDates(sortedTasks, sectionStart, sectionEnd);
  });
  return returnObj;
}

import {
  Avatar, Card, CardBody, CardHeader, Heading, Table, Tbody, Td, Tr,
} from "@chakra-ui/react";
import { parseAndFormatDate } from "utils/dateFormatter";

type AttributeGetter<T> = keyof T | ((data: T) => string);

export interface AttributeAccessor<T> {
  label: string;
  attribute: AttributeGetter<T>;
}

interface DisplayRowProps<T> extends AttributeAccessor<T> {
  data: T;
}

interface BasicInfoCardProps<T> {
  header: string;
  avatar?: { url: string; name?: string };
  data: T;
  fieldDefinitions: AttributeAccessor<Partial<T>>[];
}

function DisplayRowTable<T>({ data, label, attribute }: DisplayRowProps<T>) {
  let displayResult;
  if (attribute instanceof Function) {
    displayResult = attribute(data);
  } else if (typeof attribute === "string") {
    const attr = data[attribute as keyof T];
    displayResult = parseAndFormatDate(attr as string) || (attr as string);
  }

  return (
    <Tr key={String(attribute)}>
      <Td fontWeight="bold">{label}</Td>
      <Td>{displayResult}</Td>
    </Tr>
  );
}

export default function BasicInfoCard<T>({
  fieldDefinitions, header, data, avatar,
}: BasicInfoCardProps<T>) {
  return (
    <Card w={{ md: "2xl" }}>
      <CardHeader px={10} pt={10} pb={0}>
        <Heading color="fg.muted" size="md">
          {header}
        </Heading>
      </CardHeader>

      <CardBody>
        {avatar && <Avatar size="xl" ml={5} mb={5} src={avatar.url} name={avatar.name} />}
        <Table variant="simple">
          <Tbody>
            {fieldDefinitions.map((fieldDefinition) => (
              <DisplayRowTable {...fieldDefinition} key={fieldDefinition.label} data={data} />
            ))}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
}

BasicInfoCard.defaultProps = {
  avatar: undefined,
};

import { Card, CardProps } from "@chakra-ui/react";

export default function DisplayCard({ children, ...rest }: CardProps) {
  return (
    <Card
      p="0"
      direction="row"
      mb="2"
      borderColor="var(--chakra-colors-boldBlue-DEFAULT)"
      borderWidth="1px"
      width="850px"
      height="fit-content"
      maxWidth="100%"
      borderRadius="lg"
      boxShadow="5px 5px 10px 0px rgba(0, 0, 0, 0.08)"
      overflow="hidden"
      _hover={{
        borderColor: "var(--chakra-colors-brand-DEFAULT)",
        borderWidth: "2px",
      }}
      {...rest}
    >
      {children}
    </Card>
  );
}

import {
  Box, Card, CardBody, CardHeader, Checkbox, Flex, HStack, Heading, Stack, Text,
} from "@chakra-ui/react";
import AppPage from "layouts/AppPage";
import { RadioCardGroup, RadioCard } from "components/FormElements/RadioCardGroup";
import SearchBar from "components/SearchBar";
import { useState, useMemo, useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import OverviewTable from "features/NewHireJourney/OverviewTable";
import { ACTIONABLE_TYPE, QUERY_KEYS, RELATION_TARET_TYPE } from "definitions/constants";
import { useQuery } from "@tanstack/react-query";
import { ColumnDefinition, StageInfo } from "features/NewHireJourney/OverviewTable/definition";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import useQueryString from "hooks/useQueryString";
import { ActionableType, PathRelationTarget } from "models/automation";
import SelectableActionType from "pages/PeopleTeam/ActivityFeedPage/SelectableActionType";
import SelectableRelationTargetType from "pages/PeopleTeam/ActivityFeedPage/SelectableTargetType";

export default function NewHireJourneysOverviewPage() {
  const [searchText, setSearchText] = useState("");
  const [selectedTarget, setSelectedTarget] = useState<PathRelationTarget | "">("");
  const [selectedStageId, setSelectedStageId] = useState<string>("all");
  const [selectedActionType, setSelectedActionType] = useState<ActionableType | "">("");
  const [showEmptyStages, setShowEmptyStages] = useState<boolean>(false);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([{ name: "Onboarding Overview", isCurrentPage: true }]);
    setPageTitle("Onboarding Overview");
  }, [setBreadcrumbs, setPageTitle]);

  const { queryString } = useQueryString({
    filters: [],
  });

  const { data: stages } = useQuery<StageInfo[]>({
    queryKey: [QUERY_KEYS.stages],
    queryFn: () => apiClient.get(`${API_ROUTES.stages.base}?${queryString}`),
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const columnFilterFn = (columnDefinitions: ColumnDefinition[]) => {
    let filteredColumns = columnDefinitions;

    // Apply column filter based on selectedTarget
    switch (selectedTarget) {
      case RELATION_TARET_TYPE.NEW_HIRE:
        filteredColumns = filteredColumns.filter((column) => column.action.actionable.relationTarget === "new_hire");
        break;
      case RELATION_TARET_TYPE.MANAGER:
        filteredColumns = filteredColumns.filter((column) => column.action.actionable.relationTarget === "manager");
        break;
      case RELATION_TARET_TYPE.BUDDY:
        filteredColumns = filteredColumns.filter((column) => column.action.actionable.relationTarget === "buddy");
        break;
      case RELATION_TARET_TYPE.PEOPLE_TEAM:
        filteredColumns = filteredColumns.filter(
          (column) => column.action.actionable.relationTarget === "people_team_contact",
        );
        break;
      default:
        break;
    }

    // Apply action type filter based on selectedActionType
    switch (selectedActionType) {
      case ACTIONABLE_TYPE.EMAIL:
        filteredColumns = filteredColumns.filter(
          (column) => column.action.actionable.actionType === ACTIONABLE_TYPE.EMAIL,
        );
        break;
      case ACTIONABLE_TYPE.CHAT:
        filteredColumns = filteredColumns.filter(
          (column) => column.action.actionable.actionType === ACTIONABLE_TYPE.CHAT,
        );
        break;
      case ACTIONABLE_TYPE.TASK:
        filteredColumns = filteredColumns.filter(
          (column) => column.action.actionable.actionType === ACTIONABLE_TYPE.TASK,
        );
        break;
      default:
        break;
    }

    return filteredColumns;
  };

  const stageFilter = useMemo(
    () => (inputStages: StageInfo[]) => {
      if (selectedStageId && selectedStageId !== "all") {
        return inputStages.filter((inputStage) => inputStage.id === selectedStageId);
      } else if (selectedStageId === "all" && !showEmptyStages) {
        return inputStages.filter((inputStage) => inputStage.columnDefinitions.length > 0);
      }
      return inputStages;
    },
    [selectedStageId, showEmptyStages],
  );

  return (
    <AppPage>
      <Card>
        <CardBody as={Stack} direction="column" w="100%" alignItems="flex-start">
          <Flex gap="4">
            <SearchBar minWidth="500" name="tasksSearch" onInput={setSearchText} my="4" />
            <Checkbox
              colorScheme="brand"
              size="lg"
              isChecked={showEmptyStages}
              onChange={(e) => setShowEmptyStages(!showEmptyStages)}
            >
              Show Empty Stages
            </Checkbox>
          </Flex>
          <Box>
            <RadioCardGroup direction="row" defaultValue="all" onChange={(value: any) => setSelectedStageId(value)}>
              {[{ name: "All Stages", id: "all" }, ...stages.sort((a, b) => a.position - b.position)].map((stage) => (
                <RadioCard backgroundColor="brand.500" value={stage.id} key={stage.id}>
                  <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                    {stage.name}
                  </Text>
                </RadioCard>
              ))}
            </RadioCardGroup>
          </Box>
          <Flex gap="12">
            <SelectableActionType actionType={selectedActionType} setActionType={setSelectedActionType} />
            <SelectableRelationTargetType
              relationTargetType={selectedTarget}
              setRelationTargetType={setSelectedTarget}
            />
          </Flex>
        </CardBody>
      </Card>
      <Card mt="8" p="4">
        <CardHeader>
          <HStack justify="space-between">
            <Heading size="xs" textTransform="capitalize">
              Onboarding Overview
            </Heading>
          </HStack>
        </CardHeader>
        <CardBody>
          <OverviewTable
            stages={stages}
            searchText={searchText}
            stageFilterFn={stageFilter}
            columnFilterFn={columnFilterFn}
            selectedStageId={selectedStageId}
          />
        </CardBody>
      </Card>
    </AppPage>
  );
}

import apiClient from "services/ApiClient";
import { NetworkingError } from "services/ApiClient/networkingError";
import { useEffect, useState } from "react";

type NetworkState<T> =
  | { data: T; loading: false; error?: undefined }
  | { data?: undefined; loading: true; error?: undefined }
  | { data?: undefined; loading: false; error: NetworkingError };

type APIClientOptions = { method: "GET" | "DELETE" } | { method: "POST" | "PUT"; body: object };

export default function useApiClient<T>(path: string, options: APIClientOptions) {
  const [state, setState] = useState<NetworkState<T>>({ loading: true });

  useEffect(() => {
    const controller = new AbortController();
    const performRequest = async () => {
      try {
        let data: T;
        switch (options.method) {
          case "GET":
            data = await apiClient.get<T>(path, controller.signal);
            break;
          case "POST":
            data = await apiClient.post<T>(path, options.body, controller.signal);
            break;
          case "PUT":
            data = await apiClient.put<T>(path, options.body, controller.signal);
            break;
          case "DELETE":
            data = await apiClient.delete<T>(path, controller.signal);
            break;
          default:
            throw new Error("Invalid method");
        }
        setState({ data, loading: false });
      } catch (error) {
        setState({ loading: false, error: error as NetworkingError });
      }
    };

    performRequest();

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, options.method]);

  return state;
}

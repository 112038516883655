import { CreateToastFnReturn, HStack, Stack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { Path } from "models/automation";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { Link, generatePath } from "react-router-dom";
import { useCallback, useState } from "react";
import { QUERY_KEYS } from "definitions/constants";
import SwitchField from "components/FormElements/Fields/SwitchField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";

const NAME_FIELD = {
  type: "text",
  label: "Path Name (unique & recognizable)",
  name: "name",
};

const AUTO_ADD_FIELD = {
  type: "boolean",
  label: "Auto-Assign Path to All New Hires?",
  name: "autoAdd",
  helperText: "Automatically assign this path to all new hires added to Camino.",
};

const schema = yup.object({
  name: yup.string().label(NAME_FIELD.label).required(),
  autoAdd: yup.boolean().label(AUTO_ADD_FIELD.label).required(),
});

type Schema = yup.InferType<typeof schema>;

type FormData = Schema;

function PathForm({
  performRequest,
  onSuccess,
  onClose,
  proceedText,
  defaultValues,
}: {
  performRequest: (data: FormData) => Promise<Path>;
  onSuccess: (res: Path, queryClient: QueryClient, toast: CreateToastFnReturn) => void;
  onClose: () => void;
  proceedText: string;
  defaultValues: Partial<Schema>;
}) {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { autoAdd: false, ...defaultValues },
  });
  const toast = useToast({ duration: 9000, isClosable: true, position: "top" });
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: Schema) => {
    const formData: FormData = data;

    try {
      setIsLoading(true);
      const res = await performRequest(formData);
      setIsLoading(false);
      onSuccess(res, queryClient, toast);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({ title: "Error", description: "There was an error, please try again.", status: "error" });
    }
  };

  return (
    <Stack spacing="3">
      <HStack spacing="5">
        <TextInputField required {...NAME_FIELD} errors={errors} register={register} />
      </HStack>
      <SwitchField {...AUTO_ADD_FIELD} sideLayout errors={errors} control={control} />
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText={proceedText}
          isLoading={isLoading}
        />
      </HStack>
    </Stack>
  );
}

export function CreatePathForm({ onClose }: { onClose: () => void }) {
  const onSuccess = useCallback(
    (res: Path, queryClient: QueryClient, toast: CreateToastFnReturn) => {
      queryClient.invalidateQueries([QUERY_KEYS.paths]);
      queryClient.invalidateQueries([QUERY_KEYS.defaultPaths]);
      onClose();
      toast({
        title: "New Path Created.",
        description: <Link to={generatePath(PEOPLE_TEAM_ROUTES.paths.show, { id: res.id })}>Click to view</Link>,
        status: "success",
      });
    },
    [onClose],
  );

  return PathForm({
    performRequest: (data) => apiClient.post<Path>(API_ROUTES.paths.base, data),
    onSuccess,
    onClose,
    proceedText: "Create",
    defaultValues: {},
  });
}

export function UpdatePathForm({ workflow, onClose }: { workflow: Path; onClose: () => void }) {
  const onSuccess = useCallback(
    (_: Path, queryClient: QueryClient, toast: CreateToastFnReturn) => {
      queryClient.invalidateQueries([QUERY_KEYS.paths]);
      queryClient.invalidateQueries([QUERY_KEYS.defaultPaths]);
      onClose();
      toast({ title: "Path updated.", status: "success" });
    },
    [onClose],
  );

  return PathForm({
    performRequest: (data) => apiClient.put<Path>(generatePath(API_ROUTES.paths.show, { id: workflow.id }), data),
    onSuccess,
    onClose,
    proceedText: "Update",
    defaultValues: { name: workflow.name, autoAdd: workflow.autoAdd },
  });
}

export function DuplicatePathForm({ path, onClose }: { path: Path; onClose: () => void }) {
  const onSuccess = useCallback(
    (res: Path, queryClient: QueryClient, toast: CreateToastFnReturn) => {
      queryClient.invalidateQueries([QUERY_KEYS.paths]);
      queryClient.invalidateQueries([QUERY_KEYS.defaultPaths]);
      onClose();
      toast({
        title: "Path Duplicated",
        description: <Link to={generatePath(PEOPLE_TEAM_ROUTES.paths.show, { id: res.id })}>Click to view</Link>,
        status: "success",
      });
    },
    [onClose],
  );

  return PathForm({
    performRequest: async (data) => {
      const res = await apiClient.post<Path>(generatePath(API_ROUTES.paths.duplicate, { id: path.id }), { ...data });
      return res;
    },
    onSuccess,
    onClose,
    proceedText: "Duplicate",
    defaultValues: {
      name: `${path.name} (Copy)`,
      autoAdd: path.autoAdd,
    },
  });
}

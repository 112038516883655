import {
  Box, Flex, HStack, Link, SystemStyleObject, Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { SHARED_COMPANY_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { RandomEmptyPendingNewHiresState } from "components/EmptyComponents/RandomEmptyState";
import { PeopleTeamDashboardNewHire } from "pages/PeopleTeam/DashboardPage/DashboardDataTypes";
import { newHireJourneyPathById } from "helpers/url";
import IssueTag from "components/Tag/IssueTag";
import StartDateTag from "components/Tag/StartDateTag";
import ControlledDataTable, { ControlledDataTableProps } from "./ControlledDataTable";

const columnHelper = createColumnHelper<PeopleTeamDashboardNewHire>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: "name",
    header: "New Hire",
    minSize: 250,
    size: 250,
    cell: (row) => (
      <Link
        as={RouterLink}
        _hover={{ textDecoration: "none" }}
        to={{
          pathname: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: row.getValue().id }),
        }}
        width="fit-content"
      >
        <HStack spacing="3">
          <Flex direction="column">
            <Text fontWeight="medium">{row.getValue().user.fullName || "Unknown"}</Text>
            <Text color="fg.muted">{row.getValue().user.title || ""}</Text>
          </Flex>
        </HStack>
      </Link>
    ),
  }),
  columnHelper.accessor("startDate", {
    id: "startDate",
    cell: (info) => <StartDateTag startDate={info.getValue()} />,
    header: "Start Date",
  }),
  columnHelper.accessor((row) => row, {
    id: "currentUserOpenTasksCount",
    header: "My Outstanding Tasks",
    cell: (info) => (
      <Box textAlign="center">
        <Link as={RouterLink} to={`${generatePath(SHARED_ROUTES.tasks.index)}?newHireId=${info.getValue()?.id}`}>
          {info.getValue()?.currentUserOpenTasksCount}
        </Link>
      </Box>
    ),
    meta: { headerHStackProps: { justifyContent: "center" } },
  }),
  columnHelper.accessor((row) => row.othersOpenTasksCount, {
    id: "othersOpenTasksCount",
    header: "Team Outstanding Tasks",
    cell: (info) => <Box textAlign="center">{info.getValue()}</Box>,
    meta: { headerHStackProps: { justifyContent: "center" } },
  }),
  columnHelper.accessor((row) => row, {
    id: "issuesCount",
    header: "Issues",
    cell: (info) => (
      <Box textAlign="center">
        <IssueTag issuesCount={info.getValue()?.issuesCount} newHireJourneyId={info.getValue()?.id} />
      </Box>
    ),
    meta: { headerHStackProps: { justifyContent: "center" } },
  }),
  columnHelper.accessor((row) => row, {
    id: "tasks",
    header: "Tasks",
    enableSorting: false,
    cell: (info) => (
      <Link
        as={RouterLink}
        _hover={{ textDecoration: "underline" }}
        to={`${newHireJourneyPathById(info.getValue()?.id)}?t=5`}
      >
        View Tasks
      </Link>
    ),
  }),
];

interface PeopleTeamUserDashboardTableProps {
  data?: PeopleTeamDashboardNewHire[];
  isLoading?: boolean;
  sx?: SystemStyleObject;
}

export default function PeopleTeamUserDashboardTable({
  data,
  isLoading,
  sx,
  ...rest
}: PeopleTeamUserDashboardTableProps) {
  const tableProps: ControlledDataTableProps<PeopleTeamDashboardNewHire> = {
    isLoading,
    data: data || [],
    columns,
    initialPagination: { pageSize: 20, pageIndex: 0 },
    defaultSort: [{ id: "startDate", desc: true }],
    textWrap: true,
    emptyStateComponent: <RandomEmptyPendingNewHiresState />,
    variant: "simple",
  };

  return (
    <ControlledDataTable
      containerProps={{
        borderRadius: "8px",
      }}
      sx={{
        "td:first-of-type": {
          "padding-left": "30px",
        },
        th: {
          "border-color": "transparent",
        },
        thead: {
          position: "relative",
          _after: {
            content: "''",
            position: "absolute",
            bottom: "0",
            height: "2px",
            backgroundColor: "tableUnderlineBorder.DEFAULT",
            width: "calc(100% - 68px)",
            left: "34px",
            right: "34px",
          },
        },
        "th:first-of-type": {
          "padding-left": "30px",
        },
        width: { base: "100%", xl: "973px" },
        minWidth: { base: "400px", xl: "973px" },
        ...sx,
      }}
      thProps={{ background: "white", paddingTop: 8 }}
      {...tableProps}
    />
  );
}

PeopleTeamUserDashboardTable.defaultProps = {
  data: [],
  isLoading: false,
  sx: undefined,
};

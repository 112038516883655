import { Flex, useToast } from "@chakra-ui/react";
import { ExtendedNewHireJourney, Task } from "models/joinedModels";
import { Step, StepState } from "components/Steps";
import { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { NewHireJourney } from "models/onboarding";
import { useNavigate } from "react-router-dom";
import { apiNewHireJourneyPath, pendingNewHireJourneysPath } from "helpers/url";
import { QUERY_KEYS } from "definitions/constants";
import { sortAssignedActionsByTriggerDate } from "helpers/assignedAction";
import { pullScheduledActions } from "helpers/newHireJourney";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { sortTasksByNotifier } from "helpers/task";
import { ImmediateActionsSummary, StepContent, TotalActionsSummary } from "./Components";
import useAllNewHireJourneyTasks from "./useAllNewHireJourneyTasks";

interface ReviewEverythingProps {
  newHireJourney: ExtendedNewHireJourney;
}

function ReviewEverything(props: ReviewEverythingProps) {
  const { newHireJourney } = props;
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const { tasks: allTasks } = useAllNewHireJourneyTasks({ newHireJourney });

  const allActions = useMemo(
    () => sortAssignedActionsByTriggerDate(pullScheduledActions(newHireJourney?.assignedPaths || []) as AssignedAction[]),
    [newHireJourney?.assignedPaths],
  );

  const emails = useMemo(
    () => allActions.filter((assignedAction) => assignedAction.actionType === "email") as AssignedAction[],
    [allActions],
  );

  const chatMessages = useMemo(
    () => allActions.filter((assignedAction) => assignedAction.actionType === "chat") as AssignedAction[],
    [allActions],
  );

  const tasks = useMemo(() => sortTasksByNotifier((allTasks || []) as Task[]), [allTasks]);

  /* HandleOnSubmit */
  const { mutate: onSubmit } = useMutation({
    mutationFn: (_data: object) => apiClient.put<NewHireJourney>(apiNewHireJourneyPath(newHireJourney), {
      onboardingJourney: {
        pending: false,
        workflowsStatus: "enabled",
      },
    }),
    onSuccess: async () => {
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourneys]);
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourneysPending]);
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourneysPendingCount]);
      toast({
        title: `${newHireJourney?.user?.firstName}'s onboarding has started!`,
      });
    },
    onError: (mutateError) => {
      console.log(`Error starting ${newHireJourney?.user?.fullName}'s onboarding journey`, mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  const handleOnSubmit = useCallback(
    ({ callback }: { callback: (state: StepState) => void }) => {
      // handleSubmit built in - if the form is invalid, this function will not be called.
      // Because of this, if we pull "updateData" and its an empty object (no dirty fields),
      // we can just move to the next step
      callback(StepState.loading);
      onSubmit(
        {},
        {
          onSuccess: () => {
            callback(StepState.complete);
            navigate(pendingNewHireJourneysPath());
          },
          onError: (error) => {
            console.log("error", error);
            callback(StepState.initial);
          },
        },
      );
    },
    [navigate, onSubmit],
  );

  return (
    <Step
      id="review-everything"
      onClickNext={(callback) => {
        handleOnSubmit({ callback });
      }}
    >
      <StepContent title="Review onboarding summary">
        <Flex direction="column" gap="16" px="8" height="100%">
          <ImmediateActionsSummary
            {...{
              newHireJourney,
              chatMessages,
              emails,
              tasks,
            }}
          />
          <TotalActionsSummary
            {...{
              newHireJourney,
              chatMessages,
              emails,
              tasks,
            }}
          />
        </Flex>
      </StepContent>
    </Step>
  );
}

export default ReviewEverything;

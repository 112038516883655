/* eslint-disable object-curly-newline */
import { Container, Divider, Stack, StackDivider, Text } from "@chakra-ui/react";
import { PlanStepProps } from "components/Form/typings";
import { Helpers } from "hooks/useStep";
import { useState } from "react";
import WizardCard, { CardContent, CardHeader } from "./Cardheader";
import { UserSelectField } from "./Fields";

const FIELDS = {
  new_hire: {
    label: "New Hire",
    name: "userId",
  },
  manager: {
    label: "Manager",
    name: "managerId",
  },
  buddy: {
    label: "Buddy",
    name: "buddyId",
    helperText:
      "When choosing a buddy, ensure to consider someone who has time to support and mentor, and relevant skills. Communication is key.",
  },
};

export default function Step1AddUser({
  control,
  errors,
  goToPrevStep,
  goToNextStep,
  canGoToNextStep,
  canGoToPrevStep,
}: PlanStepProps & Partial<Helpers>) {
  // this is a workaround to bust the cache of react select when
  // a new user is added to the backend for the Select Manager
  // dropdown
  const [cacheKey] = useState("");
  return (
    <WizardCard>
      <CardHeader
        headerText="Add New Hire Buddy"
        {...{ goToPrevStep, goToNextStep, canGoToNextStep, canGoToPrevStep }}
      />
      <Divider mb="4" />
      <CardContent>
        <Container mb="10">
          <Text color="fg.emphasized">
            The New Hire&apos;s Buddy is an important part of the New Hire&apos;s team! Ensure to select someone who is
            great at communication and wants to be helpful! It is more important the person is interested in supporting
            and helping than their work based skills.
          </Text>
        </Container>
        <Stack spacing="5" divider={<StackDivider />}>
          <UserSelectField cacheKey={cacheKey} {...FIELDS.new_hire} control={control} errors={errors} readOnly />
          <UserSelectField cacheKey={cacheKey} {...FIELDS.manager} control={control} errors={errors} readOnly />
          <UserSelectField cacheKey={cacheKey} {...FIELDS.buddy} control={control} errors={errors} />
        </Stack>
      </CardContent>
    </WizardCard>
  );
}

/* eslint-disable no-nested-ternary */
import {
  Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import useCaminoStore from "hooks/useCaminoStore";
import TaskTab from "./TaskTab";

const TAB_QUERY_PARAM = "t";

export default function NewHireOnboardingPlanPage() {
  const [newHireJourney] = useCaminoStore((state) => [state.currentNewHireJourney]);
  const [searchParams, setSearchParams] = useSearchParams();

  const tabIndex = Number(searchParams.get(TAB_QUERY_PARAM)) ?? 0;
  const setTabIndex = (index: number) => setSearchParams(
    (params) => {
      params.set(TAB_QUERY_PARAM, String(index));
      return params;
    },
    { replace: true },
  );

  return (
    <AppPage>
      <Tabs defaultIndex={tabIndex} onChange={setTabIndex} size="lg" variant="indicator" isLazy>
        <TabList>
          <Tab>Tasks</Tab>
          <Tab>Connections</Tab>
          <Tab>Goals</Tab>
        </TabList>
        <TabIndicator />
        <TabPanels>
          <TabPanel>{newHireJourney && <TaskTab newHireJourney={newHireJourney} />}</TabPanel>
          <TabPanel>Your Connections</TabPanel>
          <TabPanel>30 / 60 / 90 Day Goals.</TabPanel>
        </TabPanels>
      </Tabs>
    </AppPage>
  );
}

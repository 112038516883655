import ROLE from "models/role";
import {
  MANAGER_ROUTES,
  ONBOARDER_ROUTES,
  PEOPLE_TEAM_ROUTES,
  SHARED_ROUTES,
} from "definitions/constants/routeConstants";
import { User } from "models/user";

export function roleFromPath(path: string) {
  const pathItems = path.split("/").filter(Boolean);
  if (!pathItems) {
    return null;
  }
  switch (pathItems[0]) {
    case "onboarder":
      return ROLE.ONBOARDER;
    case "people-team":
      return ROLE.PEOPLE_TEAM;
    case "manager":
      return ROLE.MANAGER;
    default:
      return null;
  }
}

export function roleInOrder(user: Partial<User> | null) {
  // this is half baked and needs more thought, including it to
  // get rid of error with the new-hires/overview page
  if (!user?.permission?.length) {
    return null;
  }
  if (user.permission.includes(ROLE.PEOPLE_TEAM)) {
    return ROLE.PEOPLE_TEAM;
  } else if (user.permission.includes(ROLE.MANAGER)) {
    return ROLE.MANAGER;
  } else if (user.permission.includes(ROLE.ONBOARDER)) {
    return ROLE.ONBOARDER;
  }
  return ROLE.BASIC;
}

export function areSameRoleFromPaths(path: string, comparePath: string) {
  const pathItems = path.split("/").filter(Boolean);
  const comparePathItems = comparePath.split("/").filter(Boolean);
  if (!pathItems && !comparePathItems) {
    return true;
  }
  if (!pathItems || !comparePathItems) {
    return false;
  }
  return pathItems[0] === comparePathItems[0];
}

export function redirectPathByRole(role: ROLE) {
  switch (role) {
    case ROLE.BASIC:
      return SHARED_ROUTES.home; // FIXME: this should be the coworker dashboard
    case ROLE.ONBOARDER:
      return ONBOARDER_ROUTES.dashboard;
    case ROLE.COMPANY_ADMIN:
    case ROLE.PEOPLE_TEAM:
      return PEOPLE_TEAM_ROUTES.dashboard;
    case ROLE.MANAGER:
      return MANAGER_ROUTES.dashboard;
    default:
      return SHARED_ROUTES.home;
  }
}

import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import NewConnectionForm from "components/ModalForm/NewConnectionForm";
import { OnboardingPlan } from "models/onboarding";
import React from "react";

interface AddConnectionButtonWithModalProps {
  onboardingPlan: OnboardingPlan;
}

export default function AddConnectionButtonWithModal({ onboardingPlan }: AddConnectionButtonWithModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);

  return (
    <>
      <Button onClick={onOpen}>Add Connection</Button>

      <Modal initialFocusRef={initialRef} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Connection</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <NewConnectionForm onboardingPlan={onboardingPlan} onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

/* eslint-disable import/prefer-default-export */
import { Heading, HeadingProps } from "@chakra-ui/react";

export function CaminoHeading({ children, ...rest }: HeadingProps) {
  return (
    <Heading size="xs" fontSize="xl" fontWeight="bold">
      {children}
    </Heading>
  );
}

export function DashboardHeading({ children, ...rest }: HeadingProps) {
  return (
    <Heading size="2xs" fontSize="lg" fontWeight="semibold">
      {children}
    </Heading>
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { Step } from "components/Steps";
import { useEffect } from "react";
import TimezoneSelectField from "components/FormElements/Fields/SelectFields/TimezoneSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import SingleFormColumn from "components/FormElements/SingleFormColumn";
import {
  COUNTRY_FIELD,
  DEPARTMENT_FIELD,
  LINKEDIN_URL_FIELD,
  LOCATION_FIELD,
  TIMEZONE_FIELD,
  newHireSchema,
} from "./definition";
import useSubmitNewHire from "./useSubmitNewHireUpdate";
import { StepContent } from "./Components";

interface AddAdditionalProfileInfoStepProps {
  newHireJourney: ExtendedNewHireJourney;
}

function AddAdditionalProfileInfoStep(props: AddAdditionalProfileInfoStepProps) {
  const { newHireJourney } = props;
  const { handleOnSubmit } = useSubmitNewHire({ newHireJourneyId: newHireJourney?.id || "" });
  const form = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(
      newHireSchema.pick([
        LINKEDIN_URL_FIELD.name as "linkedinUrl",
        LOCATION_FIELD.name as "location",
        COUNTRY_FIELD.name as "country",
        TIMEZONE_FIELD.name as "timezone",
        DEPARTMENT_FIELD.name as "department",
      ]),
    ),
    defaultValues: {
      linkedinUrl: newHireJourney?.user?.linkedinUrl || "",
      location: newHireJourney?.user?.location || "",
      country: newHireJourney?.user?.country || "",
      timezone: newHireJourney?.user?.timezone || "",
      department: newHireJourney?.user?.department || "",
    },
  });
  const {
    control,
    register,
    formState: { errors },
    reset,
  } = form;

  useEffect(() => {
    if (newHireJourney) {
      // Reset the form values when newHireJourney is updated
      reset({
        linkedinUrl: newHireJourney?.user?.linkedinUrl || "",
        location: newHireJourney?.user?.location || "",
        country: newHireJourney?.user?.country || "",
        timezone: newHireJourney?.user?.timezone || "",
        department: newHireJourney?.user?.department || "",
      });
    }
  }, [newHireJourney, reset]);

  return (
    <Step
      id="add-additional-profile-info"
      onClickNext={(callback) => {
        handleOnSubmit({
          callback,
          form,
        });
      }}
    >
      <StepContent title="Add additional profile info">
        <SingleFormColumn>
          <TextInputField {...LINKEDIN_URL_FIELD} errors={errors} register={register} />
          <TextInputField {...LOCATION_FIELD} register={register} errors={errors} />
          <TextInputField {...COUNTRY_FIELD} register={register} errors={errors} />
          <TimezoneSelectField {...TIMEZONE_FIELD} control={control} errors={errors} />
          <TextInputField {...DEPARTMENT_FIELD} register={register} errors={errors} />
        </SingleFormColumn>
      </StepContent>
    </Step>
  );
}

export default AddAdditionalProfileInfoStep;

import { useToast } from "@chakra-ui/react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import apiClient from "services/ApiClient";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { apiAssignedActionPathById } from "helpers/url";

interface UseDeleteAssignedAction {
  assignedActionId: string;
  onSuccess?: () => void;
}

export default function useDeleteAssignedAction({ assignedActionId, onSuccess }: UseDeleteAssignedAction) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate: onSubmit, isLoading } = useMutation({
    mutationFn: () => apiClient.delete<AssignedAction>(apiAssignedActionPathById(assignedActionId)),
    onSuccess: (res) => {
      queryClient.invalidateQueries([QUERY_KEYS.assignedActions, assignedActionId]);
      toast({
        title: "Action removed!",
      });
      onSuccess?.();
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Remove Error", mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return { onSubmit, isLoading };
}

import { Flex, FlexProps } from "@chakra-ui/react";
import { DEFAULT_FIELD_GAP } from "definitions/constants/styling";

export default function SingleFormColumn({ children, ...rest }: FlexProps) {
  return (
    <Flex direction="column" gap={DEFAULT_FIELD_GAP} width="100%" px="32" {...rest}>
      {children}
    </Flex>
  );
}

/* eslint-disable object-curly-newline */
import { Avatar, Card, CardProps, HStack, Text, VStack } from "@chakra-ui/react";
import { WelcomeMessage } from "models/welcomeFlow";
import LoadingCard from "./LoadingCard";

interface WelcomeMessageProfileCardProps extends CardProps {
  message: WelcomeMessage;
}

export function VerticalWelcomeMessageProfileCard({ message, ...rest }: WelcomeMessageProfileCardProps) {
  if (!message) {
    return <LoadingCard />;
  }

  const avatarProps = { src: message?.author?.avatarUrl, name: message?.author?.fullName };

  return (
    <Card
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius="8px"
      border="1px solid var(--stroke-vibes, #F1F1F1)"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      paddingLeft="9"
      paddingRight="25"
      paddingY={12}
      maxW="368"
      {...rest}
    >
      <VStack gap="14px" alignItems="start">
        <VStack alignItems="start" gap="12px">
          <Avatar size="xl" {...avatarProps} />
          <VStack alignItems="start" gap="0">
            <Text fontWeight="400" fontSize={20}>
              {message?.author?.firstName}
            </Text>
            <Text fontWeight="600" fontSize={20}>
              {message?.author?.title}
            </Text>
          </VStack>
        </VStack>
        <Text fontSize={24}>{message?.content}</Text>
      </VStack>
    </Card>
  );
}

export function HorizontalWelcomeMessageProfileCard({ message, ...rest }: WelcomeMessageProfileCardProps) {
  if (!message) {
    return <LoadingCard />;
  }

  const avatarProps = { src: message?.author?.avatarUrl, name: message?.author?.fullName };

  return (
    <Card
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius="8px"
      border="1px solid var(--stroke-vibes, #F1F1F1)"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      paddingX="70"
      paddingY="46"
      maxW="900"
      {...rest}
    >
      <HStack gap="60px" alignItems="start">
        <VStack alignItems="center" gap="12px">
          <Avatar size="xl" {...avatarProps} />
          <VStack alignItems="center" gap="0">
            <Text fontWeight="400" fontSize={20}>
              {message?.author?.firstName}
            </Text>
            <Text fontWeight="600" fontSize={20}>
              {message?.author?.title}
            </Text>
          </VStack>
        </VStack>
        <Text mt="12px" fontSize={24}>{message?.content}</Text>
      </HStack>
    </Card>
  );
}

/* eslint-disable object-curly-newline */
import { Card, CardProps, Image, Text, VStack } from "@chakra-ui/react";
import { WelcomeMessageWithImage } from "models/welcomeFlow";
import LoadingCard from "./LoadingCard";

interface WelcomeImageCardProps extends CardProps {
  message: WelcomeMessageWithImage | null;
}

export default function WelcomeImageCard({ message, ...rest }: WelcomeImageCardProps) {
  if (!message) {
    return <LoadingCard />;
  }

  return (
    <Card
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius="8px"
      border="1px solid var(--stroke-vibes, #F1F1F1)"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      paddingX="9"
      paddingY={8}
      w="100%"
      display="inline-block"
      mb="2"
      {...rest}
    >
      <VStack gap="0" alignItems="center">
        { message?.mediaUrl && (
          <Image
            key={message?.mediaUrl}
            w="100%"
            borderRadius="xl"
            mb={2}
            // display="inline-block"
            src={message?.mediaUrl}
            alt={`${message?.author?.fullName}'s image`}
          />
        )}
        <VStack gap="12px" alignItems="start">
          <Text fontSize={24}>{message?.content}</Text>
          <Text mr="24px" alignSelf="end" fontSize={20}>{`- ${message?.author?.firstName}`}</Text>
          <VStack alignItems="start" gap="2px">
            <Text fontWeight="400" fontSize={20}>
              {message?.authorDescription ? message?.authorDescription : message?.author?.title}
            </Text>
            <Text fontWeight="600" fontSize={20}>
              {message?.authorTeam}
            </Text>
          </VStack>
        </VStack>
      </VStack>
    </Card>
  );
}

import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Card, CardBody, Flex, Heading, Text, Button, Skeleton, CreateToastFnReturn,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import InnerSidebar from "layouts/InnerSidebar";
import { User } from "models/user";
import { useCallback, useEffect } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export default function IndividualPage() {
  const { data: currentUser, isLoading } = useQuery<User>({
    queryKey: [QUERY_KEYS.users, "current"],
    queryFn: async () => apiClient.get<User>(generatePath(API_ROUTES.users.show, { id: "current" })),
  });

  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Individual Settings",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Individual Settings");
  }, [setBreadcrumbs, setPageTitle]);

  const { data: slackUrl } = useQuery<{ url: string }>({
    queryKey: ["slack-oauth-url"],
    queryFn: () => apiClient.get(API_ROUTES.oauth.slack.individual.url),
    refetchOnWindowFocus: false,
  });

  const redirectToOauth = useCallback(() => {
    window.open(slackUrl?.url, "_blank", "rel=noopener noreferrer");
  }, [slackUrl]);

  const { performRequest } = useDeferredApiCall(generatePath(API_ROUTES.oauth.slack.individual.base), {
    method: "DELETE",
    onSuccess: (_first, queryClient, toast) => {
      queryClient.invalidateQueries([QUERY_KEYS.users, "current"]);
      toast({ title: "Successfully removed sending messages authorization", status: "success" });
    },
    onFailure: (_second, _third, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return (
    <AppPage>
      <InnerSidebar>
        <Heading mb="8" size="sm" color="fg.subtle">
          Individual Settings
        </Heading>
        <Card>
          <Skeleton isLoaded={!isLoading}>
            <CardBody>
              <Heading as="h3" color="fg.muted" size="xs">
                Authorizations
              </Heading>
              <Flex direction="column" mt="4">
                <Flex align="center" gap="4">
                  <Text>Camino Authorized to send messages on your behalf</Text>
                  {currentUser?.sendMessageAuthorized ? (
                    <>
                      <CheckIcon aria-label="Authorized" color="green.500" />
                      <Button onClick={performRequest} size="sm">
                        Remove Authorization
                      </Button>
                    </>
                  ) : (
                    <>
                      <CloseIcon aria-label="Not Authorized" color="red.500" />
                      <Button onClick={redirectToOauth} size="sm">
                        Authorize
                      </Button>
                    </>
                  )}
                </Flex>
              </Flex>
            </CardBody>
          </Skeleton>
        </Card>
      </InnerSidebar>
    </AppPage>
  );
}

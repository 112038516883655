import {
  Button, Card, CardBody, CardHeader, Heading, Link,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import GenericTable, { GenericTableProps } from "components/Table/GenericTable";
import AppPage from "layouts/AppPage";
import { ResponseModel } from "services/ApiClient/responseModel";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { NewHireJourney } from "models/onboarding";
import { User } from "models/user";
import { QUERY_KEYS } from "definitions/constants";
import useCaminoStore from "hooks/useCaminoStore";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const columnHelper = createColumnHelper<NewHireJourney>();

const makeColumns = (currentUser: User | null) => [
  columnHelper.accessor((row) => row, {
    id: "name",
    header: "New Hire",
    cell: (row) => (
      <Link
        as={RouterLink}
        to={{
          pathname: generatePath(SHARED_COMPANY_ROUTES.onboardingPlans.show, { id: row.getValue().id }),
        }}
      >
        {row.getValue().user?.fullName || ""}
      </Link>
    ),
  }),
  columnHelper.accessor("cohort", {
    cell: (info) => info.getValue()?.name || "N/A",
    header: "Cohort",
  }),
  columnHelper.accessor("manager", {
    cell: (info) => info.getValue()?.fullName || "TBD",
    header: "Manager",
  }),
  columnHelper.accessor("buddy", {
    cell: (info) => info.getValue()?.fullName || "TBD",
    header: "Buddy",
  }),
  columnHelper.accessor((row) => row, {
    cell: (info) => info.getValue().onboardingPlanUrl && (
    <Link href={info.getValue().onboardingPlanUrl} isExternal>
      Link to Plan
      {" "}
      <ExternalLinkIcon mx="2px" />
    </Link>
    ),
    header: "Onboarding Plan Url",
  }),
  columnHelper.accessor((row) => row, {
    id: "update",
    header: "",
    cell: (row) => currentUser
      && (row.getValue() as NewHireJourney)?.manager?.id === currentUser?.id && (
        <Button
          as={RouterLink}
          to={{
            pathname: generatePath(SHARED_COMPANY_ROUTES.buildOnboardingPlan, { id: row.getValue().id }),
          }}
        >
          Update
        </Button>
    ),
  }),
];

export default function OnboardingPlansPage() {
  const user = useCaminoStore((state) => state.currentUser);
  const columns = makeColumns(user);

  const props: GenericTableProps<NewHireJourney> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<NewHireJourney[]>>(`${API_ROUTES.onboardingPlans.base}${query}`, signal)
      .then((response) => response.payload),
    queryKey: [QUERY_KEYS.onboardingPlans],
    columns,
    meta: { pagination: { perPage: 20 } },
  };

  return (
    <AppPage>
      <Card mt="8" p="4">
        <CardHeader>
          <Heading size="xs" textTransform="capitalize">
            Onboarding Plans
          </Heading>
        </CardHeader>
        <CardBody>
          <GenericTable {...props} />
        </CardBody>
      </Card>
    </AppPage>
  );
}

import { useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Flex, FlexProps, HStack, Stack, useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { User } from "models/user";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { camelCase, get, pick } from "lodash";
import * as yup from "yup";
import { validDate } from "utils/dateFormatter";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import TimezoneSelectField from "components/FormElements/Fields/SelectFields/TimezoneSelectField";

export const FIRST_NAME_FIELD = {
  label: "First Name",
  name: "firstName",
};
export const LAST_NAME_FIELD = {
  label: "Last Name",
  name: "lastName",
};
export const TITLE_FIELD = {
  label: "Job Title",
  name: "title",
};
export const START_DATE_FIELD = {
  label: "Start Date",
  name: "startDate",
  type: "date",
  textTransform: "lowercase",
};
export const PERSONAL_EMAIL_FIELD = {
  label: "Personal Email",
  name: "personalEmail",
  type: "email",
  textTransform: "lowercase",
};
export const WORK_EMAIL_FIELD = {
  label: "Future Work Email",
  name: "workEmail",
  type: "email",
  textTransform: "lowercase",
};
export const LOCATION_FIELD = {
  label: "Location",
  name: "location",
  helperText: "Location of where the new hire will be during onboarding",
};
export const LINKEDIN_URL_FIELD = {
  label: "Linkedin Url",
  name: "linkedinUrl",
  helperText: "The url for the person's Linkedin profile",
};
export const ONBOARDING_PLAN_URL_FIELD = {
  label: "Onboarding Plan Url",
  name: "onboardingPlanUrl",
  helperText: "The url for the onboarding plan",
};
export const TEAM_WIKI_URL_FIELD = {
  label: "Team Wiki Url",
  name: "teamWikiUrl",
  helperText: "The url for the team wiki",
};
export const TEAM_GOALS_URL_FIELD = {
  label: "Team Goals Url",
  name: "teamGoalsUrl",
  helperText: "The url for the team goals",
};
export const TIMEZONE_FIELD = {
  label: "Timezone",
  name: "timezone",
  helperText: "Timezone of where the new hire will be during onboarding",
};
export const SHIPPING_TRACKING_NUMBER_FIELD = {
  label: "Shipping Tracking Number",
  name: "shippingTrackingNumber",
  helperText: "Tracking number for the New Hire's laptop",
};
export const SHIPPING_TRACKING_URL_FIELD = {
  label: "Shipping Tracking Url",
  name: "shippingTrackingUrl",
  helperText: "Link to the shipping tracking number for the New Hire's laptop",
};
export const SHIPPING_CARRIER_FIELD = {
  label: "Shipping Carrier",
  name: "shippingCarrier",
  helperText: "Shipping carrier for the New Hire's laptop (e.g. UPS, FedEx, etc.)",
};
export const INTERNAL_ONBOARDING_CHECKLIST_URL_FIELD = {
  label: "Internal Onboarding Checklist Url",
  name: "internalOnboardingChecklistUrl",
  helperText: "Link to the HR/Ops/IT onboarding checklist for this specific New Hire",
};
export const HRIS_ONBOARDING_URL_FIELD = {
  label: "HRIS Onboarding Url",
  name: "hrisOnboardingUrl",
  helperText: "Link to the specific New Hire's login for Employee Paperwork",
};

export const MANAGER_FIELD = {
  label: "Manager",
  name: "managerId",
};
export const BUDDY_FIELD = {
  label: "Buddy",
  name: "buddyId",
  helperText: "Typically assigned by manager. Can add now if known",
};
export const PEOPLE_TEAM_CONTACT_FIELD = {
  label: "People Team Contact",
  name: "peopleTeamContactId",
};

export interface UserUpdateFormValues {
  firstName: string;
  lastName: string;
  title: string;
  personalEmail?: string;
  workEmail?: string;
  location?: string;
  timezone?: string;
  peopleTeamContactId?: string;
  managerId?: string;
  buddyId?: string;
  startDate?: Date;
  linkedinUrl?: string;
  onboardingPlanUrl?: string;
  teamWikiUrl?: string;
  teamGoalsUrl?: string;
  shippingTrackingNumber?: string;
  shippingTrackingUrl?: string;
  shippingCarrier?: string;
  internalOnboardingChecklistUrl?: string;
  hrisOnboardingUrl?: string;
}

export const newHireSchema = yup.object({
  firstName: yup.string().label("First Name").notRequired(),
  lastName: yup.string().label("Last Name").notRequired(),
  title: yup.string().label("Title").notRequired(),
  managerId: yup.string().label("Manager").notRequired(),
  buddyId: yup.string().label("Buddy").notRequired(),
  peopleTeamContactId: yup.string().label("People Team Contact").notRequired(),
  personalEmail: yup.string().label("Personal Email").email().lowercase()
    .notRequired(),
  workEmail: yup.string().label("Work Email").email().lowercase()
    .notRequired(),
  location: yup.string().label("Location").notRequired(),
  timezone: yup.string().label("Timezone").notRequired(),
  startDate: yup.date().label("Start Date").notRequired(),
  linkedinUrl: yup.string().label("Linkedin Url").notRequired(),
  onboardingPlanUrl: yup.string().label("Onboarding Plan Url").notRequired(),
  teamWikiUrl: yup.string().label("Team Wiki Url").notRequired(),
  teamGoalsUrl: yup.string().label("Team Goals Url").notRequired(),
  [SHIPPING_TRACKING_NUMBER_FIELD.name]: yup.string().label(SHIPPING_TRACKING_NUMBER_FIELD.label).notRequired(),
  [SHIPPING_TRACKING_URL_FIELD.name]: yup.string().label(SHIPPING_TRACKING_URL_FIELD.label).notRequired(),
  [SHIPPING_CARRIER_FIELD.name]: yup.string().label(SHIPPING_CARRIER_FIELD.label).notRequired(),
  [INTERNAL_ONBOARDING_CHECKLIST_URL_FIELD.name]: yup
    .string()
    .label(INTERNAL_ONBOARDING_CHECKLIST_URL_FIELD.label)
    .notRequired(),
  [HRIS_ONBOARDING_URL_FIELD.name]: yup.string().label(HRIS_ONBOARDING_URL_FIELD.label).notRequired(),
});

const values = (user: User | undefined) => ({
  firstName: user?.firstName || null,
  lastName: user?.lastName || null,
  title: user?.title || null,
  personalEmail: user?.personalEmail || null,
  workEmail: user?.workEmail || null,
  linkedinUrl: user?.linkedinUrl || null,
  location: user?.location || null,
  timezone: user?.timezone || null,
  startDate: validDate(user?.onboardingJourney?.startDate) || null,
  managerId: user?.manager?.id || null,
  peopleTeamContactId: user?.onboardingJourney?.peopleTeamContact?.id || null,
  buddyId: user?.onboardingJourney?.buddy?.id || null,
  onboardingPlanUrl: user?.onboardingJourney?.onboardingPlanUrl || null,
  teamWikiUrl: user?.onboardingJourney?.teamWikiUrl || null,
  teamGoalsUrl: user?.onboardingJourney?.teamGoalsUrl || null,
  [SHIPPING_TRACKING_NUMBER_FIELD.name]: get(user?.onboardingJourney, SHIPPING_TRACKING_NUMBER_FIELD.name, null),
  [SHIPPING_TRACKING_URL_FIELD.name]: get(user?.onboardingJourney, SHIPPING_TRACKING_URL_FIELD.name, null),
  [SHIPPING_CARRIER_FIELD.name]: get(user?.onboardingJourney, SHIPPING_CARRIER_FIELD.name, null),
  [INTERNAL_ONBOARDING_CHECKLIST_URL_FIELD.name]: get(
    user?.onboardingJourney,
    INTERNAL_ONBOARDING_CHECKLIST_URL_FIELD.name,
    null,
  ),
  [HRIS_ONBOARDING_URL_FIELD.name]: get(user?.onboardingJourney, HRIS_ONBOARDING_URL_FIELD.name, null),
});

interface UserUpdateFormProps extends FlexProps {
  userId: string;
  fields: Array<keyof UserUpdateFormValues>;
  onSuccess?: () => void;
  onClose?: () => void;
}

export default function UserUpdateForm({
  userId,
  fields,
  onSuccess = () => {},
  onClose = () => {},
  ...restProps
}: UserUpdateFormProps) {
  const camelFields = useMemo(() => fields.map((field) => camelCase(field)), [fields]);
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const { data: user, isLoading } = useQuery<User>({
    queryKey: [QUERY_KEYS.users, userId],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.users.show, { id: userId })),
  });
  const defaultValues = useMemo(() => pick(values(user), camelFields), [user, camelFields]);

  const {
    control,
    formState: { errors, dirtyFields, isDirty },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(newHireSchema),
    defaultValues,
  });

  useEffect(() => {
    setFocus(camelFields[0] as keyof UserUpdateFormValues);
  }, [setFocus, camelFields]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  /* HandleOnSubmit */
  const { mutate: onSubmit, isLoading: submitIsLoading } = useMutation({
    mutationFn: (data: UserUpdateFormValues) => {
      console.log(data);
      const updateData = Object.fromEntries(
        Object.entries(data).filter(([key]) => dirtyFields[key as keyof UserUpdateFormValues] === true),
      );
      const onboardingJourney = pick(updateData, [
        "startDate",
        "peopleTeamContactId",
        "buddyId",
        "onboardingPlanUrl",
        "teamWikiUrl",
        "teamGoalsUrl",
        "shippingTrackingNumber",
        "shippingTrackingUrl",
        "shippingCarrier",
        "internalOnboardingChecklistUrl",
        "hrisOnboardingUrl",
      ]);
      const onboardingJourneyData = onboardingJourney ? { onboardingJourney } : {};
      const {
        startDate, peopleTeamContactId, buddyId, onboardingPlanUrl, teamWikiUrl, teamGoalsUrl, ...rest
      } = updateData;
      return apiClient.put<User>(generatePath(API_ROUTES.users.show, { id: userId }), {
        user: rest,
        ...onboardingJourneyData,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([QUERY_KEYS.users, userId]);
      onSuccess();
      onClose();
      toast({
        title: "Success!",
      });
    },
    onError: (mutateError) => {
      console.log("UserUpdateForm Error", mutateError);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });

  const [userCacheKey, updateUserCacheKey] = useState("");

  return (
    <Flex direction="column" {...restProps}>
      <Stack width="100%">
        {camelFields.includes(FIRST_NAME_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField
            {...FIRST_NAME_FIELD}
            onBlur={(e) => console.log(e)}
            register={register}
            errors={errors}
            disabled={isLoading}
          />
        )}
        {camelFields.includes(LAST_NAME_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...LAST_NAME_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
        {(camelFields.includes(START_DATE_FIELD.name as keyof UserUpdateFormValues)
          || camelFields.includes("startDateFormatted")) && (
          <DatePickerField isClearable control={control} {...START_DATE_FIELD} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(TITLE_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...TITLE_FIELD} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(PERSONAL_EMAIL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...PERSONAL_EMAIL_FIELD} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(WORK_EMAIL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...WORK_EMAIL_FIELD} errors={errors} register={register} disabled={isLoading} />
        )}
        {camelFields.includes(MANAGER_FIELD.name as keyof UserUpdateFormValues) && (
          <UserSelectField
            cacheKey={userCacheKey}
            updateCacheKey={updateUserCacheKey}
            control={control}
            {...MANAGER_FIELD}
            errors={errors}
            includeCreateNewUser
            disabled={isLoading}
          />
        )}
        {camelFields.includes(PEOPLE_TEAM_CONTACT_FIELD.name as keyof UserUpdateFormValues) && (
          <UserSelectField
            cacheKey={userCacheKey}
            updateCacheKey={updateUserCacheKey}
            control={control}
            {...PEOPLE_TEAM_CONTACT_FIELD}
            errors={errors}
            includeCreateNewUser
            disabled={isLoading}
          />
        )}
        {camelFields.includes(BUDDY_FIELD.name as keyof UserUpdateFormValues) && (
          <UserSelectField
            cacheKey={userCacheKey}
            updateCacheKey={updateUserCacheKey}
            control={control}
            {...BUDDY_FIELD}
            errors={errors}
            disabled={isLoading}
          />
        )}
        {camelFields.includes(LINKEDIN_URL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...LINKEDIN_URL_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(LOCATION_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...LOCATION_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(ONBOARDING_PLAN_URL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...ONBOARDING_PLAN_URL_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(TEAM_WIKI_URL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...TEAM_WIKI_URL_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(TEAM_GOALS_URL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...TEAM_GOALS_URL_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(TIMEZONE_FIELD.name as keyof UserUpdateFormValues) && (
          <TimezoneSelectField control={control} {...TIMEZONE_FIELD} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(SHIPPING_TRACKING_NUMBER_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField
            {...SHIPPING_TRACKING_NUMBER_FIELD}
            register={register}
            errors={errors}
            disabled={isLoading}
          />
        )}
        {camelFields.includes(SHIPPING_TRACKING_URL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...SHIPPING_TRACKING_URL_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(SHIPPING_CARRIER_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...SHIPPING_CARRIER_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
        {camelFields.includes(INTERNAL_ONBOARDING_CHECKLIST_URL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField
            {...INTERNAL_ONBOARDING_CHECKLIST_URL_FIELD}
            register={register}
            errors={errors}
            disabled={isLoading}
          />
        )}
        {camelFields.includes(HRIS_ONBOARDING_URL_FIELD.name as keyof UserUpdateFormValues) && (
          <TextInputField {...HRIS_ONBOARDING_URL_FIELD} register={register} errors={errors} disabled={isLoading} />
        )}
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText="Update"
          isLoading={submitIsLoading}
          proceedDisabled={!isDirty}
        />
      </HStack>
    </Flex>
  );
}

UserUpdateForm.defaultProps = {
  onSuccess: () => {},
  onClose: () => {},
};

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { Step } from "components/Steps";
import buildPathsUrl from "assets/undraw_completed_tasks.svg";
import { useEffect } from "react";
import { WORKFLOW_SELECT_FIELD } from "definitions/constants/fields";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import PathsSelectField from "components/FormElements/Fields/SelectFields/PathsSelectField";
import SingleFormColumn from "components/FormElements/SingleFormColumn";
import useSubmitNewHire from "./useSubmitNewHireUpdate";
import { StepContent } from "./Components";
import { newHireSchema } from "./definition";

interface AddPathsStepProps {
  newHireJourney: ExtendedNewHireJourney;
}

function AddPathsStep(props: AddPathsStepProps) {
  const { newHireJourney } = props;
  const query = useQueryClient();
  const { handleOnSubmit } = useSubmitNewHire({
    newHireJourneyId: newHireJourney?.id || "",
    onSuccess: () => {
      query.invalidateQueries([QUERY_KEYS.newHireJourneys]);
    },
  });
  const form = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(newHireSchema.pick(["workflowIds"])),
    defaultValues: {
      workflowIds: newHireJourney?.assignedPaths?.map((assignedPath) => assignedPath.workflow.id),
    },
  });

  const {
    control,
    formState: { errors },
    reset,
  } = form;

  useEffect(() => {
    if (newHireJourney?.assignedPaths) {
      // Reset the form values when newHireJourney is updated
      reset({
        workflowIds: newHireJourney.assignedPaths.map((assignedPath) => assignedPath.workflow.id),
      });
    }
  }, [newHireJourney?.assignedPaths, newHireJourney?.startDate, reset]);

  return (
    <Step
      id="add-paths"
      onClickNext={(callback) => {
        handleOnSubmit({
          callback,
          form,
        });
      }}
    >
      <StepContent
        title="Add paths"
        imageUrl={buildPathsUrl}
        imageDescription={`Add Paths To ${newHireJourney?.user?.fullName}'s Onboarding`}
      >
        <SingleFormColumn>
          <PathsSelectField {...WORKFLOW_SELECT_FIELD} control={control} errors={errors} required />
        </SingleFormColumn>
      </StepContent>
    </Step>
  );
}

export default AddPathsStep;

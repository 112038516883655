import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  DrawerFooter,
  Tooltip,
  useToast,
  ButtonGroup,
  Button,
} from "@chakra-ui/react";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import TextAreaField from "components/FormElements/Fields/TextAreaField";
import { API_ROUTES } from "definitions/constants/routeConstants";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import apiClient from "services/ApiClient";

const FEEDBACK_TYPE_FIELD = {
  type: "SELECT",
  label: "Feedback Type",
  name: "feedbackType",
  options: [
    "Onboarding feedback",
    "Report a bug",
    "Broken link",
    "Out of date information",
    "Who owns this information?",
    "Camino product feedback",
    "Other",
  ].map((value) => ({ label: value, value })),
};

const CONTENT_URL_FIELD = {
  type: "text",
  label: "Content Url",
  helperText: "What is the specific URL (if any) that you are giving feedback about?",
  name: "contentUrl",
};

const FEEDBACK_FIELD = {
  type: "text",
  label: "Feedback",
  name: "feedback",
};

export default function FeedBackDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const firstField = React.useRef();

  const {
    control,
    reset,
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    register,
  } = useForm({
    mode: "onBlur",
  });

  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [reset, isSubmitSuccessful]);

  /* HandleOnSubmit */
  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const res = await apiClient.post<{ id: string }>(API_ROUTES.feedback, { feedback: data });
      setIsLoading(false);
      console.log(res);
      onClose();
      toast({
        title: "Feedback Successfully Submitted.",
      });
      // log the newly created item to the console
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    }
  };

  return (
    <>
      <Tooltip label="Give Feedback">
        <Button
          variant="solid"
          colorScheme="brand"
          aria-label="Feedback"
          fontSize="22px"
          size="sm"
          zIndex="banner"
          transform="rotate(-90deg) translateY(-50%)"
          // icon={<TfiPencilAlt />}
          onClick={onOpen}
          sx={{
            position: "absolute",
            right: -16,
            top: "50%",
          }}
        >
          Feedback
        </Button>
      </Tooltip>

      <Drawer isOpen={isOpen} placement="right" initialFocusRef={firstField} onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Submit Feedback</DrawerHeader>

          <DrawerBody>
            <Stack>
              <SingleSelectField required {...FEEDBACK_TYPE_FIELD} errors={errors} control={control} />
              <TextInputField {...CONTENT_URL_FIELD} errors={errors} register={register} />
              <TextAreaField {...FEEDBACK_FIELD} errors={errors} register={register} />
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <ButtonGroup display="flex" justifyContent="flex-end">
              <ProceedCancelButtons
                handleSubmit={handleSubmit(onSubmit)}
                onClose={onClose}
                proceedText="Submit Feedback"
                proceedDisabled={!isDirty}
                isLoading={isLoading}
              />
            </ButtonGroup>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

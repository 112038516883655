import { Link as RouterLink, generatePath, useParams, useSearchParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { ExtendedNewHireJourney } from "models/joinedModels";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  Center,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import { QUERY_KEYS } from "definitions/constants";
import { useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import OverviewTab from "./Tabs/OverviewTab";
import ChatMessagesTab from "./Tabs/ChatMessagesTab";
import EmailsTab from "./Tabs/EmailsTab";
import TasksTab from "./Tabs/TasksTab";
import NewHireJourneyTitleBar from "../NewHiresTitleBar";
import FieldsTab from "./Tabs/FieldsTab";
import PathsTab from "./Tabs/PathsTab";
import ActivityFeedTab from "./Tabs/ActivityFeedTab";

const TAB_QUERY_PARAM = "t";

export default function NewHireJourneyPage() {
  const { id } = useParams<{ id: string }>();
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const alertTextColor = useColorModeValue("fg.subtle", "inherit");

  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = Number(searchParams.get(TAB_QUERY_PARAM)) ?? 0;
  const setTabIndex = (index: number) => setSearchParams(
    (params) => {
      params.set(TAB_QUERY_PARAM, String(index));
      return params;
    },
    { replace: true },
  );
  const { data, isLoading } = useQuery<ExtendedNewHireJourney>({
    queryKey: [QUERY_KEYS.newHireJourney, id],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.newHireJourneys.show, { id })),
  });

  useEffect(() => {
    if (data && !isLoading) {
      setPageTitle(`${data?.user?.fullName ? `${data.user.fullName}'s Onboarding` : ""}`);
      setBreadcrumbs([
        {
          name: "New Hires",
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.index),
          isCurrentPage: false,
        },
        {
          name: data?.user?.fullName,
          isCurrentPage: true,
        },
      ]);
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      {data?.isPending && (
        <RouterLink to={generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.pending.show, { id })}>
          <Alert status="warning" borderTopRadius="10px">
            <AlertIcon />
            <AlertTitle color={alertTextColor}>This new hire is pending!</AlertTitle>
            <AlertDescription color={alertTextColor}>
              Visit the pending new hire page to add additional information and kick off the automations.
            </AlertDescription>
          </Alert>
        </RouterLink>
      )}
      <NewHireJourneyTitleBar newHireJourney={data} />
      <Card mt="6">
        <CardBody>
          <Tabs defaultIndex={tabIndex} onChange={setTabIndex} size="lg" variant="underline" isLazy>
            <TabList>
              <Tab color="fg.muted">Overview</Tab>
              <Tab color="fg.muted">Fields</Tab>
              <Tab color="fg.muted">Paths</Tab>
              <Tab color="fg.muted">Chat Messages</Tab>
              <Tab color="fg.muted">Emails</Tab>
              <Tab color="fg.muted">Tasks</Tab>
              <Tab color="fg.muted">Activity Feed</Tab>
            </TabList>
            <TabIndicator />
            <TabPanels>
              <TabPanel>
                {data ? (
                  <OverviewTab newHireJourney={data} />
                ) : (
                  <Center>
                    <Spinner thickness="4px" size="xl" />
                  </Center>
                )}
              </TabPanel>
              <TabPanel>
                {data ? (
                  <FieldsTab newHireJourney={data} />
                ) : (
                  <Center>
                    <Spinner thickness="4px" size="xl" />
                  </Center>
                )}
              </TabPanel>
              <TabPanel>
                {data ? (
                  <PathsTab newHireJourney={data} />
                ) : (
                  <Center>
                    <Spinner thickness="4px" size="xl" />
                  </Center>
                )}
              </TabPanel>
              <TabPanel>
                {data ? (
                  <ChatMessagesTab newHireJourney={data} />
                ) : (
                  <Center>
                    <Spinner thickness="4px" size="xl" />
                  </Center>
                )}
              </TabPanel>
              <TabPanel>
                {data ? (
                  <EmailsTab newHireJourney={data} />
                ) : (
                  <Center>
                    <Spinner thickness="4px" size="xl" />
                  </Center>
                )}
              </TabPanel>
              <TabPanel>
                {data ? (
                  <TasksTab newHireJourney={data} />
                ) : (
                  <Center>
                    <Spinner thickness="4px" size="xl" />
                  </Center>
                )}
              </TabPanel>
              <TabPanel>
                {data ? (
                  <ActivityFeedTab newHireJourney={data} />
                ) : (
                  <Center>
                    <Spinner thickness="4px" size="xl" />
                  </Center>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </AppPage>
  );
}

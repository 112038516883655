import React, { useCallback } from "react";
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import useDeleteAssignedAction from "./hooks/useDeleteAssignedAction";

interface DeleteAssignedActionButtonProps {
  assignedActionId: string;
  onSuccess?: () => void;
  iconSize?: string;
  alertHeader?: string;
}

export default function DeleteAssignedActionButton({
  assignedActionId,
  onSuccess,
  iconSize,
  alertHeader,
}: DeleteAssignedActionButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onDeleteSuccess = useCallback(() => {
    onSuccess?.();
    onClose();
  }, [onSuccess, onClose]);

  const { onSubmit: onDelete } = useDeleteAssignedAction({ assignedActionId, onSuccess: onDeleteSuccess });
  const cancelRef = React.useRef();

  return (
    <>
      <IconButton
        icon={<FiTrash2 />}
        variant="tertiary"
        aria-label="Delete Action"
        onClick={onOpen}
        {...(iconSize ? { size: iconSize } : {})}
      />

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {alertHeader || "Delete Assigned Action"}
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? If you delete it, you cannot undo it afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => onDelete()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

DeleteAssignedActionButton.defaultProps = {
  onSuccess: () => {},
  iconSize: null,
  alertHeader: null,
};

import {
  Box, Center, Flex, Icon, Spinner, Stack, Text, useColorModeValue,
} from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import { useState } from "react";
import { CheckboxCard, CheckboxCardGroup } from "components/FormElements/CheckboxCardGroup";
import { NewHireJourney } from "models/onboarding";
import { ExtendedNewHireJourney } from "models/joinedModels";
import TasksTable from "components/Table/TasksTable";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { MdOutlineRedo } from "react-icons/md";
import SelectableSupportTeam from "../../SelectableSupportTeam";

interface TasksTabProps {
  newHireJourney: ExtendedNewHireJourney;
}

export default function TasksTab({ newHireJourney }: TasksTabProps) {
  const [searchText, setSearchText] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [filterByUserId, setFilterByUserId] = useState<string>();
  const searchBorder = useColorModeValue("border.dark", "border.light");

  return (
    <Flex direction="column" alignItems="flex-start">
      <Box mt="4px">
        <Flex align="self-start">
          <Flex direction="column">
            <Stack direction="column" w="100%" alignItems="flex-start">
              <SearchBar
                border={`1px solid var(--stroke-vibes, ${searchBorder})`}
                minWidth="500"
                name="tasksSearch"
                onInput={setSearchText}
                my="4"
              />
              <Box m="4" ml="0">
                <CheckboxCardGroup
                  direction="row"
                  defaultValue={[]}
                  onChange={(value: any) => setSelectedValues(value)}
                >
                  <CheckboxCard value="showCompleted">
                    <Flex align="center" gap="2">
                      <Icon as={AiOutlineCheckSquare} />
                      <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                        Show Completed
                      </Text>
                    </Flex>
                  </CheckboxCard>
                  <CheckboxCard value="showHidden">
                    <Flex align="center" gap="2">
                      <Icon as={BiTimeFive} />
                      <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                        Show Upcoming
                      </Text>
                    </Flex>
                  </CheckboxCard>
                  <CheckboxCard value="showSkipped">
                    <Flex align="center" gap="2">
                      <Icon as={MdOutlineRedo} />
                      <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                        Show Skipped
                      </Text>
                    </Flex>
                  </CheckboxCard>
                </CheckboxCardGroup>
              </Box>
            </Stack>
            <SelectableSupportTeam newHireJourney={newHireJourney} setFilterByUserId={setFilterByUserId} />
          </Flex>
        </Flex>
        {!newHireJourney ? (
          <Center>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
          </Center>
        ) : (
          <TasksTable
            showFullStatus
            includeCommunications
            newHireJourney={newHireJourney as NewHireJourney}
            filterByUserId={filterByUserId}
            searchText={searchText}
            showHidden={selectedValues.includes("showHidden")}
            showCompleted={selectedValues.includes("showCompleted")}
            showSkipped={selectedValues.includes("showSkipped")}
          />
        )}
      </Box>
    </Flex>
  );
}

import { get } from "lodash";
import { Box, Container } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Step from "components/StepContainer/Step";
import StepperHeader from "components/StepContainer/StepProgressBar";
import useStep from "hooks/useStep";
import { OnboardingPlan } from "models/onboarding";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import Step1AddUser from "./Step1AddUser";
import Step2MakeConnections from "./Step2MakeConnections";
import Step3AddResources from "./Step3AddResources";
// import Step4AssignWorkflow from "./Step4AssignWorkflow";

// function PLACEHOLDER({ index }: { index: number }) {
//   return <Text as="h1" color="#444d5d !important">{`Step ${index + 1}`}</Text>;
// }

export const steps: Step[] = [
  {
    title: "Step 1",
    description: "Add New Hire Buddy",
    component: Step1AddUser,
  },
  {
    title: "Step 2",
    description: "Make Important Connections",
    component: Step2MakeConnections,
  },
  {
    title: "Step 3",
    description: "Add Important Resources",
    component: Step3AddResources,
  },
  // {
  //   title: "Step 4",
  //   description: "Assign Workflow",
  //   component: Step4AssignWorkflow,
  // },
  // {
  //   title: "Step 5",
  //   description: "Description",
  //   component: PLACEHOLDER,
  // },
];

// string;
// Employee;
// Employee;
// Employee;
// Connection[];
// Employee[];
// Cohort;
// Resource[];

const onboardingPlanFieldsMap = {
  // "id",
  userId: "newHire.id",
  managerId: "manager.id",
  buddyId: "buddy.id",
  // "connections",
  // "editors",
  // "cohort",
  // "resources",
  // "createdAt",
  // "updatedAt",
};

interface OnboardingPlanWizardProps {
  onboardingPlan: OnboardingPlan;
}

export default function OnboardingPlanWizard({ onboardingPlan }: OnboardingPlanWizardProps) {
  // eslint-disable-next-line object-curly-newline
  const [currentStep, { setStep, goToNextStep, goToPrevStep, canGoToNextStep, canGoToPrevStep }] = useStep({
    maxStep: steps.length - 1,
  });
  const CurrentComponent = steps[currentStep].component;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const { mutate } = useMutation(
    (data: OnboardingPlan) => apiClient.put(generatePath(API_ROUTES.onboardingPlans.show, { id: onboardingPlan.id }), { onboardingPlan: data }),
    {
      onSuccess: async (response: OnboardingPlan) => {
        // update the onboarding plan cache directly
        queryClient.setQueryData(["onboardingPlans", response.id], response);
      },
      onError: (mutateError) => {
        console.log("mutateError");
        console.log(mutateError);
      },
    },
  );

  const onSubmit = useCallback(
    (formData: any) => {
      mutate(formData);
    },
    [mutate],
  );

  useEffect(() => {
    if (onboardingPlan) {
      // set the React Hook Form Fields In the Beginning
      Object.keys(onboardingPlanFieldsMap).forEach((field) => setValue(field, get(onboardingPlan, get(onboardingPlanFieldsMap, field))));
    }
  }, [setValue, onboardingPlan]);

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      // pull out only the field that has been changed to submit in the form => There is another field, type ( {name, type} )
      // and type has been 'change' as the field changes. We may need to ensure no errors are brought out
      // by deletion or anything, but unsure what other types there are and the API docs are not comprehensive
      const updatedData = { [name as string]: data[name as string] };
      handleSubmit(() => onSubmit(updatedData))();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch]);

  return (
    <Box bg="bg.surface" pt="2">
      <StepperHeader
        steps={steps}
        currentStepIndex={currentStep}
        setStep={setStep}
        goToNextStep={goToNextStep}
        goToPrevStep={goToPrevStep}
        canGoToNextStep={canGoToNextStep}
        canGoToPrevStep={canGoToPrevStep}
        onFinish={() => navigate(generatePath("/"))}
      />
      <Container>
        {/* eslint-disable-next-line object-curly-newline */}
        <CurrentComponent
          index={currentStep}
          onboardingPlan={onboardingPlan}
          control={control}
          register={register}
          errors={errors}
          // goToNextStep={goToNextStep}
          // goToPrevStep={goToPrevStep}
          // canGoToNextStep={canGoToNextStep}
          // canGoToPrevStep={canGoToPrevStep}
        />
      </Container>
    </Box>
  );
}

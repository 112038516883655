import { GroupBase, OptionsOrGroups, Select } from "chakra-react-select";
import { useController } from "react-hook-form";
import apiClient from "services/ApiClient";
import { DebouncedFunc, debounce } from "lodash";
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from "react";
import { DEFAULT_INPUT_VARIANT } from "definitions/constants/styling";
import clsx from "clsx";
import { ControlledSelectProps, OptionType } from ".";

export interface GenericAsyncSelectProps<T> extends ControlledSelectProps {
  embedOption: (arg0: T) => Option<T>;
  extractValue: (arg0: T) => string;
  fetchPath: string;
  excludedIds?: string[];
  onChangeCallback?: (arg0: T | undefined) => void;
  cacheKey?: string;
  readOnly?: boolean;
  perPagePagination?: number;
  containerStyles?: object;
}

interface Option<T> {
  value: string;
  label: string;
  rawValue: T;
}

async function fetchOptions<T>(
  path: string,
  extractValue: (arg0: T) => string,
  embedOption: (arg0: T) => Option<T>,
  inputValue: string,
  excludedIds: string[],
  perPagePagination?: number,
): Promise<OptionsOrGroups<Option<T>, GroupBase<Option<T>>>> {
  const pagination = perPagePagination ? `&pagination[per_page]=${perPagePagination}` : "";
  return apiClient
    .get<T[]>(`${path}?q=${encodeURIComponent(inputValue)}${pagination}`)
    .then((value) => value.filter((v) => !(excludedIds || []).includes(extractValue(v))).map((v) => embedOption(v)))
    .catch(() => []);
}

export function GenericAsyncSelect<T>({
  control,
  name,
  id,
  placeholder,
  embedOption,
  extractValue,
  rules,
  fetchPath,
  excludedIds,
  perPagePagination,
  onChangeCallback,
  cacheKey,
  readOnly,
  variant,
  className,
  containerStyles = {},
  ...props
}: GenericAsyncSelectProps<T>) {
  const {
    field: { onChange, value: controlValue },
  } = useController({
    name,
    control,
    rules,
  });
  type OptionsGroups = OptionsOrGroups<Option<T>, GroupBase<Option<T>>>;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<OptionsGroups>([]);
  const [optionsCache, setOptionsCache] = useState<Record<string, OptionsGroups>>({});
  const deb = useRef<DebouncedFunc<() => Promise<void>> | undefined>();

  const loadOptions = useCallback(
    (inputValue: string, callback: (options: OptionsGroups) => void) => {
      deb.current?.cancel();
      deb.current = debounce(async () => {
        setIsLoading(true);
        callback(
          await fetchOptions(fetchPath, extractValue, embedOption, inputValue, excludedIds || [], perPagePagination),
        );
        setIsLoading(false);
      }, 300);
      deb.current();
    },
    [fetchPath, extractValue, embedOption, excludedIds, perPagePagination],
  );

  useEffect(() => {
    if (!optionsCache[""]) {
      loadOptions("", (opts) => {
        setOptions(opts);
        setOptionsCache(opts ? { ...optionsCache, "": opts } : optionsCache);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onInputChange = useCallback(
    (newValue: string) => {
      if (!newValue) {
        setOptions(optionsCache[""] || []);
        return;
      }
      if (optionsCache[newValue]) {
        setOptions(optionsCache[newValue]);
      } else {
        loadOptions(newValue, (opts) => {
          setOptions(opts || []);
          setOptionsCache(opts ? { ...optionsCache, [newValue]: opts } : optionsCache);
        });
      }
    },
    [loadOptions, optionsCache],
  );

  const handleOnChange = useCallback(
    (val: OptionType<T> | undefined) => {
      onChange(val?.value || "");
      if (onChangeCallback) onChangeCallback(val?.rawValue);
    },
    [onChange, onChangeCallback],
  );

  const value = useMemo(
    () => options.find((option) => (option as Option<T>).value === controlValue) as OptionType<T> | undefined,
    [options, controlValue],
  );

  return (
    <Select
      key={cacheKey}
      name={name}
      menuPortalTarget={document.body}
      isLoading={isLoading}
      useBasicStyles
      isClearable={!readOnly}
      placeholder={variant === "floating" ? " " : placeholder}
      onChange={handleOnChange}
      options={options}
      value={value}
      onInputChange={onInputChange}
      components={{ DropdownIndicator: null }}
      variant={variant || DEFAULT_INPUT_VARIANT}
      classNamePrefix="chakra-react-select"
      className={clsx("chakra-react-select-container-single-select", className)}
      colorScheme="blue"
      chakraStyles={{
        container: (provided) => ({
          ...provided,
          cursor: "pointer",
          ...containerStyles,
        }),
        option: (provided) => ({
          ...provided,
          color: "fg.muted",
        }),
        valueContainer: (provided) => ({
          ...provided,
          className: "single-select",
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          width: "fit-content",
          height: "fit-content",
          marginRight: "1rem",
          _hover: {
            color: "notBlack.700",
          },
        }),
        crossIcon: (provided) => ({
          ...provided,
          width: "12px",
          height: "12px",
        }),
      }}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 1400 }),
        valueContainer: (provided) => ({
          ...provided,
          className: "single-select",
        }),
      }}
      {...props}
    />
  );
}

GenericAsyncSelect.defaultProps = {
  excludedIds: [],
  onChangeCallback: undefined,
  cacheKey: undefined,
  readOnly: false,
  perPagePagination: null,
  containerStyles: {},
};

import { Box, Text } from "@chakra-ui/react";
import { Select, SingleValue } from "chakra-react-select";

function SelectOrText<T>({
  values,
  selected,
  setSelected,
  getLabel,
  getValue,
}: {
  values: T[];
  selected: T;
  setSelected: (value: SingleValue<T>) => void;
  getLabel: (value: T) => string;
  getValue: (value: T) => string;
}) {
  if (values.length <= 1) return <Text w="260px">{getLabel(selected)}</Text>;

  return (
    <Box width="220px">
      <Select
        useBasicStyles
        menuPortalTarget={document.body}
        options={values}
        onChange={(value) => setSelected(value)}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        colorScheme="blue"
        value={selected}
        classNamePrefix="chakra-react-select"
        className="chakra-react-select-container-single-select"
        chakraStyles={{
          valueContainer: (provided) => ({
            ...provided,
            className: "single-select",
          }),
          control: (provided) => ({
            ...provided,
            cursor: "pointer",
          }),
        }}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 1400 }),
        }}
      />
    </Box>
  );
}

export default SelectOrText;

/* eslint-disable max-len */
import { useQuery } from "@tanstack/react-query";
import AppPage from "layouts/AppPage";
import { Company } from "models/company";
import { generatePath } from "react-router-dom";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useNavbar } from "hooks/useNavbar";
import { useEffect } from "react";
import InnerSidebar from "layouts/InnerSidebar";
import CompanyInfoSection from "./CompanyInfoSection";
import CompanyResourcesSection from "./CompanyResourcesSection";

export default function PeopleTeamCompanyPage() {
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const { data: company } = useQuery<Company>({
    queryKey: [QUERY_KEYS.companies, "current"],
    queryFn: async () => apiClient.get<Company>(generatePath(API_ROUTES.companies.show, { id: "current" })),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Company Info",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Company Info");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <InnerSidebar>
        <CompanyInfoSection company={company} />
        <CompanyResourcesSection company={company} />
      </InnerSidebar>
    </AppPage>
  );
}

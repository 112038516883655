import {
  Flex, FlexProps, Tag, TagLabel, TagLeftIcon, TagProps,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Task } from "models/joinedModels";
import { actionableTypeToIcon, assignedActionStatusDisplay } from "helpers/workflowActionableConverters";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { assignedActionPath } from "helpers/url";
import { isImmediateAssignedAction } from "helpers/assignedAction";

interface TaskCommunicationProps {
  assignedAction: AssignedAction;
  tagProps?: TagProps;
  overrideImmediate?: boolean;
}

export function TaskCommunication({ assignedAction, tagProps, overrideImmediate }: TaskCommunicationProps) {
  if (!assignedAction) {
    return null;
  }
  let iconSize = "4";
  let padding = "2";
  if (tagProps?.size) {
    switch (tagProps.size) {
      case "sm":
        iconSize = "2";
        padding = "1";
        break;
      case "lg":
        iconSize = "6";
        break;
      default:
        break;
    }
  }
  const { plainActionType, backgroundColor, status, datetime } = assignedActionStatusDisplay(assignedAction);
  let tagDisplay = `${plainActionType} ${status} (${datetime})`;
  if (overrideImmediate && isImmediateAssignedAction(assignedAction) && assignedAction.actionType === "task_notifier") {
    tagDisplay = `Notification sent at pre-boarding kickoff`;
  }
  return (
    <RouterLink to={assignedActionPath(assignedAction)}>
      <Tag p={padding} variant="subtle" colorScheme={backgroundColor} {...tagProps}>
        <TagLeftIcon boxSize={iconSize} as={actionableTypeToIcon({ actionType: assignedAction.actionType })} />
        <TagLabel>{tagDisplay}</TagLabel>
      </Tag>
    </RouterLink>
  );
}

TaskCommunication.defaultProps = {
  tagProps: {},
  overrideImmediate: false,
};

interface TaskCommunicationsProps extends FlexProps {
  task: Task;
  tagSize?: TagProps["size"];
  overrideImmediate?: boolean;
}

export default function TaskCommunications({ task, tagSize, overrideImmediate, ...rest }: TaskCommunicationsProps) {
  return (
    <Flex direction="row" gap="2" align="center" {...rest}>
      {(task?.scheduledWorkflowActions || []).map((assignedAction) => (
        <TaskCommunication
          overrideImmediate={overrideImmediate}
          tagProps={{ size: tagSize }}
          key={assignedAction.id}
          assignedAction={assignedAction}
        />
      ))}
    </Flex>
  );
}

TaskCommunications.defaultProps = {
  tagSize: "md",
  overrideImmediate: false,
};

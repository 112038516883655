import { MenuButtonProps, MenuProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import IconMenu from "./IconMenu";

interface ThreeDotMenuProps extends MenuButtonProps, PropsWithChildren {
  menuProps?: Omit<MenuProps, "children">;
  ariaLabel?: string;
  inPortal?: boolean;
}

export function HThreeDotMenu({
  menuProps, inPortal, ariaLabel, children, ...rest
}: ThreeDotMenuProps) {
  return (
    <IconMenu inPortal={inPortal} menuProps={menuProps} ariaLabel={ariaLabel as string} icon={BsThreeDots} {...rest}>
      {children}
    </IconMenu>
  );
}

HThreeDotMenu.defaultProps = {
  menuProps: {},
  ariaLabel: "Options",
  inPortal: false,
};

export function VThreeDotMenu({
  menuProps, inPortal, ariaLabel, children, ...rest
}: ThreeDotMenuProps) {
  return (
    <IconMenu
      inPortal={inPortal}
      menuProps={menuProps}
      ariaLabel={ariaLabel as string}
      icon={BsThreeDotsVertical}
      {...rest}
    >
      {children}
    </IconMenu>
  );
}

VThreeDotMenu.defaultProps = {
  menuProps: {},
  ariaLabel: "Options",
  inPortal: false,
};

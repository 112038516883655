import { BaseAssignedPath, BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { NewHireJourney } from "models/onboarding";
import { ExtendedNewHireJourney, Task } from "models/joinedModels";

function scheduledActionHasStarted(action: AssignedAction | BaseAssignedAction) {
  return !!action.firstAttemptedAt;
}

export function pullScheduledActions(scheduledWorkflows: BaseAssignedPath[]) {
  return scheduledWorkflows.map((scheduledWorkflow) => scheduledWorkflow.actions).flat();
}

export default function haveAutomationsStarted(newHireJourney: ExtendedNewHireJourney) {
  const actions = pullScheduledActions(newHireJourney?.assignedPaths || []);
  return actions ? actions.some((action) => scheduledActionHasStarted(action)) : false;
}

export function pullUniqueNewHireJourneys(actions: AssignedAction[]) {
  const uniqueNewHireJourneys: { [key: string]: NewHireJourney } = {};
  actions.forEach((action) => {
    if (action?.onboardingJourney?.id) {
      uniqueNewHireJourneys[action.onboardingJourney.id] = action.onboardingJourney;
    }
  });
  return Object.values(uniqueNewHireJourneys);
}

export function pullUniqueNewHireJourneysFromTasks(tasks: Task[]) {
  const uniqueNewHireJourneys: { [key: string]: NewHireJourney } = {};
  tasks.forEach((task) => {
    if (task?.newHireJourneyId) {
      uniqueNewHireJourneys[task.newHireJourneyId] = task.newHireJourney;
    }
  });
  return Object.values(uniqueNewHireJourneys);
}

import { CreateToastFnReturn, HStack, Stack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { Action } from "models/automation";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import * as yup from "yup";

const NAME_FIELD = {
  type: "text",
  label: "Action Name",
  name: "name",
};

const schema = yup.object({
  name: yup.string().label(NAME_FIELD.label).required(),
});

type Schema = yup.InferType<typeof schema>;

type FormData = Schema;

function Form({
  performRequest,
  onSuccess,
  onClose,
  proceedText,
  defaultValues,
}: {
  performRequest: (data: FormData) => Promise<Action>;
  onSuccess: (res: Action, queryClient: QueryClient, toast: CreateToastFnReturn) => void;
  onClose: () => void;
  proceedText: string;
  defaultValues: Partial<Schema>;
}) {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues },
  });
  const toast = useToast({ duration: 9000, isClosable: true, position: "top" });
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: Schema) => {
    const formData: FormData = data;

    try {
      setIsLoading(true);
      const res = await performRequest(formData);
      setIsLoading(false);
      onSuccess(res, queryClient, toast);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({ title: "Error", description: "There was an error, please try again.", status: "error" });
    }
  };

  return (
    <Stack spacing="3">
      <HStack spacing="5">
        <TextInputField required {...NAME_FIELD} errors={errors} register={register} />
      </HStack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText={proceedText}
          isLoading={isLoading}
        />
      </HStack>
    </Stack>
  );
}

export default function DuplicatePathActionForm({ action, onClose }: { action: Action; onClose: () => void }) {
  const onSuccess = useCallback(
    (res: Action, queryClient: QueryClient, toast: CreateToastFnReturn) => {
      queryClient.invalidateQueries([QUERY_KEYS.paths, action.workflowId]);
      onClose();
      toast({
        title: "Action Duplicated",
        description: <Link to={generatePath(PEOPLE_TEAM_ROUTES.actions.show, { id: res.id })}>Click to view</Link>,
        status: "success",
      });
    },
    [onClose, action.workflowId],
  );

  return Form({
    performRequest: async (data) => {
      const res = await apiClient.post<Action>(generatePath(API_ROUTES.actions.duplicate, { id: action.id }), {
        ...data,
      });
      return res;
    },
    onSuccess,
    onClose,
    proceedText: "Duplicate",
    defaultValues: {
      name: `${action.actionable.name} (Copy)`,
    },
  });
}

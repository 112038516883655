// The goal of this component is to have an editable, sortable, filterable, and pagenatable table
// but one that has all the items from the start (does not make calls to the backend for more)

import { SystemStyleObject, TableProps } from "@chakra-ui/react";

import {
  ColumnDef,
  ColumnHelper,
  DeepKeys,
  SortingFn,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import React, { useMemo } from "react";
import { range } from "lodash";
import BaseRowTable from "./BaseRowTable";

export interface IColumnDefinition<T> {
  attribute: DeepKeys<T>;
  label: string;
  disableSorting?: true;
  size?: number;
  maxSize?: number;
  minSize?: number;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const simpleColumn = <T,>(columnDefinition: IColumnDefinition<T>, colHelper: ColumnHelper<T>) => colHelper.accessor(columnDefinition.attribute, {
  header: columnDefinition.label,
  enableSorting: !columnDefinition.disableSorting,
  ...(columnDefinition.size ? { size: columnDefinition.size } : {}),
  ...(columnDefinition.maxSize ? { maxSize: columnDefinition.maxSize } : {}),
  ...(columnDefinition.minSize ? { minSize: columnDefinition.minSize } : {}),
});

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const totalPages = <T,>(data: T[], pagesize: number) => Math.ceil(data.length / pagesize);

export interface ControlledDataTableProps<T> extends TableProps {
  isLoading?: boolean;
  data: T[];
  columns: ColumnDef<T, any>[];
  sx?: SystemStyleObject;
  initialPagination?: { pageIndex: number; pageSize: number };
  customSortingObject?: {
    [key: string]: SortingFn<unknown>;
  };
  defaultSort?: SortingState;
  includeNumberedRow?: boolean;
  textWrap?: boolean;
  emptyStateComponent?: React.ReactNode;
  tableOptions?: object;
  containerProps?: object;
}

const PAGE_SIZE_QUERY_PARAM = "ps";
const PAGE_INDEX_QUERY_PARAM = "pi";

export default function ControlledDataTable<T>({
  customSortingObject,
  data,
  columns,
  sx,
  isLoading,
  defaultSort,
  includeNumberedRow,
  initialPagination,
  textWrap,
  emptyStateComponent,
  tableOptions,
  ...props
}: ControlledDataTableProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultPagination = useMemo(() => {
    let pagination = { pageSize: 20, pageIndex: 0 };
    if (initialPagination) pagination = initialPagination;
    if (searchParams.get(PAGE_SIZE_QUERY_PARAM)) pagination.pageSize = Number(searchParams.get(PAGE_SIZE_QUERY_PARAM));
    if (searchParams.get(PAGE_INDEX_QUERY_PARAM)) pagination.pageIndex = Number(searchParams.get(PAGE_INDEX_QUERY_PARAM));
    return pagination;
  }, [initialPagination, searchParams]);

  const [pagination, setPagination] = React.useState(defaultPagination);
  const [sorting, setSorting] = React.useState<SortingState>(defaultSort ?? []);

  React.useEffect(() => {
    setSearchParams(
      (params) => {
        params.set(PAGE_SIZE_QUERY_PARAM, String(pagination.pageSize));
        params.set(PAGE_INDEX_QUERY_PARAM, String(pagination.pageIndex));
        return params;
      },
      { replace: true },
    );
  }, [pagination, setSearchParams]);

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    sortingFns: { ...customSortingObject },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    enableSortingRemoval: true,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    ...tableOptions,
  });

  return (
    <BaseRowTable
      table={table}
      sx={sx}
      textWrap={textWrap}
      isLoading={isLoading}
      includeNumberedRow={includeNumberedRow}
      pageSizeOptions={range(20, 100, 20)}
      emptyStateComponent={emptyStateComponent}
      {...props}
    />
  );
}

ControlledDataTable.defaultProps = {
  defaultSort: undefined,
  sx: undefined,
  isLoading: false,
  customSortingObject: {},
  initialPagination: undefined,
  includeNumberedRow: false,
  textWrap: false,
  emptyStateComponent: null,
  tableOptions: {},
  containerProps: {},
};

/* eslint-disable no-nested-ternary */
import {
  TableProps, SystemStyleObject, Tr, Tbody, Td, Box, Table as ChakraTable, Flex,
} from "@chakra-ui/react";
import { range } from "lodash";
import { Table } from "@tanstack/react-table";
import SmallArrowPagination from "../SmallArrowPagination";
import SkeletonCards from "./SkeletonCards";

interface BaseCardTableProps<T> extends TableProps {
  table: Table<T>;
  sx?: SystemStyleObject;
  isLoading?: boolean;
  cardComponent: React.ComponentType<any>;
  emptyStateComponent?: React.ReactNode;
  pageSizeOptions?: number[];
  getCardProps?: () => object;
  getTrProps?: () => object;
  getTdProps?: () => object;
  getContainerProps?: () => object;
  includePagination?: boolean;
  skeletonHeight?: string;
  skeletonWidth?: string;
  skeletonRowCount?: number;
}

export default function BaseCardTable({
  cardComponent: CardComponent,
  table,
  sx,
  isLoading,
  emptyStateComponent,
  pageSizeOptions,
  includePagination,
  skeletonHeight,
  skeletonWidth,
  skeletonRowCount,
  getCardProps = () => ({}),
  getTrProps = () => ({}),
  getTdProps = () => ({}),
  getContainerProps = () => ({}),
  ...props
}: BaseCardTableProps<any>) {
  // eslint-disable-next-line no-underscore-dangle
  const columnNumber = table._getColumnDefs().length;

  return (
    <>
      {includePagination && <SmallArrowPagination pageSizeOptions={pageSizeOptions} table={table} />}
      <Box sx={sx}>
        <Flex overflow="hidden" width="100%" borderRadius="sm" {...getContainerProps()}>
          <ChakraTable overflow="scroll" bg="none" borderRadius="5px" variant="simple" {...props}>
            <Tbody>
              {isLoading ? (
                <SkeletonCards
                  columnNumber={columnNumber}
                  isLoading={isLoading}
                  getCardProps={getCardProps}
                  getTrProps={getTrProps}
                  getTdProps={getTdProps}
                  skeletonHeight={skeletonHeight}
                  skeletonWidth={skeletonWidth}
                  numberOfRows={skeletonRowCount}
                />
              ) : !table.getRowModel().rows?.length && emptyStateComponent ? (
                <Tr>
                  <Td px="0" colSpan={columnNumber} textAlign="center">
                    {emptyStateComponent}
                  </Td>
                </Tr>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <Tr key={row.id} {...getTrProps()}>
                    <Td px="0" colSpan={columnNumber} textAlign="center" borderBottom="none" {...getTdProps()}>
                      <CardComponent key={row.id} rowData={row.original} {...getCardProps()} />
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </ChakraTable>
        </Flex>
      </Box>
    </>
  );
}

BaseCardTable.defaultProps = {
  sx: {},
  isLoading: false,
  emptyStateComponent: null,
  pageSizeOptions: range(20, 100, 20),
  getCardProps: () => ({}),
  getTrProps: () => ({}),
  getTdProps: () => ({}),
  getContainerProps: () => ({}),
  includePagination: true,
  skeletonHeight: "100px",
  skeletonWidth: undefined,
  skeletonRowCount: undefined,
};

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { Step } from "components/Steps";
import { useEffect } from "react";
import { dateStrToLocalDate } from "utils/dateFormatter";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import SingleFormColumn from "components/FormElements/SingleFormColumn";
import { PERSONAL_EMAIL_FIELD, START_DATE_FIELD, WORK_EMAIL_FIELD, newHireSchema } from "./definition";
import useSubmitNewHire from "./useSubmitNewHireUpdate";
import { StepContent } from "./Components";

interface AddStartDateAndEmailStepProps {
  newHireJourney: ExtendedNewHireJourney;
}

function AddStartDateAndEmailStep(props: AddStartDateAndEmailStepProps) {
  const { newHireJourney } = props;
  const { handleOnSubmit } = useSubmitNewHire({ newHireJourneyId: newHireJourney?.id || "" });
  const resolver = yupResolver(
    newHireSchema.pick([
      START_DATE_FIELD.name as "startDate",
      PERSONAL_EMAIL_FIELD.name as "personalEmail",
      WORK_EMAIL_FIELD.name as "workEmail",
    ]),
  );
  const form = useForm<any>({
    mode: "onBlur",
    resolver,
    defaultValues: {
      startDate: dateStrToLocalDate(newHireJourney?.startDate),
      personalEmail: newHireJourney?.user?.personalEmail || "",
      workEmail: newHireJourney?.user?.workEmail || "",
    },
  });
  const {
    register,
    control,
    formState: { errors },
    reset,
  } = form;

  useEffect(() => {
    if (newHireJourney) {
      // Reset the form values when newHireJourney is updated
      reset({
        startDate: dateStrToLocalDate(newHireJourney?.startDate),
        personalEmail: newHireJourney?.user?.personalEmail || "",
        workEmail: newHireJourney?.user?.workEmail || "",
      });
    }
  }, [newHireJourney, reset]);

  return (
    <Step
      id="add-start-date-and-email"
      onClickNext={(callback) => {
        handleOnSubmit({
          callback,
          form,
        });
      }}
    >
      <StepContent title="Add start date and email info">
        <SingleFormColumn>
          <DatePickerField required isClearable control={control} {...START_DATE_FIELD} errors={errors} />
          <TextInputField required {...PERSONAL_EMAIL_FIELD} errors={errors} register={register} />
          <TextInputField {...WORK_EMAIL_FIELD} errors={errors} register={register} />
        </SingleFormColumn>
      </StepContent>
    </Step>
  );
}

export default AddStartDateAndEmailStep;

import { ControlInputFieldProps } from "components/Form/typings";
import "./styles.css";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import Trix from "trix";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import { useController } from "react-hook-form";
import { MutableRefObject, forwardRef, useCallback, useState } from "react";
import {
  Flex, Modal, ModalContent, useDisclosure, useTheme,
} from "@chakra-ui/react";
import EmojiPicker, { SuggestionMode, Theme } from "emoji-picker-react";
import { TrixEditor } from "react-trix";
import FormField from "../FormField";
import { RichText } from "./richtext";

type Props = ControlInputFieldProps & {
  onInitialize?: (event: Event, richText: RichText) => void;
};

const field = forwardRef<Trix, Props>((props, ref) => {
  const emojiPicker = useDisclosure();
  const [idSuffix] = useState(crypto.randomUUID());
  const theme = useTheme();
  const [hasValue, setHasValue] = useState(false);
  const editorId = `trix-editor-${idSuffix}`;
  const toolbarId = `trix-toolbar-${idSuffix}`;

  const {
    name, label, control, helperText, errors, required, onInitialize, variant,
  } = props;
  const formVariant = variant || theme.components.Form.defaultProps.variant;

  const {
    field: { onChange, value },
  } = useController({ name, control, defaultValue: "" });

  const handleEditorChange = useCallback(
    (event: Event) => {
      const target = event.target as TrixEditor;
      setHasValue(!!target.value?.trim());
      onChange(event);
    },
    [onChange],
  );

  return (
    <>
      <FormField
        name={name}
        label={label}
        errors={errors}
        helperText={helperText}
        required={required}
        variant={formVariant}
        labelProps={{ ...(hasValue && { className: "has-value" }) }}
      >
        <Flex className="trix-container" direction="column-reverse" gap={2}>
          <ReactTrixRTEToolbar toolbarId={toolbarId} />
          <ReactTrixRTEInput
            className="trix-editor"
            id={editorId}
            toolbarId={toolbarId}
            key={name}
            name={name}
            defaultValue={value}
            onChange={(event: Event) => handleEditorChange(event)}
            onInitialize={(event: Event) => {
              const richText = new RichText(event.target);
              richText.setup();
              richText.emojiButton.addEventListener("click", emojiPicker.onOpen);

              if (onInitialize) {
                onInitialize(event, richText);
              }
            }}
            isRailsDirectUpload
            trixInputRef={ref}
          />
        </Flex>
      </FormField>
      <Modal closeOnEsc isCentered isOpen={emojiPicker.isOpen} onClose={emojiPicker.onClose}>
        <ModalContent bgColor="transparent" width="fit-content">
          <EmojiPicker
            theme={Theme.AUTO}
            lazyLoadEmojis
            suggestedEmojisMode={SuggestionMode.RECENT}
            previewConfig={{ showPreview: false }}
            onEmojiClick={(emojiObject) => {
              const trix = (ref as MutableRefObject<any>).current.editor;
              trix.insertString(emojiObject.emoji);
              emojiPicker.onClose();
            }}
          />
        </ModalContent>
      </Modal>
    </>
  );
});

field.defaultProps = {
  onInitialize: undefined,
};

export default field;

import { PropsWithChildren } from "react";
import { Box } from "@chakra-ui/react";

function StepContent({ children }: PropsWithChildren) {
  return (
    <Box minHeight="calc(100vh - 180px)" maxHeight="calc(100vh - 180px)" overflow="scroll" px={{ base: "4", md: "6" }} py="5">
      {children}
    </Box>
  );
}

export default StepContent;

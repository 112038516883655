/* eslint-disable react/jsx-no-useless-fragment */
import { useToast } from "@chakra-ui/react";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import apiClient from "services/ApiClient";
import { set } from "lodash";
import DatePickerField from "components/FormElements/Fields/DatePickerField";

// DatePickerField
// {
//   name,
//   label,
//   helperText,
//   control,
//   errors,
//   defaultValue,
//   disabled,
//   required,
//   isClearable,
//   minDate,
// }

interface DateSelectFormProps extends PropsWithChildren {
  onClose?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
  submitUrl: string;
  name: string;
  label: string;
  helperText?: string;
  minDate?: Date;
  initialValue?: Date;
  defaultIsOpen?: boolean;
  submitType?: "POST" | "PUT";
  usePortal?: boolean;
  preserveTime?: boolean;
  isDisabled?: boolean;
}

export default function DateSelectForm({
  onClose,
  name,
  label,
  initialValue,
  minDate,
  helperText,
  submitUrl,
  submitType,
  onSuccess,
  onFailure,
  defaultIsOpen,
  usePortal,
  preserveTime,
  isDisabled,
  children,
  ...rest
}: DateSelectFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FieldValues>({
    mode: "onChange",
    defaultValues: initialValue ? set({}, name, initialValue) : {},
  });
  const toast = useToast({ duration: 3000, isClosable: true, position: "top" });

  /* HandleOnSubmit */
  // const onSubmit = (data: FieldValues) => console.log(data);
  const onSubmit = useCallback(
    async (data: FieldValues) => {
      try {
        let res;
        if (submitType === "POST") {
          res = await apiClient.post<{ id: string }>(submitUrl, data);
        } else {
          res = await apiClient.put<{ id: string }>(submitUrl, data);
        }
        console.log(res);
        if (onSuccess) {
          onSuccess();
        }
        if (onClose) {
          onClose();
        }
        toast({
          title: `${label}${label.length > 0 ? " u" : "U"}pdated successfully!`,
          status: "success",
        });
        // log the newly created item to the console
      } catch (error) {
        console.error(error);
        if (onFailure) {
          onFailure();
        }
        toast({
          title: `There was an error updating ${label}`,
          status: "error",
        });
      }
    },
    [label, onClose, onFailure, onSuccess, submitType, submitUrl, toast],
  );

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [onSubmit, handleSubmit, watch]);
  return (
    <DatePickerField
      control={control}
      name={name}
      label={label}
      helperText={helperText}
      errors={errors}
      defaultIsOpen={defaultIsOpen}
      usePortal={usePortal}
      preserveTime={preserveTime}
      minDate={minDate}
      disabled={isDisabled}
      display="flex"
      alignItems="center"
      {...rest}
    >
      {children}
    </DatePickerField>
  );
}

DateSelectForm.defaultProps = {
  helperText: null,
  initialValue: null,
  onSuccess: null,
  onFailure: null,
  onClose: null,
  defaultIsOpen: false,
  submitType: "POST",
  usePortal: true,
  preserveTime: false,
  isDisabled: false,
  minDate: null,
};

import { ExtendedNewHireJourney } from "models/joinedModels";
import { Step } from "components/Steps";
import { Flex, Text } from "@chakra-ui/react";
import { dateToLongString } from "helpers/time";
import newHireSetupImageUrl from "assets/undraw_happy_news.svg";
import { StepContent } from "./Components";

interface InitialStepProps {
  newHireJourney: ExtendedNewHireJourney;
}

function NewHireInfo({ newHireJourney }: InitialStepProps) {
  return (
    <Flex direction="column" align="center" gap="4" mb="4">
      <Text fontSize="xl" color="fg.muted">
        New Hire
        {" "}
        <Text as="i" fontWeight="bold" color="brand.500">
          {`${newHireJourney?.user?.firstName || "(First Name)"} ${newHireJourney?.user?.lastName || "(Last Name)"}`}
        </Text>
        {" "}
        will join as a
        {" "}
        <Text as="i" fontWeight="bold" color="brand.500">
          {newHireJourney?.user?.title || "(Title)"}
        </Text>
      </Text>
      <Text fontSize="xl" color="fg.muted">
        Starting on
        {" "}
        <Text as="i" fontWeight="bold" color="brand.500">
          {dateToLongString(newHireJourney?.startDate) || "(Start Date)"}
        </Text>
        ,
      </Text>
      <Text fontSize="xl" color="fg.muted">
        and reporting to
        {" "}
        <Text as="i" fontWeight="bold" color="brand.500">
          {newHireJourney?.manager?.fullName || "(Manager)"}
        </Text>
      </Text>
    </Flex>
  );
}

function AddPathsStep(props: InitialStepProps) {
  const { newHireJourney } = props;

  return (
    <Step id="initial">
      <StepContent title="New Hire setup" imageUrl={newHireSetupImageUrl} imageDescription="New Hire setup">
        <NewHireInfo newHireJourney={newHireJourney} />
      </StepContent>
    </Step>
  );
}

export default AddPathsStep;

import {
  Card,
  CardBody,
  CardFooter,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Skeleton,
  Stack,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { GoPerson } from "react-icons/go";
import { MdUpdate } from "react-icons/md";
import moment from "moment";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { useState } from "react";

interface TemplateWorkflowInfoCardProps {
  isLoading?: boolean;
  name: string;
  actionsCount: number;
  creatorName: string;
  updatedAt: Date;
  handleUseTemplate: (name: string, autoAdd: boolean) => void;
}

export default function TemplateWorkflowInfoCard({
  name,
  actionsCount,
  creatorName,
  updatedAt,
  handleUseTemplate,
  isLoading,
}: TemplateWorkflowInfoCardProps) {
  const [customName, setCustomName] = useState(name);
  const [autoAdd, setAutoAdd] = useState(false);

  const handleToggle = () => {
    setAutoAdd(a => !a);
  };

  return (
    <Card>
      <Skeleton isLoaded={!isLoading}>
        <CardBody>
          <Heading size="md">{name}</Heading>
          <Text mt="2" fontWeight="medium">{`${actionsCount} total actions`}</Text>
          <Stack spacing="1" mt="2">
            {creatorName && (
              <HStack fontSize="sm">
                <Icon as={GoPerson} color="gray.500" />
                <Tooltip label="Created By" aria-label="Created By">
                  <Text>{creatorName}</Text>
                </Tooltip>
              </HStack>
            )}
            <HStack fontSize="sm">
              <Icon as={MdUpdate} color="gray.500" />
              <Tooltip label="Last Updated" aria-label="Last Updated">
                <Text>{moment(updatedAt).format("MMM DD [at] hh:mm A")}</Text>
              </Tooltip>
            </HStack>
          </Stack>
        </CardBody>
        <CardFooter>
          <Flex direction="column" alignItems="flex-start" gap="3">
            <Heading size="xxs">Use template & customize:</Heading>
            <ButtonWithConfirm
              buttonText="Use Template"
              header="Use Template"
              confirmButtonText="Confirm"
              body={(
                <>
                  <FormLabel>Name</FormLabel>
                  <Input
                    placeholder="Enter a name"
                    value={customName}
                    onChange={(e) => setCustomName(e.target.value)}
                  />
                  <Flex alignItems="center" mt="2">
                    <FormLabel>Auto Assign to All New Hires?</FormLabel>
                    <Switch id="autoAdd" onChange={handleToggle} isChecked={autoAdd} />
                  </Flex>
                </>
              )}
              handleConfirm={() => handleUseTemplate(customName, autoAdd)}
              intent="confirmation"
            />
          </Flex>
        </CardFooter>
      </Skeleton>
    </Card>
  );
}

TemplateWorkflowInfoCard.defaultProps = {
  isLoading: false,
};

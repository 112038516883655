import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Box, Heading, Text, AccordionProps, AccordionItemProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

interface AccordionSectionProps extends AccordionItemProps {
  title: string;
  titleBackgroundColor?: string;
  rightTitle?: string;
}

export function AccordionSection({ children, title, titleBackgroundColor, rightTitle, ...rest }: AccordionSectionProps) {
  return (
    <AccordionItem {...rest}>
      <Heading>
        <AccordionButton {...(titleBackgroundColor ? { backgroundColor: titleBackgroundColor } : {})}>
          <AccordionIcon ml={4} mr={8} />
          <Box as="span" flex="1" textAlign="left">
            <Text fontSize="xl" fontWeight="600" color="fg.emphasized">
              {title}
            </Text>
          </Box>
          {!!rightTitle && (
            <Box as="span" flex="1" textAlign="right" mr={16}>
              <Text fontSize="md" color="fg.emphasized">
                {rightTitle}
              </Text>
            </Box>
          )}
        </AccordionButton>
      </Heading>
      <AccordionPanel pb={4}>
        {children as ReactNode}
      </AccordionPanel>
    </AccordionItem>
  );
}

AccordionSection.defaultProps = {
  titleBackgroundColor: null,
  rightTitle: null,
}

interface AccordionLayoutProps extends AccordionProps {
  children: ReactElement<typeof AccordionSection> | ReactElement<typeof AccordionSection>[];
}

export default function AccordionLayout({ children, ...rest }: AccordionLayoutProps) {
  // eslint-disable-next-line no-nested-ternary
  return (
    <Accordion {...rest}>
      {children}
    </Accordion>
  );
}

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import UpdateEmailForm from "components/Forms/AssignedActions/Email/UpdateEmailForm";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useSendNowAssignedAction from "features/AssignedAction/hooks/useSendNowAssignedAction";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { useRef, useState } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this email now!",
  body: "This cannot be undone! Be sure you want to send this email now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface EmailSideDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  assignedActionId: string;
}

export default function EmailSideDrawer({ isOpen, onClose, assignedActionId }: EmailSideDrawerProps) {
  const formRef = useRef<any>(null); // Create a ref to access form methods
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);

  const { data: assignedEmail, isLoading: emailIsLoading } = useQuery({
    queryKey: [QUERY_KEYS.assignedActions, assignedActionId],
    queryFn: async ({ signal }) => apiClient.get<AssignedAction>(
      generatePath(API_ROUTES.assignedActions.show, { id: assignedActionId || "" }),
      signal,
    ),
    enabled: !!assignedActionId && assignedActionId.length > 0,
  });

  const handleSubmit = (onSuccessCallback: () => void) => {
    formRef.current?.handleSubmit(onSuccessCallback); // Call form submit from the ref
  };

  const { sendNow, isReady } = useSendNowAssignedAction({ assignedAction: assignedEmail });

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="4" fontSize="xl" font-Weight="semibold" borderBottomWidth="2px">
          Email
        </DrawerHeader>

        <DrawerBody>
          {emailIsLoading && <Text>Loading...</Text>}
          {!emailIsLoading && assignedEmail && (
            <UpdateEmailForm
              ref={formRef}
              assignedAction={assignedEmail}
              onSuccess={onClose}
              setIsSubmitLoading={setIsSubmitLoading}
              setIsFormDirty={setIsFormDirty}
            />
          )}
        </DrawerBody>

        <DrawerFooter borderTopWidth="2px">
          <ButtonGroup display="flex" justifyContent="flex-end">
            <ButtonWithConfirm
              intent="warning"
              isDisabled={!isReady}
              {...CONFIRM_SEND_NOW}
              handleConfirm={() => handleSubmit(() => sendNow())}
            />
            <ProceedCancelButtons
              handleSubmit={() => handleSubmit(() => {})}
              onClose={onClose}
              proceedText="Update Email"
              proceedDisabled={!isFormDirty}
              isLoading={isSubmitLoading}
            />
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

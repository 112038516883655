/* eslint-disable import/prefer-default-export */
/* eslint-disable no-multiple-empty-lines */

import {
  API_ROUTES,
  PEOPLE_TEAM_ROUTES,
  SHARED_COMPANY_ROUTES,
  SHARED_ROUTES,
} from "definitions/constants/routeConstants";
import { Action } from "models/automation";
import { AssignedAction, AssignedPath } from "models/automation/scheduledWorkflow";
import { Task } from "models/joinedModels";
import { NewHireJourney } from "models/onboarding";
import { User } from "models/user";
import { generatePath } from "react-router-dom";

export function removeLastPath(pathname: string) {
  return pathname.split("/").slice(0, -1).join("/");
}

export function userPathById(userId: string | undefined) {
  return generatePath(SHARED_ROUTES.showUser, { id: userId || "" });
}

export function userPath(user: User) {
  return userPathById(user?.id);
}

export function newHireJourneyPathById(newHireJourneyId: string | undefined) {
  return generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourneyId || "" });
}

export function newHireJourneyPath(newHireJourney: NewHireJourney) {
  return newHireJourneyPathById(newHireJourney?.id);
}

export function pendingNewHireJourneyPath(newHireJourney: NewHireJourney) {
  return generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.pending.show, { id: newHireJourney?.id || "" });
}

export function pendingNewHireJourneysPath() {
  return generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.pending.index);
}

export function assignedPathPath(assignedPath: AssignedPath) {
  return generatePath(PEOPLE_TEAM_ROUTES.assignedPaths.show, { id: assignedPath?.id || "" });
}

export function assignedActionPathById(assignedActionId: string) {
  return assignedActionId ? generatePath(PEOPLE_TEAM_ROUTES.assignedActions.show, { id: assignedActionId }) : "";
}

export function assignedActionPath(assignedAction: AssignedAction) {
  return assignedActionPathById(assignedAction?.id);
}

export function actionPath(action: Action) {
  return generatePath(PEOPLE_TEAM_ROUTES.actions.show, { id: action?.id || "" });
}

export function taskPath(task: Task) {
  return generatePath(SHARED_ROUTES.tasks.show, { id: task?.id || "" });
}

export function issuesPath(newHireJourneyId: string) {
  return `${generatePath(PEOPLE_TEAM_ROUTES.issues.error)}${newHireJourneyId ? `?newHireJourneyId=${newHireJourneyId}` : ""}`;
}

export function apiNewHireJourneyPath(newHireJourney: NewHireJourney) {
  return generatePath(API_ROUTES.newHireJourneys.show, { id: newHireJourney?.id || "" });
}

export function apiAssignedActionPathById(assignedActionId: string) {
  return assignedActionId ? generatePath(API_ROUTES.assignedActions.show, { id: assignedActionId }) : "";
}

export function apiTaskPathById(taskId: string) {
  return generatePath(API_ROUTES.tasks.show, { id: taskId || "" });
}

import { Box, Heading } from "@chakra-ui/react";
import WelcomeImageCard from "components/Card/WelcomeImageCard";
import { User } from "models/user";
import { WelcomeMessageWithImage } from "models/welcomeFlow";

interface TeammatesWelcomeProps {
  user: User | null;
  messages: WelcomeMessageWithImage[];
}

export default function TeammatesWelcome({ user, messages }: TeammatesWelcomeProps) {
  return (
    <Box
      pt="12"
      px="32"
      pb="32"
    >
      <Heading as="h3" fontWeight="400" mb="32px">
        A few people wanted to say hi
      </Heading>
      <Box
        padding={4}
        w="100%"
        maxW="1200px"
        mx="auto"
        sx={{ columnCount: [1, 1, 1, 2, 3], columnGap: "8px" }}
        overflow="scroll"
      >
        {(messages || []).map((message) => (
          <WelcomeImageCard key={message.id} message={message} />
        ))}
      </Box>
    </Box>
  );
}

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  Link,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { Step } from "components/Steps";
import { pullScheduledActions } from "helpers/newHireJourney";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { toTriggerText } from "components/Trigger";
import { DependencyInfo } from "features/AssignedAction/AssignedActionCardTable/AssignedActionCard";
import { useCallback, useMemo, useState } from "react";
import ChatMessageSideDrawer from "components/AssignedActionSideDrawer/ChatMessageSideDrawer";
import {
  AssignedActionScheduledAt,
  AssignedActionTargetText,
  AssignedActionTriggerText,
} from "features/AssignedAction/DisplayInfo";
import { Link as RouterLink } from "react-router-dom";
import DeleteAssignedActionButton from "features/AssignedAction/DeleteAssignedActionButton";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import moment from "moment";
import { assignedActionPath } from "helpers/url";
import { sortAssignedActionsByTriggerDate } from "helpers/assignedAction";
import useSendNowAssignedAction from "features/AssignedAction/hooks/useSendNowAssignedAction";
import { RandomEmptyChatMessagesStepState } from "components/EmptyComponents/RandomEmptyState";
import { StepCardList, StepContent } from "./Components";

const RELATIONS = ["new_hire", "manager", "people_team_contact", "buddy"];

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this message now!",
  body: "This cannot be undone! Be sure you want to send this message now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface ChatMessageCardProps {
  actionChat: AssignedAction;
  onOpen: (actionId: string) => void;
}

function ChatMessageCard({ actionChat, onOpen }: ChatMessageCardProps) {
  const queryClient = useQueryClient();
  const bgColor = useColorModeValue("inherit", "gray.600");
  const triggerText = toTriggerText(actionChat.workflowAction);

  const { sendNow, isReady } = useSendNowAssignedAction({ assignedAction: actionChat });

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEYS.newHireJourney, actionChat?.scheduledWorkflow?.onboardingJourneyId]);
  }, [actionChat?.scheduledWorkflow?.onboardingJourneyId, queryClient]);

  return (
    <Card bg={bgColor}>
      <CardBody display="flex" gap="1" alignItems="flex-start" flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text fontSize="lg" fontWeight="medium" textAlign="left" color="chakra-body-text">
            {actionChat.name}
          </Text>
          {actionChat.status === "processed" ? null : (
            <DeleteAssignedActionButton
              assignedActionId={actionChat?.id}
              onSuccess={onSuccess}
              iconSize="xs"
              alertHeader="Remove Chat Message"
            />
          )}
        </Flex>
        <Flex gap="4" mt="1" ml="4">
          <AssignedActionTargetText assignedAction={actionChat} linkProps={{ target: "_blank" }} />
          {actionChat.status === "processed" ? null : <AssignedActionTriggerText assignedAction={actionChat} />}
        </Flex>
        {actionChat.status === "processed" ? null : (
          <Flex gap="1" mt="1" ml="3">
            {triggerText?.includes("Immediate") && !actionChat?.trigger?.data?.customized ? null : (
              <AssignedActionScheduledAt textProps={{ fontSize: "sm" }} assignedAction={actionChat} />
            )}
            {actionChat.trigger.type === "dependency" && <DependencyInfo assignedAction={actionChat} />}
          </Flex>
        )}
        {actionChat.status === "processed" ? (
          <Flex width="100%" ml="4" mt="2" gap="2">
            <Text fontSize="sm" fontWeight="medium" textAlign="center">
              {`✅ `}
              <Link as={RouterLink} to={assignedActionPath(actionChat)}>
                Chat
              </Link>
              {` Sent ${moment(actionChat.statusUpdatedAt).fromNow()}`}
            </Text>
          </Flex>
        ) : (
          <ButtonGroup display="flex" mt="2" gap="2">
            <Button onClick={() => onOpen(actionChat.id)} size="sm">
              Edit Chat
            </Button>
            <ButtonWithConfirm
              intent="warning"
              isDisabled={!isReady}
              {...CONFIRM_SEND_NOW}
              handleConfirm={() => sendNow()}
              buttonProps={{ size: "sm" }}
            />
          </ButtonGroup>
        )}
      </CardBody>
    </Card>
  );
}

interface ReviewChatMessagesProps {
  newHireJourney: ExtendedNewHireJourney;
}

function ReviewChatMessages(props: ReviewChatMessagesProps) {
  const { newHireJourney } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedChatMessageId, setSelectedChatMessageId] = useState<string>("");

  const handleOpenDrawer = (chatMessageId: string) => {
    setSelectedChatMessageId(chatMessageId);
    onOpen();
  };

  // Memoize the derived actions based on assignedPaths
  // eslint-disable-next-line arrow-body-style
  const allActions = useMemo(() => {
    return sortAssignedActionsByTriggerDate(
      pullScheduledActions(newHireJourney?.assignedPaths || []).filter(
        (assignedAction) => assignedAction.actionType === "chat",
      ) as AssignedAction[],
    );
  }, [newHireJourney?.assignedPaths]);

  const newHireChatMessages = allActions.filter(
    (action) => action?.workflowAction?.actionable?.relationTarget === "new_hire",
  );
  const managerChatMessages = allActions.filter(
    (action) => action?.workflowAction?.actionable?.relationTarget === "manager",
  );
  const peopleTeamChatMessages = allActions.filter(
    (action) => action?.workflowAction?.actionable?.relationTarget === "people_team_contact",
  );
  const buddyChatMessages = allActions.filter(
    (action) => action?.workflowAction?.actionable?.relationTarget === "buddy",
  );
  const nonRelationChatMessages = allActions.filter(
    (action) => !RELATIONS.includes(action?.workflowAction?.actionable?.relationTarget as string),
  );

  return (
    <Step id="review-chat-messages">
      <StepContent title="Review chat messages">
        {!allActions?.length ? (
          <RandomEmptyChatMessagesStepState />
        ) : (
          <Tabs size="lg" variant="underline" colorScheme="brand" orientation="vertical" width="100%" height="100%">
            <TabList width="fit-content" minWidth="150px" height="100%">
              <Tab textAlign="start">All</Tab>
              {newHireChatMessages.length ? <Tab textAlign="start">New Hires</Tab> : null}
              {managerChatMessages.length ? <Tab textAlign="start">Managers</Tab> : null}
              {peopleTeamChatMessages.length ? <Tab textAlign="start">People Team</Tab> : null}
              {buddyChatMessages.length ? <Tab textAlign="start">Buddy</Tab> : null}
              {nonRelationChatMessages.length ? <Tab textAlign="start">Other</Tab> : null}
            </TabList>
            <TabIndicator />
            <TabPanels>
              <TabPanel>
                <StepCardList>
                  {allActions.map((action) => (
                    <ChatMessageCard key={action.id} actionChat={action} onOpen={handleOpenDrawer} />
                  ))}
                </StepCardList>
              </TabPanel>
              {newHireChatMessages.length ? (
                <TabPanel>
                  <StepCardList>
                    {newHireChatMessages.map((action) => (
                      <ChatMessageCard key={action.id} actionChat={action} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
              {managerChatMessages.length ? (
                <TabPanel>
                  <StepCardList>
                    {managerChatMessages.map((action) => (
                      <ChatMessageCard key={action.id} actionChat={action} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
              {peopleTeamChatMessages.length ? (
                <TabPanel>
                  <StepCardList>
                    {peopleTeamChatMessages.map((action) => (
                      <ChatMessageCard key={action.id} actionChat={action} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
              {buddyChatMessages.length ? (
                <TabPanel>
                  <StepCardList>
                    {buddyChatMessages.map((action) => (
                      <ChatMessageCard key={action.id} actionChat={action} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
              {nonRelationChatMessages.length ? (
                <TabPanel>
                  <StepCardList>
                    {nonRelationChatMessages.map((action) => (
                      <ChatMessageCard key={action.id} actionChat={action} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
            </TabPanels>
          </Tabs>
        )}
      </StepContent>
      <ChatMessageSideDrawer isOpen={isOpen} onClose={onClose} assignedActionId={selectedChatMessageId} />
    </Step>
  );
}

export default ReviewChatMessages;

import {
  Box, BoxProps, Container, Flex, Spinner, useColorModeValue,
} from "@chakra-ui/react";

import Navbar from "layouts/Navbar";
import Sidebar from "layouts/Sidebar/Sidebar";
import React, { Suspense } from "react";
import FeedBackDrawer from "./FeedbackDrawer";

interface AppPageProps extends BoxProps {}

export default function AppPage({ children, ...rest }: AppPageProps) {
  const bgColor = useColorModeValue("gray.50", "gray.500");
  return (
    // FIXME: Add some kind of generic skeleton loader instead of Spinner
    <Suspense fallback={<Spinner />}>
      <Box minH="100vh" maxW="100vw" position="relative">
        <Flex h="full" id="app-container">
          <Box w={64} bg="gray.900" color="white" fontSize="sm">
            <Sidebar />
          </Box>
          <Container
            maxHeight="100vh"
            overflow="scroll"
            // max width is 100% minus the size of the sidebar
            maxWidth="calc(100% - var(--chakra-sizes-64))"
            bg={bgColor}
            flex="1"
            display="flex"
            flexDirection="column"
            px="0"
            pb="6"
          >
            <Navbar />
            <Box px="6" height="100%" color={useColorModeValue("gray.200", "gray.700")} maxWidth="full" {...rest}>
              {children}
              <FeedBackDrawer />
            </Box>
          </Container>
        </Flex>
      </Box>
    </Suspense>
  );
}

AppPage.defaultProps = {
  children: undefined,
};

/* eslint-disable import/prefer-default-export */

import { PathTimingTriggerData } from "models/automation";

export const DEFAULT_TIME_OF_DAY = "09:00:00";

export const TRIGGER_DEFAULT_VALUES: { trigger: { type: "timing"; data: Partial<PathTimingTriggerData> } } = {
  trigger: {
    type: "timing",
    data: {
      timeOfDay: DEFAULT_TIME_OF_DAY,
      timezoneType: "target_timezone",
    },
  },
};

import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useMemo } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { Filter } from "models/filter";
import { createQueryString } from "helpers/queryString";
import { ExtendedNewHireJourney, Task } from "models/joinedModels";
import { useQuery } from "@tanstack/react-query";

interface UseAllNewHireJourneyTasks {
  newHireJourney: ExtendedNewHireJourney;
}

export default function useAllNewHireJourneyTasks({ newHireJourney }: UseAllNewHireJourneyTasks) {
  const queries = useMemo(() => {
    const innerQueries: Record<string, string> = {};
    innerQueries.view = "extended";
    innerQueries.showCompleted = String(true);
    innerQueries.showHidden = String(true);
    innerQueries.showSkipped = String(true);
    return innerQueries;
  }, []);

  const queryString = useMemo(() => {
    const filters: Filter[] = [
      {
        fieldName: "id",
        value: (newHireJourney?.tasks || []).map((task) => task.id),
        operator: "in",
      },
    ];
    return createQueryString({
      pagination: { pageIndex: 0, pageSize: 200 },
      filters,
      additionalQueries: { ...queries },
    });
  }, [newHireJourney, queries]);

  const { data: tasks, isLoading } = useQuery<Task[]>({
    queryKey: [QUERY_KEYS.tasks, "newHireJourney", newHireJourney?.id],
    queryFn: () => apiClient.get<Task[]>(`${generatePath(API_ROUTES.tasks.base)}?${queryString}`),
    enabled: !!newHireJourney,
  });

  return { tasks, isLoading };
}

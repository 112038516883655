import {
  As, Badge, Button, ButtonProps, HStack, Icon, Link, Text, useColorModeValue,
} from "@chakra-ui/react";
import { isActiveSidebar } from "helpers/sidebar";
import { GrWorkshop } from "react-icons/gr";
import { useLocation, Link as RouterLink } from "react-router-dom";

interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
  route: string;
  subtle?: boolean;
  notifications?: number;
}

export default function SidebarNavButton({
  route,
  icon,
  label,
  subtle,
  notifications,
  ...buttonProps
}: NavButtonProps) {
  const { pathname } = useLocation();
  const isActive = isActiveSidebar(pathname, route);
  const activeBgColor = useColorModeValue("gray.100", "gray.700");
  return (
    <Link
      as={RouterLink}
      to={route}
      style={{ textDecoration: "none" }}
      _hover={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Button
        width="100%"
        variant="tertiary"
        justifyContent="start"
        bg={isActive ? activeBgColor : undefined}
        {...(notifications
          ? {
            rightIcon: (
              <Badge variant="solid" colorScheme={notifications < 5 ? "green" : "red"} borderRadius="8px" size="xs">
                {notifications}
              </Badge>
            ),
          }
          : {})}
        {...buttonProps}
      >
        <HStack spacing="3">
          {[GrWorkshop].includes(icon) ? (
            <Icon as={icon} boxSize="6" sx={{ path: { stroke: "fg.subtle" } }} />
          ) : (
            <Icon as={icon} boxSize="6" color="fg.subtle" />
          )}
          <Text color={subtle ? "gray.400" : undefined}>{label}</Text>
        </HStack>
      </Button>
    </Link>
  );
}

SidebarNavButton.defaultProps = {
  subtle: false,
  notifications: null,
};

import {
  Box, Button, ButtonGroup, Container, HStack, Text,
} from "@chakra-ui/react";
import { Helpers } from "hooks/useStep";
import { PropsWithChildren } from "react";

interface CardHeaderProps extends PropsWithChildren, Partial<Helpers> {
  headerText: string;
}

export function CardHeader({
  headerText,
  goToPrevStep,
  goToNextStep,
  canGoToNextStep,
  canGoToPrevStep,
}: CardHeaderProps) {
  return (
    <Box px={{ base: "4", md: "6" }} py="5">
      <HStack spacing="4" justify="space-between">
        <Text color="fg.emphasized" textStyle="lg" fontWeight="medium">
          {headerText}
        </Text>
        <ButtonGroup>
          {canGoToPrevStep && <Button onClick={goToPrevStep}>Previous Step</Button>}
          {canGoToNextStep && <Button onClick={goToNextStep}>Next Step</Button>}
        </ButtonGroup>
      </HStack>
    </Box>
  );
}

export function CardContent({ children }: PropsWithChildren) {
  return (
    <Box px={{ base: "4", md: "6" }} py="5">
      {children}
    </Box>
  );
}

export default function WizardCard({ children }: PropsWithChildren) {
  return (
    <Box as="section" pb={{ base: "12", md: "24" }}>
      <Container>
        <Box bg="bg.surface" boxShadow="sm" borderRadius="lg">
          {children}
        </Box>
      </Container>
    </Box>
  );
}

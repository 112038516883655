import {
  Card, CardBody, CardHeader, Heading, Link,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import GenericTable, { GenericTableProps, simpleColumn } from "components/Table/GenericTable";
import AppPage from "layouts/AppPage";
import { BaseCohort } from "models/cohort";
import { ResponseModel } from "services/ApiClient/responseModel";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { QUERY_KEYS } from "definitions/constants";
import apiClient from "services/ApiClient";
import { Link as RouterLink } from "react-router-dom";

const columnHelper = createColumnHelper<BaseCohort>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: "name",
    header: "Name",
    cell: (row) => (
      <Link
        as={RouterLink}
        to={{
          pathname: PEOPLE_TEAM_ROUTES.cohorts.show.replace(":id", row.getValue().id),
        }}
      >
        {row.getValue().name}
      </Link>
    ),
  }),
  columnHelper.accessor("contact", {
    cell: (info) => (info.getValue() ? info.getValue().workEmail : ""),
    header: "Contact",
  }),
  simpleColumn({ attribute: "startDate", label: "Start Date" }, columnHelper),
  simpleColumn({ attribute: "finalStartDate", label: "Final Start Date" }, columnHelper),
];

export default function CohortsPage() {
  const props: GenericTableProps<BaseCohort> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<BaseCohort[]>>(`${API_ROUTES.cohorts}${query}`, signal)
      .then((response) => response.payload),
    queryKey: [QUERY_KEYS.cohorts],
    columns,
    meta: { pagination: { perPage: 20 } },
  };

  return (
    <AppPage>
      <Card mt="8" p="4">
        <CardHeader>
          <Heading size="xs" textTransform="capitalize">
            Cohorts
          </Heading>
        </CardHeader>
        <CardBody>
          <GenericTable {...props} />
        </CardBody>
      </Card>
    </AppPage>
  );
}

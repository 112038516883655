/* eslint-disable max-len */
import { HStack } from "@chakra-ui/react";
import SupportProfileCard from "components/Card/SupportProfileCard";
import { User } from "models/user";
import { NewHireJourney } from "models/onboarding";

interface SupportTeamProps {
  newHireJourney: NewHireJourney;
  buddy: User;
  hrbp: User;
  manager: User;
  editable?: boolean;
}

export default function SupportTeam({
  buddy, manager, hrbp, newHireJourney, editable,
}: SupportTeamProps) {
  return (
    <HStack>
      {hrbp && (
        <SupportProfileCard
          editable={editable}
          useFullname
          newHireJourney={newHireJourney}
          attributeName="peopleTeamContactId"
          employee={hrbp}
          messageProvider="Slack"
          connectionType="People Team Contact"
        />
      )}
      {manager && (
        <SupportProfileCard
          editable={editable}
          useFullname
          newHireJourney={newHireJourney}
          attributeName="managerId"
          employee={manager}
          messageProvider="Slack"
          connectionType="Manager"
        />
      )}
      {buddy && (
        <SupportProfileCard
          editable={editable}
          useFullname
          newHireJourney={newHireJourney}
          attributeName="buddyId"
          employee={buddy}
          messageProvider="Slack"
          connectionType="Buddy"
        />
      )}
    </HStack>
  );
}

SupportTeam.defaultProps = {
  editable: false,
};

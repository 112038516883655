import { Box, Flex } from "@chakra-ui/react";
import React from "react";

export default function NoSidebarPage({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <Box height="100vh" overflow="hidden" position="relative">
      <Flex
        h="full"
        id="logged-out-container"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Flex>
    </Box>
  );
}

NoSidebarPage.defaultProps = {
  children: undefined,
};

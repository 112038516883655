import { useTheme } from "@chakra-ui/react";
import SingleSelect from "components/Form/Select/SingleSelect";
import { SelectFieldProps } from "components/Form/typings";
import FormField from "../FormField";

export default function SingleSelectField({
  name,
  label,
  placeholder,
  isClearable,
  control,
  options,
  optionsAsText,
  optionsMapper,
  errors,
  required,
  disabled,
  menuPlacement,
  variant,
  formFieldProps = {},
  ...rest
}: SelectFieldProps) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      required={required}
      disabled={disabled}
      variant={formVariant}
      {...formFieldProps}
    >
      <SingleSelect
        isClearable={isClearable ?? true}
        useBasicStyles
        name={name}
        control={control}
        placeholder={formVariant === "floating" ? " " : placeholder}
        options={options}
        optionsAsText={optionsAsText}
        optionsMapper={optionsMapper}
        menuPlacement={menuPlacement}
        {...rest}
      />
    </FormField>
  );
}

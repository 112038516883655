/* eslint-disable react/jsx-no-useless-fragment */
import { Badge, BadgeProps } from "@chakra-ui/react";
import { Task } from "models/joinedModels";
import { findTaskNotifier } from "helpers/task";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { statusDateTime } from "helpers/status";

interface TaskStatusProps extends BadgeProps {
  task: Task;
}

export default function TaskStatus({ task, ...rest }: TaskStatusProps) {
  let statusText;
  if (task?.status === "completed") {
    statusText = "Completed";
  } else if (task?.status === "assigned") {
    statusText = "Not Notified";
  } else if (task?.status === "notified") {
    statusText = "Notified";
  } else if (task?.status === "skipped") {
    statusText = "Skipped";
  } else {
    statusText = "";
  }
  return (
    <Badge
      variant={task?.status === "completed" ? "solid" : "subtle"}
      colorScheme={["assigned", "skipped"].includes(task?.status) ? "gray" : "green"}
      size="lg"
      mt="2"
      borderRadius="5px"
      {...rest}
    >
      {statusText}
    </Badge>
  );
}

function taskStatusTextAndColorScheme(task: Task) {
  if (task?.status === "completed") {
    return {
      statusText: `Completed${task.completedAt ? ` ${statusDateTime(task.completedAt)}` : ""}`,
      colorScheme: "green",
      type: "completed",
    };
  } else if (task?.status === "skipped") {
    return {
      statusText: `Skipped${task.skippedAt ? ` ${statusDateTime(task.skippedAt)}` : ""}`,
      colorScheme: "gray",
      type: "skipped",
    };
  } else if (task?.status === "assigned") {
    const notifier = findTaskNotifier(task);
    if (notifier?.status === "error") {
      return {
        statusText: `Notification Errored${notifier.attemptedAt ? ` ${statusDateTime(notifier.attemptedAt)}` : ""}`,
        colorScheme: "red",
        type: "errored",
      };
      // eslint-disable-next-line no-else-return
    } else if (notifier) {
      return {
        statusText: `Notification${
          notifier?.trigger?.data?.datetime
            ? ` Scheduled ${statusDateTime(notifier?.trigger?.data?.datetime)}`
            : " Will Schedule After Task Completion"
        }`,
        colorScheme: "orange",
        type: "will-notify",
      };
    }
    return {
      statusText: null,
      colorScheme: null,
      type: null,
    };
  } else if (task?.status === "notified") {
    return {
      statusText: `Notified${task.notifiedAt ? ` ${statusDateTime(task.notifiedAt)}` : ""}`,
      colorScheme: "green",
      type: "notified",
    };
  }
  return { statusText: "", colorScheme: "", type: "" };
}

interface MinimalTaskStatusProps extends TaskStatusProps {
  // eslint-disable-next-line react/require-default-props
  excludedStatuses?: string[];
}

export function MinimalTaskStatus({
  task,
  excludedStatuses = ["notified", "will-notify", "completed"],
  ...rest
}: MinimalTaskStatusProps) {
  const { statusText, colorScheme, type } = taskStatusTextAndColorScheme(task);
  if (excludedStatuses.includes(type)) {
    return null;
  }
  return (
    <Badge
      variant={task?.status === "completed" ? "solid" : "subtle"}
      colorScheme={colorScheme}
      size="lg"
      borderRadius="5px"
      {...rest}
    >
      {statusText}
    </Badge>
  );
}

export function TaskStatusWithTime({ task, ...rest }: TaskStatusProps) {
  if (!task) {
    return null;
  }
  const { statusText, colorScheme, type } = taskStatusTextAndColorScheme(task);
  // return nothing for certain statuses that do not have notifier information
  if (!type) {
    return null;
  }
  const linkify = !["completed", "skipped"].includes(type);
  const notifier = findTaskNotifier(task);

  return (
    <>
      {linkify && notifier ? (
        <RouterLink to={generatePath(PEOPLE_TEAM_ROUTES.assignedActions.show, { id: notifier.id })}>
          <Badge
            variant={task?.status === "completed" ? "solid" : "subtle"}
            colorScheme={colorScheme}
            size="lg"
            borderRadius="5px"
            {...rest}
          >
            {statusText}
          </Badge>
        </RouterLink>
      ) : (
        <Badge
          variant={task?.status === "completed" ? "solid" : "subtle"}
          colorScheme={colorScheme}
          size="lg"
          borderRadius="5px"
          {...rest}
        >
          {statusText}
        </Badge>
      )}
    </>
  );
}

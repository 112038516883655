import { Flex, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { useEffect } from "react";
import { Step } from "components/Steps";
import OnboardingPlanImageUrl from "assets/undraw_adventure_map.svg";
import ExternalLink from "components/Link";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { Company } from "models/company";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import { StepContent } from "./Components";
import { ONBOARDING_PLAN_URL_FIELD, newHireSchema } from "./definition";
import useSubmitNewHire from "./useSubmitNewHireUpdate";

interface AddOnboardingPlanStepProps {
  newHireJourney: ExtendedNewHireJourney;
}

function AddOnboardingPlanStep(props: AddOnboardingPlanStepProps) {
  const { newHireJourney } = props;
  const { data: company } = useQuery<Company>({
    queryKey: [QUERY_KEYS.companies, "current"],
    queryFn: async () => apiClient.get<Company>(generatePath(API_ROUTES.companies.show, { id: "current" })),
    refetchOnWindowFocus: false,
  });
  const { handleOnSubmit } = useSubmitNewHire({ newHireJourneyId: newHireJourney?.id || "" });
  const form = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(newHireSchema.pick([ONBOARDING_PLAN_URL_FIELD.name as "onboardingPlanUrl"])),
    defaultValues: {
      onboardingPlanUrl: newHireJourney?.onboardingPlanUrl || "",
    },
  });
  const {
    register,
    formState: { errors },
    reset,
  } = form;

  useEffect(() => {
    if (newHireJourney) {
      // Reset the form values when newHireJourney is updated
      reset({
        onboardingPlanUrl: newHireJourney?.onboardingPlanUrl || "",
      });
    }
  }, [newHireJourney, reset]);

  return (
    <Step
      id="add-onboarding-plan"
      onClickNext={(callback) => {
        handleOnSubmit({
          callback,
          form,
        });
      }}
    >
      <StepContent
        title="Add onboarding plan (optional)"
        imageUrl={OnboardingPlanImageUrl}
        imageDescription="Add the onboarding plan URL for the new hire"
      >
        <Flex direction="column" gap="8" width="100%" px="32">
          <Flex direction="column" gap="2" width="100%">
            <Text fontSize="sm">
              Add the URL to the onboarding plan for the new hire to be filled out by the manager.
            </Text>
            {company?.onboardingPlanTemplatesUrl ? (
              <Text fontSize="sm">
                You can create it from
                {" "}
                <ExternalLink as="span" to={company.onboardingPlanTemplatesUrl} text="this onboarding plan template" />
                .
              </Text>
            ) : null}
          </Flex>
          <TextInputField {...ONBOARDING_PLAN_URL_FIELD} register={register} errors={errors} />
        </Flex>
      </StepContent>
    </Step>
  );
}

export default AddOnboardingPlanStep;

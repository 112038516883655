import { useDisclosure } from "@chakra-ui/react";
import { useRef } from "react";

type ModalSize = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "full";

type ModalScrollBehavior = "inside" | "outside";

interface UseModalProps {
  id: string;
  header: { fontSize?: string; title: string };
  closeOnOverlayClick?: boolean;
  isCentered?: boolean;
  size: ModalSize;
  scrollBehavior?: ModalScrollBehavior;
}
export interface UseModalReturn {
  header: { fontSize: string; title: string };
  size: ModalSize;
  isOpen: boolean;
  closeOnOverlayClick: boolean;
  isCentered: boolean;
  initialFocusRef: React.RefObject<HTMLElement>;
  finalFocusRef: React.RefObject<HTMLElement>;
  scrollBehavior: "inside" | "outside";
  onOpen: () => void;
  onClose: () => void;
}

export function useModal({
  id,
  header,
  size,
  scrollBehavior,
  closeOnOverlayClick,
  isCentered,
}: UseModalProps): UseModalReturn {
  const { isOpen, onOpen, onClose } = useDisclosure({ id });

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  return {
    header: { fontSize: header.fontSize || "30", title: header.title },
    size: size || "lg",
    scrollBehavior: scrollBehavior || "inside",
    closeOnOverlayClick: closeOnOverlayClick || true,
    isCentered: isCentered || false,
    initialFocusRef: initialRef,
    finalFocusRef: finalRef,
    isOpen,
    onOpen,
    onClose,
  };
}

useModal.defaultProps = {
  closeOnOverlayClick: true,
  scrollBehavior: "inside",
  isCentered: false,
};

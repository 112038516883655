/* eslint-disable import/prefer-default-export */
import {
  Flex, Heading, Image, Text, Icon, Skeleton, FlexProps,
} from "@chakra-ui/react";
import { isImmediateAssignedAction } from "helpers/assignedAction";
import { findTaskNotifier } from "helpers/task";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { Task } from "models/joinedModels";
import { NewHireJourney } from "models/onboarding";
import React, { PropsWithChildren, useMemo } from "react";
import { RESOURCE_ICON } from "definitions/constants/icons";

export function StepQuestion({ children }: PropsWithChildren) {
  return (
    <Heading as="h3" size="xs" color="fg.subtle" textAlign="center">
      {children}
    </Heading>
  );
}

StepQuestion.defaultProps = {
  isLoading: false,
};

interface StepImageProps {
  srcUrl: string;
  alt: string;
}

export function StepImage({ srcUrl, alt }: StepImageProps) {
  return <Image mx={{ base: 6 }} maxWidth="180px" maxHeight="180px" alt={alt} src={srcUrl} />;
}

interface StepLayoutProps extends FlexProps {
  title: string;
  imageUrl?: string;
  imageDescription?: string;
}

export function StepContent({
  title, imageUrl, imageDescription, children, ...rest
}: StepLayoutProps) {
  return (
    <Flex align="center" direction="column" gap="12" mt="8" pt="8" {...rest}>
      <StepQuestion>{title}</StepQuestion>
      {imageUrl && imageDescription ? <StepImage srcUrl={imageUrl} alt={imageDescription} /> : null}
      {children}
    </Flex>
  );
}

StepContent.defaultProps = {
  imageUrl: null,
  imageDescription: null,
};

export function StepCardList({ children }: PropsWithChildren) {
  return (
    <Flex gap="2" direction="column" height="100%">
      {children}
    </Flex>
  );
}

interface ActionOverviewProps extends PropsWithChildren {
  emails: AssignedAction[];
  chatMessages: AssignedAction[];
  tasks: Task[];
  newHireJourney: NewHireJourney;
}

function ActionSummary({ icon, count, action }: { icon: React.ElementType; count: number; action: string }) {
  return (
    <Flex align="center">
      <Icon as={icon} boxSize="6" color="fg.muted" mr="2" mt="1" />
      <Text as="span" color="brand.500" fontSize="xl" fontWeight="medium">
        {`${count} ${action}${count > 1 ? "s" : ""}`}
      </Text>
    </Flex>
  );
}

export function ImmediateActionsSummary({ newHireJourney, emails, chatMessages, tasks }: ActionOverviewProps) {
  const immediateEmails = useMemo(() => emails.filter((email) => isImmediateAssignedAction(email)), [emails]);
  const immediateChatMessages = useMemo(
    () => chatMessages.filter((chat) => isImmediateAssignedAction(chat)),
    [chatMessages],
  );
  const immediateTasks = useMemo(
    () => tasks.filter((task) => isImmediateAssignedAction(findTaskNotifier(task))),
    [tasks],
  );
  const totalImmediateActions = immediateEmails.length + immediateChatMessages.length + immediateTasks.length;

  if (!newHireJourney) {
    return <Skeleton height="20px" />;
  }

  if (totalImmediateActions === 0) {
    return (
      <Text fontSize="xl" color="fg.muted">
        This new hire does not have any emails, chats, or tasks that will be immediately sent.
      </Text>
    );
  }

  const TotalText = (
    <Text as="span" fontWeight="bold">
      {`${totalImmediateActions} actions`}
    </Text>
  );

  return (
    <Flex direction="column" gap="4">
      <Text fontSize="xl" color="fg.muted">
        {`When you kickoff this pre-boarding, Camino will send `}
        {TotalText}
        {` immediately:`}
      </Text>
      <Flex justify="center" gap="12">
        {immediateEmails.length > 0 && (
          <ActionSummary icon={RESOURCE_ICON.email} count={immediateEmails.length} action="Email" />
        )}
        {immediateChatMessages.length > 0 && (
          <ActionSummary icon={RESOURCE_ICON.chat} count={immediateChatMessages.length} action="Chat Message" />
        )}
        {immediateTasks.length > 0 && (
          <ActionSummary icon={RESOURCE_ICON.task} count={immediateTasks.length} action="Task" />
        )}
      </Flex>
    </Flex>
  );
}

export function TotalActionsSummary({ newHireJourney, emails, chatMessages, tasks }: ActionOverviewProps) {
  const totalActions = emails.length + chatMessages.length + tasks.length;

  if (!newHireJourney || !Array.isArray(tasks)) {
    return <Skeleton height="20px" />;
  }

  if (totalActions === 0) {
    return (
      <Text fontSize="xl" color="fg.muted">
        This new hire does not have any emails, chats, or tasks for their onboarding.
      </Text>
    );
  }

  const TotalText = (
    <Text as="span" fontWeight="bold">
      {`${totalActions} total actions `}
    </Text>
  );

  return (
    <Flex direction="column" gap="4">
      <Text fontSize="xl" color="fg.muted">
        {TotalText}
        will be sent during onboarding:
      </Text>
      <Flex justify="center" gap="12">
        {emails.length > 0 && <ActionSummary icon={RESOURCE_ICON.email} count={emails.length} action="Email" />}
        {chatMessages.length > 0 && (
          <ActionSummary icon={RESOURCE_ICON.chat} count={chatMessages.length} action="Chat Message" />
        )}
        {tasks.length > 0 && <ActionSummary icon={RESOURCE_ICON.task} count={tasks.length} action="Task" />}
      </Flex>
    </Flex>
  );
}

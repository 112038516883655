/* eslint-disable object-curly-newline */
import { Center, Container, Divider, Stack, StackDivider, Text } from "@chakra-ui/react";
import { PlanStepProps } from "components/Form/typings";
import { Helpers } from "hooks/useStep";
import WizardCard, { CardContent, CardHeader } from "./Cardheader";
import ConnectionCard from "./ConnectionCard";
import AddConnectionButtonWithModal from "./AddConnectionButtonWithModal";

export default function Step2MakeConnections({
  goToPrevStep,
  goToNextStep,
  canGoToNextStep,
  canGoToPrevStep,
  onboardingPlan,
}: PlanStepProps & Partial<Helpers>) {
  return (
    <WizardCard>
      <CardHeader
        headerText="Make Important Connections"
        {...{ goToPrevStep, goToNextStep, canGoToNextStep, canGoToPrevStep }}
      />
      <Divider mb="4" />
      <CardContent>
        <Container mb="10">
          <Text color="fg.emphasized">
            Identify valuable people such as team members, cross functional partners, skip levels, people from their
            location/hub, or other valuable people for the new hire to have an introductory conversations with. We will
            proactively reach out to the connection to schedule the conversation.
          </Text>
        </Container>
        <Center>
          <Stack spacing="5" mb="5" divider={<StackDivider />}>
            {(onboardingPlan.connections || []).map((connection) => (
              <ConnectionCard key={connection.id} connection={connection} />
            ))}
          </Stack>
        </Center>
        <Center>
          <AddConnectionButtonWithModal onboardingPlan={onboardingPlan} />
        </Center>
      </CardContent>
    </WizardCard>
  );
}

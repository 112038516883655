/* eslint-disable react/require-default-props */
import {
  FormControl, FormErrorMessage, FormLabel, Input, Stack, Text,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { SelectFieldProps, TextInputFieldProps } from "components/Form/typings";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { User } from "models/user";
import { FieldErrors } from "react-hook-form";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";

interface InputDescriptionProps extends PropsWithChildren {
  label: string;
  description: string;
}

export default function InputDescription({ label, description, children }: InputDescriptionProps) {
  return (
    <Stack key={label} justify="space-between" direction="row" spacing="4">
      <Stack spacing="0.5" fontSize="sm" width="50%">
        <Text color="fg.emphasized" fontWeight="medium">
          {label}
        </Text>
        <Text color="fg.muted">{description}</Text>
      </Stack>
      {children}
    </Stack>
  );
}

export function TextInputField({
  name, label, register, helperText, errors, required,
}: TextInputFieldProps) {
  const error = errors ? errors[name] : null;
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={required}>
      <Stack key={label} justify="space-between" direction="row" spacing="4">
        <Stack spacing="0.5" fontSize="sm" width="50%">
          <FormLabel color="fg.emphasized" fontWeight="medium">
            {label}
          </FormLabel>
          <Text color="fg.muted">{helperText}</Text>
        </Stack>
        <Input {...register(name)} />
      </Stack>
      {error && <FormErrorMessage>{(error as any).message}</FormErrorMessage>}
    </FormControl>
  );
}

interface UserSelectProps {
  excludedIds?: string[];
  errors: FieldErrors;
}

export function UserSelectField({
  name,
  label,
  helperText,
  control,
  errors,
  required,
  cacheKey,
  excludedIds,
  disabled,
  readOnly,
}: SelectFieldProps & UserSelectProps) {
  return (
    <GenericAsyncSelectField
      cacheKey={cacheKey}
      control={control}
      name={name}
      label={label}
      helperText={helperText}
      isDisabled={disabled}
      required={required}
      fetchPath={API_ROUTES.users.search}
      isClearable={!readOnly}
      isReadOnly={readOnly}
      extractValue={(v: User) => v.id}
      embedOption={(v) => ({
        value: v.id,
        label: `${v.fullName} (${v.workEmail || "No Email"})`,
        rawValue: v,
      })}
      excludedIds={excludedIds}
      errors={errors}
    />
  );
}

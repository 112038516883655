import * as yup from "yup";

// PROFILE INFO
export const FIRST_NAME_FIELD = {
  label: "First Name",
  name: "firstName",
};
export const LAST_NAME_FIELD = {
  label: "Last Name",
  name: "lastName",
};
export const PREFERRED_NAME_FIELD = {
  label: "Preferred Name",
  name: "preferredName",
};
export const PRONOUNS_FIELD = {
  name: "pronouns",
  label: "Pronouns",
};
export const TITLE_FIELD = {
  label: "Job Title",
  name: "title",
};
export const START_DATE_FIELD = {
  label: "Start Date",
  name: "startDate",
};
export const LINKEDIN_URL_FIELD = {
  label: "LinkedIn Url",
  name: "linkedinUrl",
};
export const PERSONAL_EMAIL_FIELD = {
  label: "Personal Email",
  name: "personalEmail",
  textTransform: "lowercase",
};
export const WORK_EMAIL_FIELD = {
  label: "Future Work Email (if known)",
  name: "workEmail",
  textTransform: "lowercase",
};
export const LOCATION_FIELD = {
  label: "Location",
  name: "location",
};
export const TIMEZONE_FIELD = {
  label: "Timezone",
  name: "timezone",
};
export const PATH_SELECT_FIELD = {
  label: "Select Paths",
  name: "workflowIds",
};
export const ONBOARDING_PLAN_URL_FIELD = {
  label: "Onboarding Plan Url",
  name: "onboardingPlanUrl",
};

// SUPPORT TEAM FIELDS
export const MANAGER_FIELD = {
  label: "Manager",
  name: "managerId",
};
export const BUDDY_FIELD = {
  label: "Buddy",
  name: "buddyId",
  helperText: "Typically assigned by manager. Can add now if known",
};
export const PEOPLE_TEAM_CONTACT_FIELD = {
  label: "People Team Contact",
  name: "peopleTeamContactId",
};

// ADDITIONAL FIELDS
export const DEPARTMENT_FIELD = {
  label: "Department",
  name: "department",
};
export const COUNTRY_FIELD = {
  label: "Country",
  name: "country",
};
export const COUNTRY_CODE_FIELD = {
  label: "Country Code (e.g. +1 for US)",
  name: "phoneCountryCode",
};
export const PHONE_NUMBER = {
  label: "Country Code (e.g. +1 for US)",
  name: "phoneNumber",
};
export const EMPLOYMENT_TYPE = {
  label: "Employment Type",
  name: "employmentType",
};
export const SHIPPING_TRACKING_NUMBER_FIELD = {
  label: "Shipping Tracking Number",
  name: "shippingTrackingNumber",
  helperText: "Tracking number for the New Hire's laptop",
};
export const SHIPPING_TRACKING_URL_FIELD = {
  label: "Shipping Tracking Url",
  name: "shippingTrackingUrl",
  helperText: "Link to the shipping tracking number for the New Hire's laptop",
};
export const SHIPPING_CARRIER_FIELD = {
  label: "Shipping Carrier",
  name: "shippingCarrier",
  helperText: "Shipping carrier for the New Hire's laptop (e.g. UPS, FedEx, etc.)",
};
export const INTERNAL_ONBOARDING_CHECKLIST_URL_FIELD = {
  label: "Internal Onboarding Checklist Url",
  name: "internalOnboardingChecklistUrl",
  helperText: "Link to the HR/Ops/IT onboarding checklist for this specific New Hire",
};
export const HRIS_ONBOARDING_URL_FIELD = {
  label: "HRIS Onboarding Url",
  name: "hrisOnboardingUrl",
  helperText: "Link to the specific New Hire's login for Employee Paperwork",
};
export const TEAM_WIKI_URL_FIELD = {
  label: "Team Wiki Url",
  name: "teamWikiUrl",
  helperText: "The url for the team wiki",
};
export const TEAM_GOALS_URL_FIELD = {
  label: "Team Goals Url",
  name: "teamGoalsUrl",
  helperText: "The url for the team goals",
};

export interface NewHireFormValues {
  firstName: string;
  lastName: string;
  title: string;
  personalEmail?: string;
  workEmail?: string;
  location?: string;
  timezone?: string;
  peopleTeamContactId?: string;
  managerId?: string;
  buddyId?: string;
  startDate?: Date;
  pronouns?: string;
  linkedinUrl?: string;
  department?: string;
  country?: string;
  phoneCountryCode?: string;
  phoneNumber?: string;
  employmentType?: string;
  workflowIds?: string[];
  shippingTrackingNumber?: string;
  shippingTrackingUrl?: string;
  shippingCarrier?: string;
  internalOnboardingChecklistUrl?: string;
  hrisOnboardingUrl?: string;
}

export const newHireSchema = yup.object({
  firstName: yup.string().label("First Name").required(),
  lastName: yup.string().label("Last Name").required(),
  preferredName: yup.string().label("Preferred Name").notRequired(),
  title: yup.string().label("Title").required(),
  managerId: yup.string().label("Manager").required(),
  buddyId: yup.string().label("Buddy").notRequired(),
  peopleTeamContactId: yup.string().label("People Team Contact").required(),
  personalEmail: yup.string().label("Personal Email").email().lowercase()
    .required(),
  workEmail: yup.string().label("Work Email").email().lowercase(),
  location: yup.string().label("Location").notRequired(),
  timezone: yup.string().label("Timezone").notRequired(),
  pronouns: yup.string().label("Pronouns").notRequired(),
  linkedinUrl: yup.string().label("LinkedIn").notRequired(),
  department: yup.string().label("Department").notRequired(),
  country: yup.string().label("Country").notRequired(),
  phoneCountryCode: yup.string().label("Phone Country Code").notRequired(),
  phoneNumber: yup.string().label("Phone Number").notRequired(),
  employmentType: yup.string().label("Employment Type").notRequired(),
  startDate: yup.date().label("Start Date"),
  workflowIds: yup.array().of(yup.string().required()).optional().label("Paths")
    .required(),
  shippingTrackingNumber: yup.string().label("Shipping Tracking #").notRequired(),
  shippingTrackingUrl: yup.string().label("Shipping Tracking Url").notRequired(),
  shippingCarrier: yup.string().label("Shipping Carrier").notRequired(),
  internalOnboardingChecklistUrl: yup.string().label("Internal Onboarding Checklist").notRequired(),
  hrisOnboardingUrl: yup.string().label("HRIS Onboarding Url").notRequired(),
  onboardingPlanUrl: yup.string().label("Onboarding Plan Url").notRequired(),
});

import { Text } from "@chakra-ui/react";
import { SegmentedControlGroup, SegmentedControlButton } from "components/FormElements/SegmentedControlGroup";
import { RELATION_TARET_TYPE } from "definitions/constants";
import { PathRelationTarget } from "models/automation";
import { Dispatch, SetStateAction } from "react";

const SelectableRelationTargetTypeOptions = [
  { label: "All", value: "" },
  { label: "New Hire", value: RELATION_TARET_TYPE.NEW_HIRE },
  { label: "Manager", value: RELATION_TARET_TYPE.MANAGER },
  { label: "People Team", value: RELATION_TARET_TYPE.PEOPLE_TEAM },
  { label: "Buddy", value: RELATION_TARET_TYPE.BUDDY },
];

interface SelectableRelationTargetTypeProps {
  setRelationTargetType: Dispatch<SetStateAction<PathRelationTarget | "">>;
  relationTargetType: PathRelationTarget | "";
}

export default function SelectableRelationTargetType({
  relationTargetType,
  setRelationTargetType,
}: SelectableRelationTargetTypeProps) {
  return (
    <SegmentedControlGroup
      defaultValue={relationTargetType}
      spacing="2"
      onChange={(value: any) => setRelationTargetType(value === "" ? null : value)}
    >
      {SelectableRelationTargetTypeOptions.map(({ value, icon, label }) => (
        <SegmentedControlButton key={value} value={value} icon={icon}>
          <Text size="sm">{label}</Text>
        </SegmentedControlButton>
      ))}
    </SegmentedControlGroup>
  );
}

import { Box } from "@chakra-ui/react";
import DOMPurify from "dompurify";

function EmbeddedHtmlLetter({ messageHtml }: { messageHtml: string }) {
  const sanitizedHtml = () => ({
    __html: DOMPurify.sanitize(messageHtml),
  });

  return (
    <Box mt="10px" color="chakra-subtle-text">
      <Box dangerouslySetInnerHTML={sanitizedHtml()} />
    </Box>
  );
}

export default EmbeddedHtmlLetter;

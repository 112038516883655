import { Button, ButtonProps } from "@chakra-ui/react";
import ModalFormWrapper from "components/ModalForm/ModalFormWrapper";
import DynamicNewHireJourneyForm from "components/Forms/DynamicNewHireJourneyForm";
import { NewHireJourney } from "models/onboarding";
import { UserUpdateFormValues } from "components/Forms/UserUpdateForm";
import { AddIcon } from "@chakra-ui/icons";
import { QUERY_KEYS } from "definitions/constants";
import { useQueryClient } from "@tanstack/react-query";

interface AddToNewHireJourneyProps extends ButtonProps {
  newHireJourney: NewHireJourney;
  label: string;
  fieldName: keyof UserUpdateFormValues;
}

function AddToNewHireJourney({ newHireJourney, label, fieldName, ...rest }: AddToNewHireJourneyProps) {
  const queryClient = useQueryClient();
  const title = `Add ${label}`;
  const buttonText = `Add ${label}`;
  return (
    <ModalFormWrapper
      modalHeader={title}
      button={(
        <Button variant="outline" width="fit-content" size="xs" height="7" leftIcon={<AddIcon boxSize={2} />} {...rest}>
          {buttonText}
        </Button>
      )}
    >
      <DynamicNewHireJourneyForm
        newHireJourney={newHireJourney}
        fields={[fieldName]}
        onSuccess={() => queryClient.invalidateQueries([QUERY_KEYS.newHireJourneys])}
      />
    </ModalFormWrapper>
  );
}

interface MissingNamespaceButtonProps extends ButtonProps {
  newHireJourney: NewHireJourney;
}

export function AddMissingManagerButton({ newHireJourney, ...rest }: MissingNamespaceButtonProps) {
  return <AddToNewHireJourney newHireJourney={newHireJourney} label="Manager" fieldName="managerId" {...rest} />;
}

export function AddMissingBuddyButton({ newHireJourney, ...rest }: MissingNamespaceButtonProps) {
  return <AddToNewHireJourney newHireJourney={newHireJourney} label="Buddy" fieldName="buddyId" {...rest} />;
}

export function AddMissingPeopleTeamContactButton({ newHireJourney, ...rest }: MissingNamespaceButtonProps) {
  return (
    <AddToNewHireJourney
      newHireJourney={newHireJourney}
      label="People Team Contact"
      fieldName="peopleTeamContactId"
      {...rest}
    />
  );
}

export function AddMissingOnboardingPlanButton({ newHireJourney, ...rest }: MissingNamespaceButtonProps) {
  return (
    <AddToNewHireJourney
      newHireJourney={newHireJourney}
      label="Onboarding Plan"
      fieldName="onboardingPlanUrl"
      {...rest}
    />
  );
}

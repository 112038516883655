/* eslint-disable import/prefer-default-export */

export const dirtyValues = <
  TData extends Record<keyof TDirtyItems, unknown>,
  TDirtyItems extends Record<string, unknown>,
>(
    data: TData,
    dirtyItems: TDirtyItems,
    alwaysDirty: string[] = [],
  ): Partial<TData> => {
  const dirtyItemsEntries = Object.entries(dirtyItems);

  // Create an array of entries combining alwaysDirty and dirtyItems
  const allDirtyEntries = [...alwaysDirty.map((field) => [field, true]), ...dirtyItemsEntries] as [string, unknown][];

  return allDirtyEntries.reduce((dirtyData, [name, value]) => {
    // Arrays and null values are typeof "object" in JavaScript
    if (typeof value !== "object" || value === null || Array.isArray(value)) {
      if (Object.prototype.hasOwnProperty.call(data, name)) {
        return { ...dirtyData, [name]: data[name] };
      }
      return dirtyData;
    }

    return {
      ...dirtyData,
      [name]: dirtyValues(data[name] as TData, dirtyItems[name] as TDirtyItems),
    };
  }, {});
};

import {
  Box, Button, HStack, Stack, useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useState } from "react";
import { ResponseModel } from "services/ApiClient/responseModel";
import { useQueryClient } from "@tanstack/react-query";
import { FEATURE_FLAG_ADD_CUSTOM_RESOURCES } from "definitions/constants";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";

const RESOURCE_TYPE_FIELD = {
  type: "SELECT",
  label: "Resource Type",
  name: "resourceType",
  options: ["DOCUMENTATION", "GUIDE", "APPLICATION", "REPOSITORY", "OTHER"].map((value) => ({ label: value, value })),
};

const NAME_FIELD = {
  type: "text",
  label: "Resource Name",
  name: "name",
};

const URL_FIELD = {
  type: "text",
  label: "Url",
  name: "url",
};

const DESCRIPTION_FIELD = {
  type: "text",
  label: "Description",
  name: "description",
};

const NOTES_FIELD = {
  type: "text",
  label: "Notes",
  name: "notes",
};

const CONTACT_ID_FIELD = {
  type: "text",
  label: "Contact if Support Needed",
  name: "contactId",
  // helperText: "If the new hire gets stuck, who can they reach out to for help?",
};

const ONBORADING_PLAN_URL_FIELD = {
  type: "text",
  label: "Onboarding Plan Url",
  name: "onboardingPlanUrl",
};

const TEAM_WIKI_URL_FIELD = {
  type: "text",
  label: "Team Wiki Url",
  name: "teamWikiUrl",
};

const TEAM_GOALS_URL_FIELD = {
  type: "text",
  label: "Team Goals Url",
  name: "teamGoalsUrl",
};

const resourceSchema = yupObject({
  onboardingPlanUrl: yupString().label(RESOURCE_TYPE_FIELD.label),
  teamWikiUrl: yupString().label(RESOURCE_TYPE_FIELD.label),
  teamGoalsUrl: yupString().label(RESOURCE_TYPE_FIELD.label),
  resourceType: yupString().label(RESOURCE_TYPE_FIELD.label),
  name: yupString().label(NAME_FIELD.label),
  url: yupString().label(URL_FIELD.label),
  description: yupString().label(DESCRIPTION_FIELD.label),
  notes: yupString().label(NOTES_FIELD.label),
  contactId: yupString().label(CONTACT_ID_FIELD.label),
});

interface NewResourceFormProps {
  onboardingPlanId: string;
}

export default function NewResourceForm({ onboardingPlanId }: NewResourceFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(resourceSchema),
  });
  const toast = useToast();
  const queryClient = useQueryClient();

  // this is a workaround to bust the cache of react select when
  // a new user is added to the backend for the Select User
  const [cacheKey] = useState("");

  /* HandleOnSubmit */
  const onSubmit = async (data: any) => {
    try {
      const res = await apiClient.performRequest<ResponseModel<{ id: string }>>(API_ROUTES.resources.base, {
        method: "POST",
        body: JSON.stringify({ resource: { ...data, onboardingPlanId } }),
        headers: { "Content-Type": "application/json" },
      });
      const responseData = res.payload.data;
      queryClient.invalidateQueries(["onboardingPlans", onboardingPlanId]);
      console.log(responseData);
      reset({
        name: "",
        url: "",
        description: "",
        // notes: "",
        contactId: "",
      });
      toast({
        title: "New Resource Created.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      // log the newly created item to the console
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Stack spacing="3">
      <TextInputField required {...ONBORADING_PLAN_URL_FIELD} errors={errors} register={register} />
      <TextInputField required {...TEAM_WIKI_URL_FIELD} errors={errors} register={register} />
      <TextInputField required {...TEAM_GOALS_URL_FIELD} errors={errors} register={register} />
      <Button alignSelf="center" width="30%" mt={5} minW={40} onClick={handleSubmit(onSubmit)}>
        Add Resources
      </Button>

      {FEATURE_FLAG_ADD_CUSTOM_RESOURCES && (
        <>
          <HStack spacing="5">
            <TextInputField required {...NAME_FIELD} errors={errors} register={register} />
            <Box width="40%">
              <SingleSelectField required {...RESOURCE_TYPE_FIELD} errors={errors} control={control} />
            </Box>
          </HStack>
          <HStack spacing="5">
            <TextInputField required {...URL_FIELD} errors={errors} register={register} />
            <Box width="40%">
              <UserSelectField cacheKey={cacheKey} control={control} {...CONTACT_ID_FIELD} errors={errors} />
            </Box>
          </HStack>
          <HStack spacing="10" alignItems="end">
            <TextInputField {...DESCRIPTION_FIELD} errors={errors} register={register} />
            <Button width="40%" minW={40} onClick={handleSubmit(onSubmit)}>
              Add Resource
            </Button>
          </HStack>
        </>
      )}
    </Stack>
  );
}

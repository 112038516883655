import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  Link,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ExtendedNewHireJourney, Task } from "models/joinedModels";
import { Step } from "components/Steps";
import { useCallback, useMemo, useState } from "react";
import TaskSideDrawer from "components/AssignedActionSideDrawer/TaskSideDrawer";
import { Link as RouterLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import moment from "moment";
import { taskPath } from "helpers/url";
import { findTaskNotifier, sortTasksByNotifier } from "helpers/task";
import TaskCommunications from "features/Task/AssignedTaskCard/TaskCommunication";
import { TaskTargetText } from "features/Task/DisplayInfo";
import DeleteTaskButton from "features/Task/DeleteTaskButton";
import useSendNowAssignedAction from "features/AssignedAction/hooks/useSendNowAssignedAction";
import { RandomEmptyTasksStepState } from "components/EmptyComponents/RandomEmptyState";
import { StepCardList, StepContent } from "./Components";
import useAllNewHireJourneyTasks from "./useAllNewHireJourneyTasks";

const RELATIONS = ["new_hire", "manager", "people_team_contact", "buddy"];

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this task now!",
  body: "This cannot be undone! Be sure you want to send this task now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface TaskCardProps {
  task: Task;
  onOpen: (taskId: string) => void;
}

function TaskCard({ task, onOpen }: TaskCardProps) {
  const queryClient = useQueryClient();
  const bgColor = useColorModeValue("inherit", "gray.600");

  const taskNotifier = useMemo(() => findTaskNotifier(task), [task]);

  const { sendNow, isReady } = useSendNowAssignedAction({
    assignedAction: taskNotifier,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.tasks, task?.id]);
      queryClient.invalidateQueries([QUERY_KEYS.assignedPaths, taskNotifier?.scheduledWorkflowId]);
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourney, task?.newHireJourneyId]);
    },
  });

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEYS.newHireJourney, task?.newHireJourney?.id]);
  }, [task?.newHireJourney?.id, queryClient]);

  return (
    <Card bg={bgColor}>
      <CardBody display="flex" gap="1" alignItems="flex-start" flexDirection="column">
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Text fontSize="lg" fontWeight="medium" textAlign="left" color="chakra-body-text">
            {task.title}
          </Text>
          <DeleteTaskButton taskId={task?.id} onSuccess={onSuccess} iconSize="xs" alertHeader="Delete Task" />
        </Flex>
        <Flex gap="4" my="1" ml="4">
          <TaskTargetText task={task} linkProps={{ target: "_blank" }} />
        </Flex>
        <TaskCommunications overrideImmediate task={task} direction="column" tagSize="sm" align="flex-start" />
        {task.status === "completed" ? (
          <Flex width="100%" ml="4" mt="2" gap="2">
            <Text fontSize="sm" fontWeight="medium" textAlign="center">
              {`✅ `}
              <Link as={RouterLink} to={taskPath(task)}>
                Task
              </Link>
              {` completed ${moment(task.completedAt).fromNow()}`}
            </Text>
          </Flex>
        ) : (
          <ButtonGroup display="flex" mt="2" gap="2">
            <Button onClick={() => onOpen(task.id)} size="sm">
              Edit Task
            </Button>
            <ButtonWithConfirm
              intent="warning"
              isDisabled={!isReady}
              {...CONFIRM_SEND_NOW}
              handleConfirm={() => sendNow()}
              buttonProps={{ size: "sm" }}
            />
          </ButtonGroup>
        )}
      </CardBody>
    </Card>
  );
}

interface ReviewTasksStepProps {
  newHireJourney: ExtendedNewHireJourney;
}

function ReviewTasksStep(props: ReviewTasksStepProps) {
  const { newHireJourney } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTaskId, setSelectedTaskId] = useState<string>("");
  const { tasks } = useAllNewHireJourneyTasks({ newHireJourney });

  const handleOpenDrawer = (taskId: string) => {
    setSelectedTaskId(taskId);
    onOpen();
  };

  const allTasks = useMemo(
    () => sortTasksByNotifier((tasks || []) as Task[]).map((task) => {
      // eslint-disable-next-line no-param-reassign
      task.taskNotifier = findTaskNotifier(task);
      return task;
    }),
    [tasks],
  );

  const newHireTasks = allTasks.filter(
    (task) => task?.taskNotifier?.workflowAction?.actionable?.relationTarget === "new_hire",
  );
  const managerTasks = allTasks.filter(
    (task) => task?.taskNotifier?.workflowAction?.actionable?.relationTarget === "manager",
  );
  const peopleTeamTasks = allTasks.filter(
    (task) => task?.taskNotifier?.workflowAction?.actionable?.relationTarget === "people_team_contact",
  );
  const buddyTasks = allTasks.filter(
    (task) => task?.taskNotifier?.workflowAction?.actionable?.relationTarget === "buddy",
  );
  const nonRelationTasks = allTasks.filter(
    (task) => !RELATIONS.includes(task?.taskNotifier?.workflowAction?.actionable?.relationTarget as string),
  );

  return (
    <Step id="review-tasks-step">
      <StepContent title="Review tasks">
        {!allTasks?.length ? (
          <RandomEmptyTasksStepState />
        ) : (
          <Tabs size="lg" variant="underline" colorScheme="brand" orientation="vertical" width="100%">
            <TabList width="fit-content" minWidth="150px" height="100%">
              <Tab textAlign="start">All</Tab>
              {newHireTasks.length ? <Tab textAlign="start">New Hires</Tab> : null}
              {managerTasks.length ? <Tab textAlign="start">Managers</Tab> : null}
              {peopleTeamTasks.length ? <Tab textAlign="start">People Team</Tab> : null}
              {buddyTasks.length ? <Tab textAlign="start">Buddy</Tab> : null}
              {nonRelationTasks.length ? <Tab textAlign="start">Other</Tab> : null}
            </TabList>
            <TabIndicator />
            <TabPanels>
              <TabPanel>
                <StepCardList>
                  {allTasks.map((task) => (
                    <TaskCard key={task.id} task={task} onOpen={handleOpenDrawer} />
                  ))}
                </StepCardList>
              </TabPanel>
              {newHireTasks.length ? (
                <TabPanel>
                  <StepCardList>
                    {newHireTasks.map((task) => (
                      <TaskCard key={task.id} task={task} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
              {managerTasks.length ? (
                <TabPanel>
                  <StepCardList>
                    {managerTasks.map((task) => (
                      <TaskCard key={task.id} task={task} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
              {peopleTeamTasks.length ? (
                <TabPanel>
                  <StepCardList>
                    {peopleTeamTasks.map((task) => (
                      <TaskCard key={task.id} task={task} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
              {buddyTasks.length ? (
                <TabPanel>
                  <StepCardList>
                    {buddyTasks.map((task) => (
                      <TaskCard key={task.id} task={task} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
              {nonRelationTasks.length ? (
                <TabPanel>
                  <StepCardList>
                    {nonRelationTasks.map((task) => (
                      <TaskCard key={task.id} task={task} onOpen={handleOpenDrawer} />
                    ))}
                  </StepCardList>
                </TabPanel>
              ) : null}
            </TabPanels>
          </Tabs>
        )}
      </StepContent>
      <TaskSideDrawer isOpen={isOpen} onClose={onClose} taskId={selectedTaskId} />
    </Step>
  );
}

export default ReviewTasksStep;

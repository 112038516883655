/* eslint-disable max-len */
import { Wrap, WrapItem } from "@chakra-ui/react";
import LinkCard from "components/Card/LinkCard";
import { FaLaptopMedical, FaMapLocation } from "react-icons/fa6";
import { BsInfoSquare, BsPersonVcard } from "react-icons/bs";
import { TbProgressCheck } from "react-icons/tb";
import { IoRocketOutline } from "react-icons/io5";
import { TiDocumentText } from "react-icons/ti";
import { HiOutlineBookOpen } from "react-icons/hi2";
import { VscHome } from "react-icons/vsc";

interface ImportantResourcesProps {
  personalizedOnboardingPlanUrl?: string;
  newHireInformationPacketUrl?: string;
  companyHandbookUrl?: string;
  hrisUrl?: string;
  companyWikiUrl?: string;
  teamWikiUrl?: string;
  teamGoalsUrl?: string;
  companyGoalsUrl?: string;
  benefitsInfoUrl?: string;
}

const resources = {
  PERSONALIZED_ONBOARDING_PLAN: {
    id: "testOnboardingPlan",
    name: "Onboarding Plan",
    description: "Your personalized onboarding plan.",
    resourceType: "GUIDE",
    url: "",
  },
  NEW_HIRE_INFORMATION_PACKET: {
    id: "testNEWHIREINFOPACKET",
    name: "New Hire Info Packet",
    description: "Information regarding benefits, etc.",
    resourceType: "GUIDE",
    url: "",
  },
  COMPANY_HANDBOOK: {
    id: "testCompanyHandbook",
    name: "Company Handbook",
    description: "Information about our policies.",
    resourceType: "OFFICIAL_COMPANY_RESOURCE",
    url: "",
  },
  COMPANY_WIKI: {
    id: "testCompanyWiki",
    name: "Company Wiki",
    description: "Our company wiki, created by teammates, for teammates.",
    resourceType: "OFFICIAL_COMPANY_RESOURCE",
    url: "",
  },
  HRIS: {
    id: "testHRIS",
    name: "HR Application",
    description: "Our HR Application where you can manage your HR profile.",
    resourceType: "OFFICIAL_COMPANY_RESOURCE",
    url: "",
  },
  TEAM_WIKI: {
    id: "testGrowthTeamWiki",
    name: "Growth Team (your team!) Wiki",
    description: "Your team's information.",
    resourceType: "DOCUMENTATION",
    url: "",
  },
  COMPANY_GOALS: {
    id: "testCompanyGoals",
    name: "Company Goals",
    description: "Our collective goals for this year!",
    resourceType: "OFFICIAL_COMPANY_RESOURCE",
    url: "",
  },
  TEAM_GOALS: {
    id: "testGrowthTeamGoals",
    name: "Growth Team Goals",
    description: "Your team's goals for this quarter.",
    resourceType: "STRATEGIC_DOCUMENT",
    url: "",
  },
  COMPANY_BENEFITS: {
    id: "testCompanyBenefits",
    name: "Company Benefits",
    description: "Information about your company benefits.",
    resourceType: "OFFICIAL_COMPANY_RESOURCE",
    url: "",
  },
};

export default function ImportantResources({
  personalizedOnboardingPlanUrl,
  newHireInformationPacketUrl,
  companyHandbookUrl,
  hrisUrl,
  companyWikiUrl,
  teamWikiUrl,
  teamGoalsUrl,
  companyGoalsUrl,
  benefitsInfoUrl,
}: ImportantResourcesProps) {
  resources.PERSONALIZED_ONBOARDING_PLAN.url = personalizedOnboardingPlanUrl as string;
  resources.NEW_HIRE_INFORMATION_PACKET.url = newHireInformationPacketUrl as string;
  resources.COMPANY_HANDBOOK.url = companyHandbookUrl as string;
  resources.COMPANY_WIKI.url = companyWikiUrl as string;
  resources.HRIS.url = hrisUrl as string;
  resources.TEAM_WIKI.url = teamWikiUrl as string;
  resources.COMPANY_GOALS.url = companyGoalsUrl as string;
  resources.TEAM_GOALS.url = teamGoalsUrl as string;
  resources.COMPANY_BENEFITS.url = benefitsInfoUrl as string;
  return (
    <Wrap width="100%" spacingX="4">
      {personalizedOnboardingPlanUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.PERSONALIZED_ONBOARDING_PLAN} label="Onboarding Plan" icon={FaMapLocation} />
        </WrapItem>
      )}
      {newHireInformationPacketUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.NEW_HIRE_INFORMATION_PACKET} label="New Hire Info Packet" icon={BsInfoSquare} />
        </WrapItem>
      )}
      {companyHandbookUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.COMPANY_HANDBOOK} label="Company Handbook" icon={TiDocumentText} />
        </WrapItem>
      )}
      {companyWikiUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.HRIS} label="HR Software" icon={BsPersonVcard} />
        </WrapItem>
      )}
      {hrisUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.COMPANY_WIKI} label="Company Wiki" icon={HiOutlineBookOpen} />
        </WrapItem>
      )}
      {teamWikiUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.TEAM_WIKI} label="Team Wiki" icon={VscHome} />
        </WrapItem>
      )}
      {companyGoalsUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.TEAM_GOALS} label="Team Goals" icon={IoRocketOutline} />
        </WrapItem>
      )}
      {teamGoalsUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.COMPANY_GOALS} label="Company Goals" icon={TbProgressCheck} />
        </WrapItem>
      )}
      {benefitsInfoUrl && (
        <WrapItem height="209px">
          <LinkCard target={resources.COMPANY_BENEFITS} label="Company Benefits" icon={FaLaptopMedical} />
        </WrapItem>
      )}
    </Wrap>
  );
}

ImportantResources.defaultProps = {
  personalizedOnboardingPlanUrl: "",
  newHireInformationPacketUrl: "",
  companyHandbookUrl: "",
  hrisUrl: "",
  companyWikiUrl: "",
  teamWikiUrl: "",
  teamGoalsUrl: "",
  companyGoalsUrl: "",
  benefitsInfoUrl: "",
};

import { Cohort } from "models/cohort";
import BasicInfoCard, { AttributeAccessor } from "./BasicInfoCard";

interface CohortCardProps {
  cohort: Cohort;
}

const basicCohortFields: AttributeAccessor<Partial<Cohort>>[] = [
  {
    label: "Cohort Id",
    attribute: "id",
  },
  {
    label: "Name",
    attribute: "name",
  },
  {
    label: "Start Date",
    attribute: "startDate",
  },
  {
    label: "Latest Possible Start Date",
    attribute: "finalStartDate",
  },
  {
    label: "Created",
    attribute: "createdAt",
  },
  {
    label: "Last Updated",
    attribute: "updatedAt",
  },
];

// New Hires
// Calendar
// Cohort Lead

export default function CohortCard({ cohort }: CohortCardProps) {
  return <BasicInfoCard fieldDefinitions={basicCohortFields} header="Cohort Info" data={cohort} />;
}

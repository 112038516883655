import breakIntoSectionsByDate from "helpers/onboardingPlan";
import AccordionLayout, { AccordionSection } from "layouts/Accordion";
import { BaseTask } from "models/task";
import { Skeleton } from "@chakra-ui/react";
import TaskGroup from "./TaskCardDropDown";

interface TaskSectionProps {
  tasks?: BaseTask[];
  startDate?: Date;
}

export default function TaskSection({ startDate, tasks }: TaskSectionProps) {
  if (!startDate || !tasks) {
    return <Skeleton />;
  }

  const tasksBySection = breakIntoSectionsByDate(startDate, tasks);
  const sectionTitles = [
    "Day 1",
    "Day 2",
    "Week 1",
    "Week 2",
    "Week 3",
    "Month 1",
    "Month 2",
    "Month 3",
    "Months 4 - 6",
  ];

  const calculateSectionCompletion = (sectionTasks: BaseTask[]) => {
    const tasksLength = sectionTasks.length;
    const tasksCompleted = sectionTasks.filter((task) => !!task.completedAt).length;
    return `${tasksCompleted} / ${tasksLength} complete`;
  };

  return (
    <AccordionLayout allowMultiple>
      {sectionTitles.map((sectionTitle) => (
        <AccordionSection
          key={sectionTitle}
          title={sectionTitle}
          titleBackgroundColor="gray.200"
          rightTitle={calculateSectionCompletion(tasksBySection[sectionTitle])}
        >
          <TaskGroup tasks={tasksBySection[sectionTitle]} />
        </AccordionSection>
      ))}
    </AccordionLayout>
  );
}

TaskSection.defaultProps = {
  tasks: null,
  startDate: null,
};

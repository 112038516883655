import { Text } from "@chakra-ui/react";
import { SegmentedControlButton, SegmentedControlGroup } from "components/FormElements/SegmentedControlGroup";
import { RESOURCE_ICON } from "definitions/constants/icons";
import { ActionableType } from "models/automation";
import { Dispatch, SetStateAction } from "react";

const SelectableActionTypeOptions = [
  { label: "All", value: "", icon: RESOURCE_ICON.all },
  { label: "Email", value: "email", icon: RESOURCE_ICON.email },
  { label: "Chat Message", value: "chat", icon: RESOURCE_ICON.chat },
  { label: "Task", value: "task", icon: RESOURCE_ICON.task },
];

interface SelectableActionTypeProps {
  setActionType: Dispatch<SetStateAction<ActionableType | "">>;
  actionType: string;
}

export default function SelectableActionType({ actionType, setActionType }: SelectableActionTypeProps) {
  return (
    <SegmentedControlGroup
      defaultValue={actionType}
      spacing="2"
      onChange={(value: any) => setActionType(value === "" ? null : value)}
    >
      {SelectableActionTypeOptions.map(({ value, icon, label }) => (
        <SegmentedControlButton key={value} value={value} icon={icon}>
          <Text size="sm">{label}</Text>
        </SegmentedControlButton>
      ))}
    </SegmentedControlGroup>
  );
}

import React from "react";
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { FiTrash2 } from "react-icons/fi";
import apiClient from "services/ApiClient";
import { generatePath } from "react-router-dom";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { AssignedPath } from "models/automation/scheduledWorkflow";

interface RemovePathConfirmationProps {
  assignedPathId: string;
  newHireJourneyId: string;
  iconSize?: string;
}

export default function RemovePathConfirmation({
  assignedPathId,
  newHireJourneyId,
  iconSize,
}: RemovePathConfirmationProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const cancelRef = React.useRef();

  const onDelete = async () => {
    try {
      const res = await apiClient.delete<AssignedPath>(
        generatePath(API_ROUTES.assignedPaths.show, {
          id: assignedPathId,
        }),
      );
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourney, newHireJourneyId]);
      console.log(res);
      toast({
        title: "Path Removed",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      onClose();
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <>
      <IconButton
        icon={<FiTrash2 />}
        variant="tertiary"
        aria-label="Remove Path"
        onClick={onOpen}
        {...(iconSize ? { size: iconSize } : {})}
      />

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Path
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can&apos;t undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

RemovePathConfirmation.defaultProps = {
  iconSize: null,
};

// eslint-disable-next-line object-curly-newline
import { Box, Heading, Wrap, WrapItem } from "@chakra-ui/react";
import LoadingCard from "components/Card/LoadingCard";
import ValueCard from "components/Card/ValueCard";
import { User } from "models/user";
import { CompanyValue } from "models/welcomeFlow";

interface CompanyValuesProps {
  user: User | null;
  values: CompanyValue[];
}

export default function CompanyValues({ user, values }: CompanyValuesProps) {
  if (!values) {
    return <LoadingCard />;
  }
  return (
    <Box
      p="12"
    >
      <Heading as="h3" fontWeight="400" mb="32px">
        Which of our values resonate most with you?
      </Heading>
      {/* <Wrap justifySelf="center" justify="center" spacing="10" {... (values.length > 4) ? { maxW: 1322 } : {}}> */}
      <Wrap justifySelf="center" justify="center" spacing="5">
        {(values || []).map((value) => (
          <WrapItem key={value.title}>
            <ValueCard
              maxW={values.length === 5 ? "270px" : "368px"}
              mt={3}
              value={value}
            />
          </WrapItem>
        ))}
      </Wrap>
    </Box>
  );
}

import { User } from "models/user";
import TitleCard from "./TitleCard";

interface SimpleNameCardProps {
  user: User | null;
}

export default function SimpleNameCard({ user }: SimpleNameCardProps) {
  const nameText = `Welcome${user?.firstName ? `, ${user.firstName}!` : "!"}`;
  return <TitleCard titleText={nameText} />;
}

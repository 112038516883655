/* eslint-disable no-else-return */
import { Row } from "@tanstack/react-table";
import { BaseAssignedAction } from "models/automation";
import { Task } from "models/joinedModels";

export function findTaskNotifier(task: Task) {
  return (task?.scheduledWorkflowActions || []).find(
    (assignedAction: BaseAssignedAction) => assignedAction.actionType === "task_notifier",
  );
}

export function setTaskNotifierOnTask(task: Task) {
  // eslint-disable-next-line no-param-reassign
  task.taskNotifier = (task.scheduledWorkflowActions || []).find(
    (assignedAction: BaseAssignedAction) => assignedAction.actionType === "task_notifier",
  );
  return task;
}

export function sortByNotifier(a: Task, b: Task) {
  const aNotifier = findTaskNotifier(a);
  const bNotifier = findTaskNotifier(b);

  if (aNotifier && bNotifier) {
    if (aNotifier.trigger.type === "date_time" && bNotifier.trigger.type === "date_time") {
      // Both are date_time triggers, compare datetime
      const datetimeA = typeof aNotifier.trigger.data.datetime === "string"
        ? aNotifier.trigger.data.datetime
        : aNotifier.trigger.data.datetime.toISOString(); // Convert Date to ISO string
      const datetimeB = typeof bNotifier.trigger.data.datetime === "string"
        ? bNotifier.trigger.data.datetime
        : bNotifier.trigger.data.datetime.toISOString(); // Convert Date to ISO string
      return datetimeA.localeCompare(datetimeB);
    } else if (aNotifier.trigger.type === "dependency" && bNotifier.trigger.type === "date_time") {
      // aNotifier is dependency, bNotifier is date_time, sort aNotifier to the end
      return 1;
    } else if (aNotifier.trigger.type === "date_time" && bNotifier.trigger.type === "dependency") {
      // aNotifier is date_time, bNotifier is dependency, sort bNotifier to the end
      return -1;
    } else {
      // Both are dependencies, maintain current order
      return 0;
    }
  } else if (aNotifier) {
    // bNotifier is undefined, sort aNotifier to the beginning
    return -1;
  } else if (bNotifier) {
    // aNotifier is undefined, sort bNotifier to the beginning
    return 1;
  } else {
    // Both aNotifier and bNotifier are undefined, maintain current order
    return 0;
  }
}

export function sortTasksByNotifier(tasks: Task[]) {
  return tasks.sort(sortByNotifier);
}

export function sortTaskRowsByNotifier<TData>(rowA: Row<TData>, rowB: Row<TData>) {
  return sortByNotifier(rowA.original as Task, rowB.original as Task);
}

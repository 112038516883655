import {
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import FocusLock from "react-focus-lock";
import { FaUserPlus } from "react-icons/fa";
import SimpleEmployeeForm from "../Forms/SimpleEmployeeForm";

interface AddNewEmployeeButtonProps {
  updateCacheKey?: (cacheKey: string) => void;
  // eslint-disable-next-line react/require-default-props
  isModal?: boolean;
}

export default function AddNewEmployeeButton({ updateCacheKey, isModal }: AddNewEmployeeButtonProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  // workaround for styling - if its a modal, have it be mt equals 7 to align with the userselect next to it
  const modalStyles: { mt?: string } = {};
  if (isModal) {
    // unnecessary margin commented out
    // modalStyles.mt = "7";
  }

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="right" closeOnBlur={false} isLazy>
      <Tooltip label="Add New Employee">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton aria-label="create new employee" size="sm" icon={<FaUserPlus />} {...modalStyles} />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent p={5}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontWeight="bold" paddingLeft={0}>
            Add New Employee
          </PopoverHeader>
          <SimpleEmployeeForm updateCacheKey={updateCacheKey} onClose={onClose} />
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
}

AddNewEmployeeButton.defaultProps = {
  updateCacheKey: undefined,
};

import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { NewHireJourney } from "models/onboarding";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { generatePath } from "react-router-dom";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { QUERY_KEYS } from "definitions/constants";
import NewHireJourneyDocsForm from "../NewHireJourney/NewHireJourneyDocsForm";
import NewHireJourneyForm from "../NewHireJourney/NewHireJourneyForm";

interface NewHireJourneyEditButtonWithModalProps extends PropsWithChildren, ButtonProps {
  newHireJourney: NewHireJourney | undefined;
  formType?: "RESOURCES";
}

export default function NewHireJourneyEditButtonWithModal({
  newHireJourney,
  children,
  formType,
  ...rest
}: NewHireJourneyEditButtonWithModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const queryClient = useQueryClient();

  const initialRef = React.useRef(null);

  const { mutate, isLoading } = useMutation(
    async (formData: any) => {
      const res = await apiClient.put<NewHireJourney>(
        generatePath(API_ROUTES.newHireJourneys.show, { id: newHireJourney?.id }),
        {
          onboardingJourney: formData,
        },
      );
      return res;
    },
    {
      onSuccess: async (res) => {
        queryClient.setQueryData([QUERY_KEYS.newHireJourney, res.id], res);
        toast({ title: "Updated" });
        onClose();
      },
      onError: (e) => {
        console.log(e);
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
        });
      },
    },
  );

  let Form;
  const modalSize = "3xl";
  switch (formType) {
    case "RESOURCES":
      Form = NewHireJourneyDocsForm;
      break;
    default:
      Form = NewHireJourneyForm;
      break;
  }
  return (
    <>
      {React.cloneElement(children as JSX.Element, { onClick: onOpen, ...rest }) || (
        <Button onClick={onOpen} {...rest}>
          Edit New Hire
        </Button>
      )}

      <Modal
        initialFocusRef={initialRef}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={modalSize}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={30}>Edit New Hire Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {Form && <Form data={newHireJourney} isLoading={isLoading} onFormSubmit={mutate} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

NewHireJourneyEditButtonWithModal.defaultProps = {
  formType: null,
};

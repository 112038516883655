import { Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import {
  forwardRef, useCallback, useEffect, useImperativeHandle, useState,
} from "react";
import RecipientsContainer from "components/Forms/PathActions/Email/RecipientsContainer";
import { TRIGGER_DATE_TIME_FIELD } from "components/ModalForm/ScheduledActionForm/definitions";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { EmailRecipient } from "models/automation";
import { yupResolver } from "@hookform/resolvers/yup";
import { dirtyValues } from "helpers/form";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import moment from "moment";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import { Schema, schema } from "./schema";
import { BODY_FIELD, SUBJECT_FIELD } from "./fields";
import useSubmitUpdateForm from "../Shared/useSubmitUpdateForm";
import { TriggerExplanation } from "../Shared/DisplayInfo";
import { showDatepicker } from "../Shared/helpers";

const ALWAYS_DIRTY_FIELDS = ["additionalRecipients", "ccRecipients", "bccRecipients"];

const CUSTOM_DATE_TIME_BUTTON_PROPS = {
  header: "Custom Date and Time",
  body: "Are you sure you want to customize the date and time for when this email is sent? Doing so will override the default date and time for sending this email.",
  confirmButtonText: "Yes",
  buttonText: "Add Custom Date and Time",
};

function isEmailAction(action: AssignedAction): action is AssignedAction & { actionType: "email" } {
  return action.actionType === "email";
}

const values = (action: AssignedAction): Schema => {
  if (!isEmailAction(action)) {
    throw new Error("Action is not of type email");
  }

  return {
    actionType: "email",
    content: {
      body: action?.content?.bodyTrix ?? action?.content?.body,
      subject: action?.content?.subject,
    },
    name: action?.name,
    trigger: action?.trigger,
    additionalRecipients: action?.additionalRecipients as EmailRecipient[],
    ccRecipients: action?.ccRecipients as EmailRecipient[],
    bccRecipients: action?.bccRecipients as EmailRecipient[],
  };
};

interface UpdateEmailFormProps {
  assignedAction: AssignedAction;
  onSuccess: () => void;
  setIsSubmitLoading: (isLoading: boolean) => void;
  setIsFormDirty: (isLoading: boolean) => void;
}

const UpdateEmailForm = forwardRef(
  ({ assignedAction, onSuccess, setIsSubmitLoading, setIsFormDirty }: UpdateEmailFormProps, ref) => {
    const queryClient = useQueryClient();
    const {
      control,
      formState: { errors, dirtyFields, isDirty },
      handleSubmit,
      register,
      watch,
      reset,
    } = useForm<Schema>({
      mode: "onBlur",
      resolver: yupResolver<Schema>(schema, { stripUnknown: true }),
      defaultValues: values(assignedAction),
    });
    const { onSubmit: onSubmitForm, isLoading: onSubmitLoading } = useSubmitUpdateForm({
      assignedActionId: assignedAction.id,
      onSuccess,
    });
    const [showDatePicker, setShowDatePicker] = useState(assignedAction && showDatepicker(assignedAction));

    useEffect(() => {
      setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    useEffect(() => {
      setIsSubmitLoading(onSubmitLoading);
    }, [onSubmitLoading, setIsSubmitLoading]);

    const handleOnSubmit = useCallback(
      (onSuccessCallback: () => void) => {
        handleSubmit((data) => {
          // handleSubmit built in - if the form is invalid, this function will not be called.
          // Because of this, if we pull "updateData" and its an empty object (no dirty fields),
          // we do not need to call the onSubmit function.
          const updateData = dirtyValues(data, dirtyFields, ALWAYS_DIRTY_FIELDS);
          updateData.actionType = data.actionType;
          if (Object.keys(updateData).length === 0) {
            onSuccess?.();
            return;
          }
          onSubmitForm(updateData, {
            onSuccess: () => {
              queryClient.invalidateQueries([
                QUERY_KEYS.newHireJourney,
                assignedAction?.scheduledWorkflow?.onboardingJourneyId,
              ]);
              reset({}, { keepValues: true });
              onSuccessCallback?.();
            },
            onError: (error) => {
              console.log("error", error);
            },
          });
        })();
      },
      [
        handleSubmit,
        dirtyFields,
        onSubmitForm,
        onSuccess,
        queryClient,
        assignedAction?.scheduledWorkflow?.onboardingJourneyId,
        reset,
      ],
    );

    // Expose form methods to parent via ref
    useImperativeHandle(ref, () => ({
      handleSubmit: (onSuccessCallback: () => void) => handleOnSubmit(onSuccessCallback),
    }));

    const [additionalRecipients, ccRecipients, bccRecipients] = watch([
      "additionalRecipients",
      "ccRecipients",
      "bccRecipients",
    ]);

    const [showAdditionalRecipients, setShowAdditionalRecipients] = useState(!!additionalRecipients?.length);
    const [showCc, setShowCc] = useState(!!ccRecipients?.length);
    const [showBcc, setShowBcc] = useState(!!bccRecipients?.length);

    useEffect(() => {
      if (additionalRecipients?.length) {
        setShowAdditionalRecipients(true);
      }
    }, [additionalRecipients, setShowAdditionalRecipients]);

    useEffect(() => {
      if (ccRecipients?.length) {
        setShowCc(true);
      }
    }, [ccRecipients, setShowCc]);

    useEffect(() => {
      if (bccRecipients?.length) {
        setShowBcc(true);
      }
    }, [bccRecipients, setShowBcc]);

    return (
      <>
        <Flex my="2" gap="2" direction="column" align="start">
          <TriggerExplanation assignedAction={assignedAction} />
          {showDatePicker ? (
            <DatePickerField
              required
              minDate={moment().subtract(1, "days").toDate()}
              control={control}
              {...TRIGGER_DATE_TIME_FIELD}
              errors={errors}
            />
          ) : (
            <ButtonWithConfirm
              {...CUSTOM_DATE_TIME_BUTTON_PROPS}
              intent="confirmation"
              handleConfirm={() => setShowDatePicker(true)}
            />
          )}
        </Flex>
        {!(showAdditionalRecipients && showCc && showBcc) && (
          <ButtonGroup colorScheme="gray" variant="outline" isAttached mb={4}>
            {!showAdditionalRecipients && (
              <Button onClick={() => setShowAdditionalRecipients((s) => !s)}>+ Recipients</Button>
            )}
            {!showCc && <Button onClick={() => setShowCc((s) => !s)}>Cc</Button>}
            {!showBcc && <Button onClick={() => setShowBcc((s) => !s)}>Bcc</Button>}
          </ButtonGroup>
        )}
        {showAdditionalRecipients && (
          <RecipientsContainer label="Additional Recipients" name="additionalRecipients" control={control} />
        )}
        {showCc && <RecipientsContainer label="Cc" name="ccRecipients" control={control} />}
        {showBcc && <RecipientsContainer label="Bcc" name="bccRecipients" control={control} />}
        <>
          <TextInputField {...SUBJECT_FIELD} errors={errors} register={register} />
          <ActionRichTextAreaField
            actionType="email"
            targetType={assignedAction?.workflowAction?.actionable?.targetType}
            {...BODY_FIELD}
            errors={errors}
            control={control}
          />
        </>
      </>
    );
  },
);

export default UpdateEmailForm;

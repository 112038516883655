import { ButtonGroup, Flex, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useState } from "react";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import Alert from "components/Alert/Alert";
import { AlertState } from "models/alert";
import { upperFirst } from "lodash";
import { TARGET_TYPE } from "definitions/constants";
import { MessageServiceChannel } from "models/integration/messageService";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";

const MESSAGE_RECIPIENT_FIELD = {
  label: "To",
  name: "targetId",
};

const MESSAGE_FIELD = {
  label: "Message",
  name: "message",
};

interface RailsError {
  errors: string[];
  status: number;
}

const resourceSchema = yupObject({
  targetId: yupString().label(MESSAGE_RECIPIENT_FIELD.label).required(),
  targetType: yupString().label(MESSAGE_RECIPIENT_FIELD.label).required(),
  message: yupString().label(MESSAGE_FIELD.label).required(),
});

interface SendMessageFormProps {
  draftMessage?: string;
  targetId: string;
  targetType: string;
  onClose: () => void;
  onSuccess?: () => void;
}

export default function SendMessageForm({
  draftMessage,
  targetType,
  targetId,
  onClose,
  onSuccess,
}: SendMessageFormProps) {
  const [alertState, setAlertState] = useState<AlertState>({ isOpen: false });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(resourceSchema),
    defaultValues: { targetId, targetType, message: draftMessage },
  });
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });

  const onCloseAlert = () => {
    setAlertState({ isOpen: false, alertBody: "", alertTitle: "" });
  };

  // this is a workaround to bust the cache of react select when
  // a new user is added to the backend for the Select User
  const [cacheKey] = useState("");

  /* HandleOnSubmit */
  const onSubmit = async (data: any) => {
    try {
      await apiClient.post<{ id: string }>(API_ROUTES.messageServices.messages.base, { message: { ...data } });
      toast({
        title: "Message successfully sent.",
      });
      if (onSuccess) {
        onSuccess();
      }
      onClose();
      // log the newly created item to the console
    } catch (error) {
      console.error(error);
      if ((error as RailsError).errors && (error as RailsError).errors[0] && (error as RailsError).status === 422) {
        setAlertState({ isOpen: true, alertBody: upperFirst((error as RailsError).errors[0]), alertTitle: "Error" });
      } else {
        setAlertState({ isOpen: true, alertBody: "There was an error, please try again.", alertTitle: "Error" });
      }
    }
  };

  return (
    <>
      <Flex direction="column" gap="2">
        {targetType === TARGET_TYPE.MESSAGE_SERVICE_CHANNEL ? (
          <GenericAsyncSelectField
            fetchPath={API_ROUTES.messageServices.channels.base}
            extractValue={(v: MessageServiceChannel) => v.id}
            embedOption={(v) => ({
              value: v.id,
              label: `#${v.name}`,
              rawValue: v,
            })}
            control={control}
            {...MESSAGE_RECIPIENT_FIELD}
            errors={errors}
          />
        ) : (
          <UserSelectField
            {...MESSAGE_RECIPIENT_FIELD}
            includeCreateNewUser
            cacheKey={cacheKey}
            control={control}
            errors={errors}
          />
        )}
        <ActionRichTextAreaField
          {...MESSAGE_FIELD}
          actionType="chat"
          targetType="specific_user"
          errors={errors}
          control={control}
        />
        <ButtonGroup display="flex" justifyContent="flex-end">
          <ProceedCancelButtons handleSubmit={handleSubmit(onSubmit)} onClose={onClose} proceedText="Send" />
        </ButtonGroup>
      </Flex>
      <Alert
        title={alertState.alertTitle}
        body={alertState.alertBody}
        buttonTitle="Ok"
        isOpen={alertState.isOpen}
        onClose={onCloseAlert}
      />
    </>
  );
}

SendMessageForm.defaultProps = {
  draftMessage: "",
  onSuccess: () => null,
};

/* eslint-disable implicit-arrow-linebreak */
import { Flex, HStack, Stack, useToast } from "@chakra-ui/react";
import * as yup from "yup";
import { QUERY_KEYS } from "definitions/constants";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useEffect, useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { generatePath } from "react-router-dom";
import { Company } from "models/company";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";

export const COMPANY_EMAIL_DEFAULT_FROM = {
  label: "Email 'From Name' (example 'Camino Onboarding')",
  name: "customEmailDefaultFrom",
};
export const COMPANY_EMAIL_DEFAULT_REPLY_TO = {
  label: "Email 'Reply To'",
  name: "customEmailDefaultReplyTo",
};

export interface CompanyUpdateFormValues {
  customEmailDefaultFrom?: string;
  customEmailDefaultReplyTo?: string;
}

export const newHireSchema = yup.object({
  customEmailDefaultFrom: yup.string().label(COMPANY_EMAIL_DEFAULT_FROM.label).notRequired(),
  customEmailDefaultReplyTo: yup.string().label(COMPANY_EMAIL_DEFAULT_REPLY_TO.label).notRequired(),
});

const values = (company: Company | undefined) => ({
  customEmailDefaultFrom: company?.customEmailDefaultFrom || null,
  customEmailDefaultReplyTo: company?.customEmailDefaultReplyTo || null,
});

interface EmailSettingsFormProps {
  company: Company;
  isLoading: boolean;
  onClose: () => void;
}

export function EmailSettingsForm({ company, isLoading, onClose }: EmailSettingsFormProps) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const defaultValues = useMemo(() => values(company), [company]);

  const {
    formState: { errors, dirtyFields, isDirty },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(newHireSchema),
    defaultValues,
  });

  useEffect(() => {
    setFocus(COMPANY_EMAIL_DEFAULT_FROM.name as keyof CompanyUpdateFormValues);
  }, [setFocus]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  /* HandleOnSubmit */
  const { mutate: onSubmit, isLoading: submitIsLoading } = useMutation({
    mutationFn: (data: CompanyUpdateFormValues) => {
      const updateData = Object.fromEntries(
        Object.entries(data).filter(([key]) => dirtyFields[key as keyof CompanyUpdateFormValues] === true),
      );
      return apiClient.put<Company>(generatePath(API_ROUTES.companies.show, { id: "current" }), {
        company: updateData,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries([QUERY_KEYS.companies, "current"]);
      toast({
        title: "Success!",
      });
    },
    onError: (mutateError) => {
      console.log("userCreateError", mutateError);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });

  return (
    <Flex direction="column">
      <Stack>
        <TextInputField {...COMPANY_EMAIL_DEFAULT_FROM} register={register} errors={errors} disabled={isLoading} />
        <TextInputField {...COMPANY_EMAIL_DEFAULT_REPLY_TO} register={register} errors={errors} disabled={isLoading} />
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText="Update"
          isLoading={submitIsLoading}
          proceedDisabled={!isDirty}
        />
      </HStack>
    </Flex>
  );
}

/* eslint-disable import/prefer-default-export */

import { Text, TextProps } from "@chakra-ui/react";
import ReceiverLinkOrTBD from "components/Table/CellDisplayHelpers";
import { EMOJI_ICONS } from "definitions/constants/icons";
import { findTaskNotifier } from "helpers/task";
import { actionTargetText } from "helpers/workflowActionableConverters";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { Task } from "models/joinedModels";

interface TaskDisplayProps extends TextProps {
  task: Task;
}

export function TaskTargetText({ task, linkProps, ...rest }: TaskDisplayProps & { linkProps?: object }) {
  const { workflowAction, taskNotifier } = task;
  const additionalText = actionTargetText(
    workflowAction || (taskNotifier as AssignedAction)?.workflowAction || findTaskNotifier(task),
  );
  return (
    <Text fontSize="sm" fontWeight="medium" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.target} `}
      <ReceiverLinkOrTBD receiver={task?.assignee} {...linkProps} />
      {additionalText && ` (${additionalText})`}
    </Text>
  );
}

TaskTargetText.defaultProps = {
  linkProps: {},
};

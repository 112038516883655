import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
  Text,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import UpdateTaskForm from "components/Forms/AssignedActions/Task/UpdateTaskForm";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useSendNowAssignedAction from "features/AssignedAction/hooks/useSendNowAssignedAction";
import { findTaskNotifier } from "helpers/task";
import { Task } from "models/joinedModels";
import { useMemo, useRef, useState } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this task now!",
  body: "This cannot be undone! Be sure you want to send this task now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface TaskSideDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  taskId: string;
}

export default function TaskSideDrawer({ isOpen, onClose, taskId }: TaskSideDrawerProps) {
  const formRef = useRef<any>(null); // Create a ref to access form methods
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { data: task, isLoading: taskIsLoading } = useQuery({
    queryKey: [QUERY_KEYS.tasks, taskId],
    queryFn: async ({ signal }) => apiClient.get<Task>(generatePath(API_ROUTES.tasks.show, { id: taskId || "" }), signal),
    enabled: !!taskId && taskId.length > 0,
  });

  const initialNotifier = useMemo(() => findTaskNotifier(task as Task), [task]);

  const handleSubmit = (onSuccessCallback: () => void) => {
    formRef.current?.handleSubmit(onSuccessCallback); // Call form submit from the ref
  };

  const { sendNow, isReady } = useSendNowAssignedAction({
    assignedAction: initialNotifier,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.tasks, task?.id]);
      queryClient.invalidateQueries([QUERY_KEYS.assignedPaths, initialNotifier?.scheduledWorkflowId]);
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourney, task?.newHireJourneyId]);
    },
  });

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="4" fontSize="xl" font-Weight="semibold" borderBottomWidth="2px">
          Task
        </DrawerHeader>

        <DrawerBody>
          {taskIsLoading && <Text>Loading...</Text>}
          {!taskIsLoading && task && (
            <UpdateTaskForm
              ref={formRef}
              task={task}
              onSuccess={onClose}
              setIsSubmitLoading={setIsSubmitLoading}
              setIsFormDirty={setIsFormDirty}
            />
          )}
        </DrawerBody>

        <DrawerFooter borderTopWidth="2px">
          <ButtonGroup display="flex" justifyContent="flex-end">
            <ButtonWithConfirm
              intent="warning"
              isDisabled={!isReady}
              {...CONFIRM_SEND_NOW}
              handleConfirm={() => handleSubmit(() => sendNow())}
            />
            <ProceedCancelButtons
              handleSubmit={() => handleSubmit(() => {})}
              onClose={onClose}
              proceedText="Update Task"
              proceedDisabled={!isFormDirty}
              isLoading={isSubmitLoading}
            />
          </ButtonGroup>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

import { useToast } from "@chakra-ui/react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { NewHireFormValues } from "features/NewHireJourney/NewHireForm/definitions";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { User } from "models/user";
import { useCallback } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { StepState } from "components/Steps";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { pick } from "lodash";
import { dirtyValues } from "helpers/form";

type UserKeys = keyof User;

export const USER_FIELDS: UserKeys[] = [
  "firstName",
  "lastName",
  "preferredName",
  "title",
  "workEmail",
  "personalEmail",
  "pronouns",
  "timezone",
  "location",
  "country",
  "department",
  "bio",
  "linkedinUrl",
  "managerId",
  "phoneCountryCode",
  "phoneNumber",
  "employmentType",
];

type NewHireJourneyKeys = keyof ExtendedNewHireJourney | "pending";

export const NEW_HIRE_JOURNEY_FIELDS: NewHireJourneyKeys[] = [
  "buddyId",
  "peopleTeamContactId",
  "startDate",
  "onboardingPlanUrl",
  "teamWikiUrl",
  "teamGoalsUrl",
  "cohortId",
  "pending",
  "shippingTrackingNumber",
  "shippingTrackingUrl",
  "shippingCarrier",
  "internalOnboardingChecklistUrl",
  "hrisOnboardingUrl",
];

interface UseSubmitNewHire {
  newHireJourneyId: string;
  onSuccess?: () => void;
}

interface HandleOnSubmit {
  callback: (state: StepState) => void;
  form: UseFormReturn<FieldValues>;
}

export default function useSubmitNewHire({ newHireJourneyId, onSuccess }: UseSubmitNewHire) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate: onSubmit, isLoading } = useMutation({
    mutationFn: (data: NewHireFormValues) => {
      const user = pick(data, USER_FIELDS);
      const onboardingJourney = pick(data, NEW_HIRE_JOURNEY_FIELDS);
      const workflows = data?.workflowIds ? { workflowIds: data.workflowIds } : {};
      return apiClient.put<User>(generatePath(API_ROUTES.newHireJourneys.show, { id: newHireJourneyId || "" }), {
        ...(Object.keys(user).length ? { user } : {}),
        ...(Object.keys(onboardingJourney).length ? { onboardingJourney } : {}),
        ...(Object.keys(workflows).length ? { workflows } : {}),
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourneys, newHireJourneyId]);
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourneysPending, newHireJourneyId]);
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourneysPendingCount]);
      onSuccess?.();
      return res;
    },
    onError: (mutateError) => {
      console.log("New Hire Update Error", mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  const handleOnSubmit = useCallback(
    ({ callback, form }: HandleOnSubmit) => {
      const {
        handleSubmit,
        formState: { dirtyFields },
        reset,
      } = form;
      handleSubmit((data) => {
        // handleSubmit built in - if the form is invalid, this function will not be called.
        // Because of this, if we pull "updateData" and its an empty object (no dirty fields),
        // we can just move to the next step
        callback(StepState.loading);
        const updateData = dirtyValues(data, dirtyFields);
        if (Object.keys(updateData).length === 0) {
          callback(StepState.complete);
          return;
        }
        onSubmit(updateData, {
          onSuccess: () => {
            reset({}, { keepValues: true });
            callback(StepState.complete);
          },
          onError: (error) => {
            console.log("error", error);
            callback(StepState.initial);
          },
        });
      })();
    },
    [onSubmit],
  );

  return { handleOnSubmit, isLoading };
}

import { generatePath, useNavigate, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { QUERY_KEYS } from "definitions/constants";
import { useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import NewHirePendingForm from "features/PendingNewHire/Form";
import { Flex } from "@chakra-ui/react";

export default function PendingNewHireJourneyPage() {
  const { id } = useParams<{ id: string }>();
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const navigate = useNavigate();

  const { data: newHireJourney, isLoading } = useQuery<ExtendedNewHireJourney>({
    queryKey: [QUERY_KEYS.newHireJourneys, id],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.newHireJourneys.show, { id })),
  });

  useEffect(() => {
    if (newHireJourney && !isLoading) {
      if (newHireJourney.isPending === false) {
        navigate(generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id }));
      }
      setPageTitle(`New Hire ${newHireJourney?.user?.fullName}`);
      setBreadcrumbs([
        {
          name: "Pending New Hires",
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.pending.index),
          isCurrentPage: false,
        },
        {
          name: newHireJourney?.user?.firstName,
          isCurrentPage: true,
        },
      ]);
    }
  }, [newHireJourney, newHireJourney?.isPending, isLoading, setBreadcrumbs, setPageTitle, navigate, id]);

  return (
    <AppPage>
      <Flex justify="center" width="100%">
        <NewHirePendingForm newHireJourney={newHireJourney} />
      </Flex>
    </AppPage>
  );
}

import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb, BreadcrumbItem, BreadcrumbLink, CardProps, Skeleton,
} from "@chakra-ui/react";
import { SHARED_ROUTES } from "definitions/constants/routeConstants";
import { Link as RouterLink, generatePath } from "react-router-dom";

export interface BreadcrumbInfo {
  name?: string;
  isCurrentPage?: boolean;
}

export interface BreadCrumbNotCurrentPage extends BreadcrumbInfo {
  isCurrentPage: false | undefined;
  toUrl: string;
}

interface BreadcrumbsProps extends CardProps {
  breadcrumbInfos: Array<BreadcrumbInfo | BreadCrumbNotCurrentPage>;
  isLoading?: boolean;
  fontColor?: string;
  fontWeight?: string;
  includeHome?: boolean;
}

export default function CaminoBreadcrumbs({
  breadcrumbInfos,
  isLoading,
  fontColor,
  fontWeight,
  includeHome,
  ...rest
}: BreadcrumbsProps) {
  return (
    <Skeleton isLoaded={!isLoading}>
      <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />} {...rest}>
        {includeHome && (
          <BreadcrumbItem>
            <BreadcrumbLink
              color={fontColor}
              {...(fontWeight ? { fontWeight } : {})}
              as={RouterLink}
              to={generatePath(SHARED_ROUTES.home)}
            >
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
        {breadcrumbInfos.map((breadcrumInfo, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <BreadcrumbItem key={`${breadcrumInfo.name}${index}`} isCurrentPage={breadcrumInfo.isCurrentPage}>
            {breadcrumInfo.isCurrentPage ? (
              <BreadcrumbLink color={fontColor} {...(fontWeight ? { fontWeight } : {})}>
                {breadcrumInfo.name}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbLink
                color={fontColor}
                as={RouterLink}
                to={(breadcrumInfo as BreadCrumbNotCurrentPage).toUrl}
                {...(fontWeight ? { fontWeight } : {})}
              >
                {breadcrumInfo.name}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Skeleton>
  );
}

CaminoBreadcrumbs.defaultProps = {
  isLoading: false,
  fontColor: "fg.muted",
  fontWeight: null,
  includeHome: false,
};

import { useToast } from "@chakra-ui/react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import apiClient from "services/ApiClient";
import { apiTaskPathById } from "helpers/url";
import { Task } from "models/joinedModels";

interface UseDeleteTask {
  taskId: string;
  onSuccess?: () => void;
}

export default function useDeleteTask({ taskId, onSuccess }: UseDeleteTask) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate: onSubmit, isLoading } = useMutation({
    mutationFn: () => apiClient.delete<Task>(apiTaskPathById(taskId)),
    onSuccess: (res) => {
      queryClient.invalidateQueries([QUERY_KEYS.assignedActions, taskId]);
      toast({
        title: "Action deleted!",
      });
      onSuccess?.();
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Delete Error", mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return { onSubmit, isLoading };
}

import {
  Box,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiBell } from "react-icons/fi";
import useNotificationStore, { Notification } from "hooks/useNotificationsStore";
import NotificationBadge from "./NotificationBadge";

function NotificationButton({ handleClick }: { handleClick: (notificaton: Notification) => void }) {
  const [notifications, unreadCount, markAsRead] = useNotificationStore((state) => [
    state.notifications,
    state.unreadCount(),
    state.markAsRead,
  ]);

  return (
    <Popover placement="top-end">
      <Box>
        <PopoverTrigger>
          <IconButton
            variant="tertiary"
            top={unreadCount ? "0.625rem" : undefined}
            icon={<FiBell fontSize="1.25rem" />}
            aria-label="Open Notifications"
          />
        </PopoverTrigger>
        {unreadCount > 0 && <NotificationBadge count={unreadCount} />}
      </Box>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Notifications</PopoverHeader>
        <PopoverBody>
          <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
            {notifications.map((notification) => (
              <Box
                as="button"
                key={notification.id}
                bg={notification.readAt ? "bg" : "bg.subtle"}
                onClick={() => {
                  markAsRead(notification);
                  handleClick(notification);
                }}
                borderRadius={4}
                p={4}
              >
                <VStack>
                  <Text fontSize="sm" color="gray.400" fontWeight="semibold">
                    {notification.title}
                  </Text>
                  <Text>{notification.message}</Text>
                </VStack>
              </Box>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default NotificationButton;

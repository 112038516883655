/* eslint-disable import/prefer-default-export */

import { BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";

export function sortAssignedActionsByTriggerDate(
  assignedActions: AssignedAction[],
  direction: "asc" | "desc" = "asc",
): AssignedAction[] {
  return assignedActions.sort((a, b) => {
    const dateA = a?.trigger?.data?.datetime;
    const dateB = b?.trigger?.data?.datetime;

    // For ascending order: place undefined/null dates at the end
    if (direction === "asc") {
      if (dateA == null && dateB == null) return 0; // Both are null/undefined, keep original order
      if (dateA == null) return 1; // Move a to the end
      if (dateB == null) return -1; // Move b to the end
    }

    // For descending order: place undefined/null dates at the beginning
    if (direction === "desc") {
      if (dateA == null && dateB == null) return 0; // Both are null/undefined, keep original order
      if (dateA == null) return -1; // Move a to the beginning
      if (dateB == null) return 1; // Move b to the beginning
    }

    // Determine the sort order based on the direction
    const comparison = dateA - dateB;
    return direction === "asc" ? comparison : -comparison;
  });
}

export function isImmediateAssignedAction(assignedAction: AssignedAction | BaseAssignedAction | undefined): boolean {
  return (
    assignedAction?.workflowAction?.actionable?.trigger?.data?.referenceEvent === "immediate"
    && assignedAction?.workflowAction?.actionable?.trigger?.data?.offset === 0
    && assignedAction?.trigger?.data?.customized === false
  );
}

import { useToast, HStack, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { useEffect, useState } from "react";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useQueryClient } from "@tanstack/react-query";
import { generatePath } from "react-router-dom";
import { QUERY_KEYS } from "definitions/constants";
import { Company } from "models/company";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import TimezoneSelectField from "components/FormElements/Fields/SelectFields/TimezoneSelectField";
import HourSelectField from "components/FormElements/Fields/SelectFields/HoursSelectField";
import { DEFAULT_FIELD_GAP } from "definitions/constants/styling";
import {
  DEFAULT_TIMEZONE_FIELD,
  DEFAULT_TRIGGER_TIME_OF_DAY_FIELD,
  Schema,
  schema,
  SUGGESTED_EMAIL_FORMAT_FIELD,
} from "./definitions";

function Form({
  onSubmit,
  onClose,
  proceedText,
  defaultValues,
  isLoading,
}: {
  onSubmit: (data: Schema) => void;
  onClose: () => void;
  proceedText: string;
  defaultValues: Partial<Schema>;
  isLoading: boolean;
}) {
  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
    register,
    reset,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <Stack gap={DEFAULT_FIELD_GAP} alignItems="start">
      <TextInputField register={register} {...SUGGESTED_EMAIL_FORMAT_FIELD} errors={errors} />
      <TimezoneSelectField control={control} {...DEFAULT_TIMEZONE_FIELD} errors={errors} />
      <HourSelectField control={control} isClearable={false} {...DEFAULT_TRIGGER_TIME_OF_DAY_FIELD} errors={errors} />

      <HStack my={8} alignSelf="end" justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText={proceedText}
          proceedDisabled={!isDirty}
          isLoading={isLoading}
        />
      </HStack>
    </Stack>
  );
}

interface ValuesFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  company: Company | null | undefined;
  onClose?: () => void;
}

const values = (company: Company | null | undefined) => {
  if (!company) return {};
  const { defaultTimezone, defaultTriggerTimeOfDay, suggestedEmailFormat } = company;
  return { defaultTimezone, defaultTriggerTimeOfDay, suggestedEmailFormat };
};

export default function CompanyDefaultsForm({ company, onClose }: ValuesFormProps) {
  const defaultValues: Partial<Schema> = values(company);
  const toast = useToast({ duration: 9000, isClosable: true, position: "top" });
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: Schema) => {
    try {
      setIsLoading(true);
      const res = await apiClient.put<Company>(generatePath(API_ROUTES.companies.show, { id: company?.id }), data);
      setIsLoading(false);
      queryClient.invalidateQueries([QUERY_KEYS.companies, "current"]);
      console.log(res);
      toast({
        title: "Company Info Updated.",
        status: "success",
      });
      onClose?.();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    }
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      onClose={() => onClose?.()}
      proceedText="Update Company Info"
      isLoading={isLoading}
    />
  );
}

CompanyDefaultsForm.defaultProps = {
  onClose: () => {},
};

/* eslint-disable no-nested-ternary */
import {
  Box, Card, CardBody, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs,
} from "@chakra-ui/react";
import { generatePath, useParams, useSearchParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import { NetworkingError, isNetworkingError } from "services/ApiClient/networkingError";
import apiClient from "services/ApiClient";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { useEffect, useState } from "react";
import UnfoundResource from "components/EmptyComponents/UnfoundResource";
import PathInfoCard from "features/Path/PathInfoCard";
import { Path } from "models/automation";
import UnknownError from "components/EmptyComponents/UnknownError";
import { CreatePathForm } from "components/Forms/PathForm";
import ScheduledWorkflowsTable from "components/Table/ScheduledWorkflowsTable";
import { QUERY_KEYS } from "definitions/constants";
import { useNavbar } from "hooks/useNavbar";
import { RandomEmptyActionState } from "components/EmptyComponents/RandomEmptyState";
import { getPathName } from "helpers/path";
import ActionCardTable from "features/Action/ActionCardTable";

const TAB_QUERY_PARAM = "t";

export default function WorkflowPage() {
  const { id } = useParams<{ id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isNotFound, setIsNotFound] = useState(false);
  const tabIndex = Number(searchParams.get(TAB_QUERY_PARAM)) ?? 0;
  const setTabIndex = (index: number) => setSearchParams(
    (params) => {
      params.set(TAB_QUERY_PARAM, String(index));
      return params;
    },
    { replace: true },
  );
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  // TODO: move this into own component, so we don't fetch it when we don't need it (assigned new hires)
  const {
    data,
    isLoading,
    error: queryError,
  } = useQuery({
    queryKey: [QUERY_KEYS.paths, id],
    queryFn: async ({ signal }) => apiClient.get<Path>(generatePath(API_ROUTES.paths.show, { id }), signal),
    retry: (failureCount, error) => {
      if (isNetworkingError(error) && error.status === 404) {
        return failureCount < 1;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: !isNotFound,
    enabled: id !== "new",
    networkMode: "always",
  });

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbs([
        {
          name: "Paths",
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.paths.index),
          isCurrentPage: false,
        },
        {
          name: getPathName(data),
          isCurrentPage: true,
        },
      ]);
      setPageTitle(getPathName(data));
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  useEffect(() => {
    // to stop automation refetches
    if ((queryError as NetworkingError)?.status === 404) {
      setIsNotFound(true);
    }
  }, [queryError]);

  return (
    <AppPage>
      {queryError ? (
        (queryError as NetworkingError)?.status === 404 ? (
          <UnfoundResource resourceId={id} resourceName="Path" />
        ) : (
          <UnknownError resourceId={id} resourceName="Path" />
        )
      ) : id === "new" ? (
        <CreatePathForm onClose={() => {}} />
      ) : (
        <Box>
          {data && <PathInfoCard isLoading={isLoading} workflow={data} />}
          <Card mt="8">
            <CardBody>
              <Tabs
                defaultIndex={tabIndex}
                onChange={setTabIndex}
                size="lg"
                variant="underline"
                position="relative"
                isLazy
              >
                <TabList>
                  <Tab>Actions</Tab>
                  <Tab>Assigned New Hires</Tab>
                </TabList>
                <TabIndicator />
                <TabPanels>
                  <TabPanel>
                    {data && (
                      <ActionCardTable
                        emptyStateComponent={<RandomEmptyActionState />}
                        sx={{ pb: "4", mt: "4" }}
                        data={data.actions}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {data?.id && (
                      <ScheduledWorkflowsTable
                        filters={[{ fieldName: "workflowId", value: data?.id, operator: "=" }]}
                        sx={{ pb: "4", mt: "4" }}
                      />
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </CardBody>
          </Card>
        </Box>
      )}
    </AppPage>
  );
}

import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Image,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import Logo from "assets/camino_logo_transparent.svg";
import apiClient from "services/ApiClient";
import React, { useState, useMemo } from "react";
import { API_ROUTES } from "definitions/constants/routeConstants";
import Alert from "../Alert/Alert";

const TOP_CALL_TO_ACTION = "Have an account?";
const TOP_CALL_TO_ACTION2 = "Log in here";

const SUCCESS_ALERT_STATE = {
  title: "Check Your Email",
  body: "We've sent you a link to sign in. If you don't see it, check your spam folder.",
  buttonTitle: "Got it!",
};

const ERROR_ALERT_STATE = (message: string) => ({
  title: "Error",
  body: (
    <>
      <Text mb="2">Please ensure you have entered a valid email address.</Text>
      <Text>If the problem persists, please reach out to the Camino Team at support@joincamino.com.</Text>
    </>
  ),
  buttonTitle: "OK",
});

export default function SignInForm(props: StackProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [email, setEmail] = useState<string>();
  const [alertState, setAlertState] = useState(SUCCESS_ALERT_STATE);
  const [alertCloseShouldReload, setAlertCloseShouldReload] = useState(false);

  const signIn = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    apiClient
      .post(API_ROUTES.auth.start, { email })
      .then(() => {
        setAlertState(SUCCESS_ALERT_STATE);
      })
      .catch((error) => {
        if (error.status === 422) setAlertCloseShouldReload(true);
        setAlertState(ERROR_ALERT_STATE(error?.errors?.[0] ?? "Something went wrong. Please try again."));
      })
      .finally(() => onOpen());
  };

  const handleAlertClose = useMemo(
    () => () => {
      onClose();
      if (alertCloseShouldReload) window.location.reload();
    },
    [onClose, alertCloseShouldReload],
  );

  return (
    <>
      <Stack spacing="8" {...props}>
        <Stack spacing="6">
          {isMobile && <Image mx={{ base: 6 }} maxWidth="160px" src={Logo} />}
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "sm" }}>{TOP_CALL_TO_ACTION}</Heading>
            <Heading size={{ base: "xs", md: "sm" }}>{TOP_CALL_TO_ACTION2}</Heading>
          </Stack>
        </Stack>
        <Stack as="form" onSubmit={signIn} spacing="6">
          <Stack spacing="5">
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                placeholder="Enter your email"
                type="email"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
          </Stack>
          <Stack spacing="4">
            <Button type="submit" variant="primary">
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Alert {...alertState} isOpen={isOpen} onClose={handleAlertClose} />
    </>
  );
}

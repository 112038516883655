/* eslint-disable no-nested-ternary */
import { Box, Card, CardBody } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import UnfoundResource from "components/EmptyComponents/UnfoundResource";
import UnknownError from "components/EmptyComponents/UnknownError";
import PageTitle from "components/PageTitle";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { createFiltersQueryString } from "helpers/queryString";
import TaskSection from "layouts/Task";
import { NewHireJourney } from "models/onboarding";
import { BaseTask } from "models/task";
import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { NetworkingError, isNetworkingError } from "services/ApiClient/networkingError";

interface TaskTabProps {
  newHireJourney: NewHireJourney;
}

export default function TaskTab({ newHireJourney }: TaskTabProps) {
  const [isNotFound, setIsNotFound] = useState(false);
  const {
    data,
    isLoading,
    error: queryError,
  } = useQuery({
    queryKey: [QUERY_KEYS.tasks],
    queryFn: async ({ signal }) => apiClient.get<BaseTask[]>(
      generatePath(
        `${API_ROUTES.tasks.base}?${createFiltersQueryString([
          { fieldName: "onboardingJourneyId", value: newHireJourney?.id as string, operator: "=" },
        ])}`,
      ),
      signal,
    ),
    retry: (failureCount, error) => {
      if (isNetworkingError(error) && error.status === 404) {
        return failureCount < 1;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: !isNotFound,
    // enabled: !!newHireJourney?.id,
    networkMode: "always",
  });

  useEffect(() => {
    // to stop automation refetches
    if ((queryError as NetworkingError)?.status === 404) {
      setIsNotFound(true);
    }
  }, [queryError]);

  return (
    <Box>
      {queryError ? (
        (queryError as NetworkingError)?.status === 404 ? (
          <UnfoundResource resourceId={newHireJourney?.id} resourceName="Tasks by OnboardingPlanId" />
        ) : (
          <UnknownError resourceId={newHireJourney?.id} resourceName="Tasks by OnboardingPlanId" />
        )
      ) : (
        <>
          <PageTitle text="Your Tasks" isLoaded={!isLoading} />
          <Box>
            <Card mt="8">
              <CardBody>
                <TaskSection startDate={newHireJourney?.startDate} tasks={data} />
              </CardBody>
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
}

import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";
import {
  Children, cloneElement, isValidElement, ReactElement, useMemo,
} from "react";
import { IconType } from "react-icons";

interface SegmentedControlGroupProps<T> extends Omit<ButtonGroupProps, "onChange" | "variant" | "isAttached"> {
  name: string;
  value: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
}

export function SegmentedControlGroup<T extends string>(props: SegmentedControlGroupProps<T>) {
  const {
    children, name, defaultValue, value, onChange, isDisabled, ...rest
  } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const buttons = useMemo(
    () => Children.toArray(children)
      .filter<ReactElement<SegmentedControlButtonProps>>(isValidElement)
      .map((button, index, array) => {
        const isFirstItem = index === 0;
        const isLastItem = array.length === index + 1;

        const styleProps = {
          ...(isFirstItem && !isLastItem ? { borderRightRadius: 0 } : {}),
          ...(!isFirstItem && isLastItem ? { borderLeftRadius: 0 } : {}),
          ...(!isFirstItem && !isLastItem ? { borderRadius: 0 } : {}),
          ...(!isLastItem ? { mr: "-px" } : {}),
        };

        return cloneElement(button, {
          ...styleProps,
          radioProps: getRadioProps({
            value: button.props.value,
            disabled: isDisabled || button.props.isDisabled,
          }),
        });
      }),
    [children, getRadioProps, isDisabled],
  );
  return (
    <ButtonGroup isAttached variant="secondary" {...getRootProps(rest)}>
      {buttons}
    </ButtonGroup>
  );
}

SegmentedControlGroup.defaultProps = {
  onChange: () => {},
  defaultValue: null,
};

interface SegmentedControlButtonProps extends ButtonProps {
  value: string;
  icon?: IconType;
  radioProps?: UseRadioProps;
}

export function SegmentedControlButton(props: SegmentedControlButtonProps) {
  const { radioProps, icon: Icon, ...rest } = props;
  const { getInputProps, getRadioProps, getLabelProps } = useRadio(radioProps);
  const id = useId(undefined, "radio-button");

  const inputProps = getInputProps();
  const customRadioProps = getRadioProps();
  const labelProps = getLabelProps();

  return (
    <Box
      as="label"
      cursor="pointer"
      {...labelProps}
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}
    >
      <input {...inputProps} aria-labelledby={id} />
      <Button
        id={id}
        as="div"
        _focus={{ boxShadow: "none" }}
        {...(Icon ? { leftIcon: <Icon /> } : {})}
        {...customRadioProps}
        {...rest}
      />
    </Box>
  );
}

SegmentedControlButton.defaultProps = {
  radioProps: {},
  icon: null,
};

// eslint-disable-next-line object-curly-newline
import { Box, HStack, Heading, Text } from "@chakra-ui/react";
import { VerticalWelcomeMessageProfileCard } from "components/Card/WelcomeProfileCard";
import { User } from "models/user";
import { WelcomeMessage } from "models/welcomeFlow";

interface LeadershipWelcomeProps {
  user: User | null;
  messages: WelcomeMessage[];
}

export default function LeadershipWelcome({ user, messages }: LeadershipWelcomeProps) {
  // const { firstName } = user || { firstname: null };
  const firstName = "Zion";
  const companyName = "Vandalay Industries";
  return (
    <Box pt="12" px="32" pb="32">
      <Heading as="h3" fontWeight="400" mb="32px">
        A few words from your support crew
      </Heading>
      <HStack justifyContent="center" gap="10" mt="10">
        {messages.map((message) => (
          <VerticalWelcomeMessageProfileCard key={message.id} mt={3} message={message} />
        ))}
      </HStack>
    </Box>
  );
}

import AppPage from "layouts/AppPage";
import {
  Card, CardBody, Flex, Icon, Stack, Text,
} from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import { useCallback, useEffect, useState } from "react";
import TasksTable from "components/Table/TasksTable";
import { CheckboxCard, CheckboxCardGroup } from "components/FormElements/CheckboxCardGroup";
import { useNavbar } from "hooks/useNavbar";
import useCaminoStore from "hooks/useCaminoStore";
import { useSearchParams } from "react-router-dom";
import NewHireJourneySelect from "components/Form/Select/NewHireJourneySingleSelect";
import { NewHireJourney } from "models/onboarding";
import { pullUniqueNewHireJourneysFromTasks } from "helpers/newHireJourney";
import { BaseTask } from "models/task";
import { Task } from "models/joinedModels";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { MdOutlineRedo } from "react-icons/md";

export default function TasksPage() {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const [searchText, setSearchText] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const [initialJourneys, setInitialJourneys] = useState<NewHireJourney[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const newHireId = searchParams.get("newHireId");

  const updateNewHireId = (id: string) => {
    if (id) {
      searchParams.set("newHireId", id);
    } else {
      searchParams.delete("newHireId");
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setPageTitle("My Tasks");
    setBreadcrumbs([
      {
        name: "My Tasks",
        isCurrentPage: true,
      },
    ]);
  }, [setBreadcrumbs, setPageTitle]);

  const setInitialNewHires = useCallback(
    (data: BaseTask[]) => {
      if ([0, 1].includes(initialJourneys.length) && data && data.length !== 0) {
        setInitialJourneys(pullUniqueNewHireJourneysFromTasks(data as Task[]));
      }
    },
    [initialJourneys.length],
  );

  return (
    <AppPage>
      <Card>
        <CardBody>
          <Stack direction="column" w="100%" alignItems="start" gap="2">
            <SearchBar
              minWidth="500"
              name="tasksSearch"
              onInput={setSearchText}
              border="2px solid var(--chakra-colors-border-default)"
            />
            <Flex align="center" gap="8">
              <NewHireJourneySelect
                newHireJourneys={initialJourneys}
                selectedNewHireJourneyId={newHireId}
                onChange={(e) => updateNewHireId(e as string)}
                placeholder="Select by New Hire"
                isClearable
                useBasicStyles
              />
              <CheckboxCardGroup direction="row" onChange={(value: any) => setSelectedValues(value)}>
                <CheckboxCard value="showCompleted">
                  <Flex align="center" gap="2">
                    <Icon as={AiOutlineCheckSquare} />
                    <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                      Show Completed
                    </Text>
                  </Flex>
                </CheckboxCard>
                <CheckboxCard value="showHidden">
                  <Flex align="center" gap="2">
                    <Icon as={BiTimeFive} />
                    <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                      Show Upcoming
                    </Text>
                  </Flex>
                </CheckboxCard>
                <CheckboxCard value="showSkipped">
                  <Flex align="center" gap="2">
                    <Icon as={MdOutlineRedo} />
                    <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                      Show Skipped
                    </Text>
                  </Flex>
                </CheckboxCard>
              </CheckboxCardGroup>
            </Flex>
          </Stack>
          <TasksTable
            displayNewHireName
            includeCommunications
            filterByUserId={user?.id}
            newHireJourneyId={newHireId as string}
            onDataSuccess={setInitialNewHires}
            searchText={searchText}
            showHidden={selectedValues.includes("showHidden")}
            showCompleted={selectedValues.includes("showCompleted")}
            showSkipped={selectedValues.includes("showSkipped")}
          />
        </CardBody>
      </Card>
    </AppPage>
  );
}

/* eslint-disable object-curly-newline */
import { Box, Card, CardProps, Heading, IconButton, Image, Text, VStack } from "@chakra-ui/react";
import { CompanyValue } from "models/welcomeFlow";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import LoadingCard from "../LoadingCard";
import ButtonWithAlert from "components/Button/ButtonWithConfirm";

interface ValueCardProps extends CardProps {
  value: CompanyValue | null;
  editable?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

export default function ValueCard({ value, editable, onEdit, onDelete, ...rest }: ValueCardProps) {
  if (!value) {
    return <LoadingCard />;
  }

  return (
    <Card
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius="8px"
      border="1px solid var(--stroke-vibes, #F1F1F1)"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      paddingLeft="9"
      paddingRight="25"
      paddingY={12}
      maxW="368"
      {...rest}
    >
      <Box
        position="absolute"
        top="4"
        left="4"
        p="1"
        borderRadius="4px"
        color="gray.400"
        fontWeight="600"
      >
        <Text fontSize={20}>
          #
          {` `}
          {value.order || "1"}
        </Text>
      </Box>
      { editable && onDelete && (
        <Box
          position="absolute"
          top="2"
          right="2"
          p="1"
        >
          <IconButton mr="-2" variant="ghost" onClick={onEdit} aria-label="edit value" size="sm" icon={<MdOutlineEdit />} />
          <ButtonWithAlert
            header="Delete Company Value"
            body="Are you sure you want to delete this Company Value? It can't be undone."
            handleConfirm={onDelete}
            confirmButtonText="Delete"
          >
            <IconButton variant="ghost" onClick={onDelete} aria-label="delete value" size="sm" icon={<MdDeleteOutline />} />
          </ButtonWithAlert>
        </Box>
      )}
      <VStack gap="14px" alignItems="stretch">
        <VStack alignItems="center" gap="0">
          <Image
            src={value?.iconUrl}
            alt={value?.title}
          />
        </VStack>
        <VStack alignItems="start" gap="2">
          <Heading size="xs">
            {value?.title}
          </Heading>
          <Text fontWeight="400" fontSize={20}>
            {value?.description}
          </Text>
        </VStack>
      </VStack>
    </Card>
  );
}

ValueCard.defaultProps = {
  editable: false,
  onEdit: () => null,
  onDelete: () => null,
};

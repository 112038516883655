import { Box, SystemStyleObject, TableProps } from "@chakra-ui/react";

import {
  ColumnDef,
  ColumnHelper,
  DeepKeys,
  SortingFn,
  Table,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useQuery } from "@tanstack/react-query";
import { MetaQuery, ResponseModel } from "services/ApiClient/responseModel";
import useQueryString from "hooks/useQueryString";
import React, { useEffect } from "react";
import BaseCardTable from "./BaseCardTable";

export interface IColumnDefinition<T> {
  attribute: DeepKeys<T>;
  label: string;
  disableSorting?: true;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const simpleColumn = <T,>(
  columnDefinition: IColumnDefinition<T>,
  colHelper: ColumnHelper<T>,
  enableMultiSort: boolean = false,
) => colHelper.accessor(columnDefinition.attribute, {
    header: columnDefinition.label,
    enableSorting: !columnDefinition.disableSorting,
    enableMultiSort,
  });

export interface GenericTableProps<T> extends TableProps {
  cardComponent: React.ComponentType<any>;
  fetchData: (query: string, signal?: AbortSignal) => Promise<ResponseModel<T[]>>;
  queryKey: string[];
  columns: ColumnDef<T, any>[];
  meta?: MetaQuery;
  sx?: SystemStyleObject;
  additionalQueries?: Record<string, string>;
  textWrap?: boolean;
  includeNumberedRow?: boolean;
  includePagination?: boolean;
  emptyStateComponent?: React.ReactNode;
  customSortingObject?: {
    [key: string]: SortingFn<unknown>;
  };
  getCardProps?: () => object;
  getTrProps?: () => object;
  getTdProps?: () => object;
  getContainerProps?: () => object;
  setTable?: (instance: Table<T> | null) => void;
  setDataIsLoading?: (isLoading: boolean) => void;
}

export default function GenericTable<T>({
  cardComponent: CardComponent,
  fetchData,
  queryKey,
  columns,
  meta,
  sx,
  additionalQueries,
  textWrap,
  includeNumberedRow,
  includePagination,
  emptyStateComponent,
  customSortingObject,
  setTable,
  setDataIsLoading,
  getCardProps,
  getTrProps,
  getTdProps,
  getContainerProps,
  ...props
}: GenericTableProps<T>) {
  const {
    queryString, pagination, setPagination, sortBy, setSortBy,
  } = useQueryString(meta, additionalQueries);

  const { data, isLoading, error } = useQuery({
    queryKey: queryKey.concat([queryString]),
    queryFn: async ({ signal }) => fetchData(queryString.length ? `?${queryString}` : "", signal),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setDataIsLoading?.(isLoading);
  }, [isLoading, setDataIsLoading]);

  const pageCount = data?.meta?.pagination?.totalPages ?? 1;

  const table = useReactTable({
    data: data?.data || [],
    columns,
    pageCount,
    state: {
      pagination,
      sorting: sortBy,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSortBy,
    sortingFns: { ...customSortingObject },
    manualPagination: true,
    manualSorting: true,
    enableSortingRemoval: true,
    enableMultiSort: true,
  });

  React.useEffect(() => {
    if (setTable) {
      setTable(table);
    }
  }, [setTable, table]);

  if (error) {
    return <Box>{`An error has occurred ${error.message}`}</Box>;
  }

  return (
    <BaseCardTable
      cardComponent={CardComponent}
      {...{
        table,
        sx,
        isLoading,
        emptyStateComponent,
        getCardProps,
        getTrProps,
        getTdProps,
        getContainerProps,
        meta,
        includePagination,
      }}
      {...props}
    />
  );
}

GenericTable.defaultProps = {
  meta: undefined,
  sx: undefined,
  additionalQueries: undefined,
  textWrap: false,
  includeNumberedRow: false,
  includePagination: true,
  emptyStateComponent: null,
  customSortingObject: {},
  getCardProps: () => ({}),
  getTrProps: () => ({}),
  getTdProps: () => ({}),
  getContainerProps: () => ({}),
  setTable: () => ({}),
  setDataIsLoading: () => ({}),
};

import {
  Box,
  Card,
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalContent,
  ModalBody,
  Skeleton,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  CardBody,
} from "@chakra-ui/react";
import { Company } from "models/company";
import { InfoDisplayValue } from "components/DataDisplay/InfoDisplayValue";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { COMPANY_EMAIL_DEFAULT_FROM, COMPANY_EMAIL_DEFAULT_REPLY_TO, EmailSettingsForm } from "./EmailSettingsForm";

interface EmailSettingsCardProps {
  company?: Company;
  editClickedHandler?: () => void;
}

function EmailSettingsCard({ company, editClickedHandler }: EmailSettingsCardProps) {
  return (
    <Skeleton isLoaded={!!company}>
      <Card minWidth="900px" width="calc(100% - 200px)" mt="4">
        <CardBody>
          <Flex justifyContent="space-between" w="100%" alignItems="center" p={5}>
            <Box opacity="70%" fontSize="2xl">
              Default Email Settings
            </Box>
            <Button onClick={editClickedHandler} px="20px">
              Edit
            </Button>
          </Flex>
          <Flex direction="column" px={10}>
            <InfoDisplayValue value={company?.customEmailDefaultFrom} label={COMPANY_EMAIL_DEFAULT_FROM.label} />
            <InfoDisplayValue value={company?.customEmailDefaultReplyTo} label={COMPANY_EMAIL_DEFAULT_REPLY_TO.label} />
          </Flex>
        </CardBody>
      </Card>
    </Skeleton>
  );
}

EmailSettingsCard.defaultProps = {
  editClickedHandler: () => null,
  company: null,
};

export default function EmailSettings() {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { data, isLoading } = useQuery<Company>({
    queryKey: [QUERY_KEYS.companies, "current"],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.companies.show, { id: "current" })),
  });

  const company = data as Company;

  return (
    <Box>
      <EmailSettingsCard company={company} editClickedHandler={onOpen} />
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Email Defaults</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EmailSettingsForm {...{ company, isLoading, onClose }} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

import {
  Box, Button, Flex, SpaceProps, Text, VStack,
} from "@chakra-ui/react";
import AppPage from "layouts/AppPage";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { capitalize } from "lodash";
import apiClient from "services/ApiClient";
import React, { ReactNode } from "react";
import WorkforceTable from "components/Table/WorkforceTable";
import SelectOrText from "components/Form/Select/SelectOrText";
import { Organization } from "models/organization";
import useCaminoStore from "hooks/useCaminoStore";

function OrganizationDetails({
  organization: { organizationType, parent, manager },
  children,
  ...spaceProps
}: { organization: Organization } & SpaceProps & { children: ReactNode }) {
  const columns = [
    ["Team", children],
    ["Type", capitalize(organizationType)],
    ["Parent Team", parent?.name || "N/A"],
    ["Manager", manager?.fullName || "N/A"],
  ];

  return (
    <Flex {...spaceProps} color="black">
      {columns.map(([label, value]) => (
        <VStack alignItems="left" key={label?.toString()}>
          <Text fontWeight="semibold" w="240px">
            {label}
          </Text>
          {typeof value === "string" ? <Text w="240px">{value}</Text> : value}
        </VStack>
      ))}
      <Box flexGrow={1} />
      <Button alignSelf="center">Edit</Button>
    </Flex>
  );
}

export default function TeamsPage() {
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = React.useState<Organization | null>(null);
  const [user] = useCaminoStore((state) => [state.currentUser]);

  React.useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await apiClient.get<Organization[]>(API_ROUTES.organizations);
      setOrganizations(response);
      if (response.length) {
        setSelectedOrganization(response[0]);
      }
    };
    fetchOrganizations();
  }, [setOrganizations, setSelectedOrganization]);

  return (
    <AppPage>
      <Box>
        {selectedOrganization && (
          <>
            <OrganizationDetails mt="30px" organization={selectedOrganization}>
              {SelectOrText({
                values: organizations,
                selected: selectedOrganization,
                setSelected: setSelectedOrganization,
                getLabel: (value) => value.name,
                getValue: (value) => value.id,
              })}
            </OrganizationDetails>
            <WorkforceTable
              sx={{ mt: "40px" }}
              filters={[
                {
                  fieldName: "organizationId",
                  value: selectedOrganization?.id || "",
                  operator: "=",
                },
                {
                  fieldName: "id",
                  value: user?.id || "",
                  operator: "!=",
                },
              ]}
            />
          </>
        )}
      </Box>
    </AppPage>
  );
}

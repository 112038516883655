import { useToast, HStack, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { useEffect, useState } from "react";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useQueryClient } from "@tanstack/react-query";
import { generatePath } from "react-router-dom";
import { QUERY_KEYS } from "definitions/constants";
import { Company } from "models/company";
import TextInputFieldWithSetValueButton from "components/FormElements/Fields/InputFields/TextInputFieldWithSetValueButton";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import {
  BUDDY_EXPECTATIONS_FIELD,
  BUDDY_SELECTION_FIELD,
  ONBOARDING_PLAN_TEMPLATES_FIELD,
  BENEFITS_INFO_FIELD,
  HRIS_FIELD,
  COMPANY_HANDBOOK_FIELD,
  COMPANY_WIKI_FIELD,
  COMPANY_GOALS_FIELD,
  Schema,
  schema,
} from "./definitions";

function Form({
  isLoading,
  onSubmit,
  onClose,
  proceedText,
  defaultValues,
}: {
  isLoading: boolean;
  onSubmit: (data: Schema) => void;
  onClose: () => void;
  proceedText: string;
  defaultValues: Partial<Schema>;
}) {
  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <Stack alignItems="start">
      <TextInputFieldWithSetValueButton
        {...BUDDY_EXPECTATIONS_FIELD}
        errors={errors}
        register={register}
        setValue={setValue}
        buttonText="Use Default"
        defaultValue="https://joincamino.notion.site/Onboarding-Buddy-Expectations-b192e0e8248b4616964eddebc4c68eb1"
      />
      <TextInputFieldWithSetValueButton
        {...BUDDY_SELECTION_FIELD}
        errors={errors}
        register={register}
        setValue={setValue}
        buttonText="Use Default"
        defaultValue="https://joincamino.notion.site/Selecting-a-Buddy-for-Managers-996d0c3fce8946d2a3fc0789ad75a54b"
      />
      <TextInputFieldWithSetValueButton
        {...ONBOARDING_PLAN_TEMPLATES_FIELD}
        errors={errors}
        register={register}
        setValue={setValue}
        buttonText="Use Default"
        defaultValue="https://www.notion.so/joincamino/Onboarding-Templates-d4b9f219ff124c4b8f9cd4339f33083f"
      />
      <TextInputField {...COMPANY_WIKI_FIELD} errors={errors} register={register} />
      <TextInputField {...COMPANY_HANDBOOK_FIELD} errors={errors} register={register} />
      <TextInputField {...COMPANY_GOALS_FIELD} errors={errors} register={register} />
      <TextInputField {...HRIS_FIELD} errors={errors} register={register} />
      <TextInputField {...BENEFITS_INFO_FIELD} errors={errors} register={register} />

      <HStack my={8} alignSelf="end" justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedDisabled={!isDirty}
          proceedText={proceedText}
          isLoading={isLoading}
        />
      </HStack>
    </Stack>
  );
}

interface ValuesFormProps {
  // eslint-disable-next-line react/no-unused-prop-types
  company: Company | null | undefined;
  onClose?: () => void;
}

const values = (company: Company | null | undefined) => {
  if (!company) return {};
  const {
    newHireInfoPacketUrl,
    benefitsInfoUrl,
    hrisUrl,
    companyHandbookUrl,
    companyWikiUrl,
    companyGoalsUrl,
    buddyExpectationsUrl,
    buddySelectionUrl,
    onboardingPlanTemplatesUrl,
  } = company;
  return {
    newHireInfoPacketUrl,
    benefitsInfoUrl,
    hrisUrl,
    companyHandbookUrl,
    companyWikiUrl,
    companyGoalsUrl,
    buddyExpectationsUrl,
    buddySelectionUrl,
    onboardingPlanTemplatesUrl,
  };
};

export default function CompanyResourcesForm({ company, onClose }: ValuesFormProps) {
  const defaultValues: Partial<Schema> = values(company);
  const toast = useToast({ duration: 9000, isClosable: true, position: "top" });
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: Schema) => {
    try {
      setIsLoading(true);
      const res = await apiClient.put<Company>(generatePath(API_ROUTES.companies.show, { id: company?.id }), data);
      setIsLoading(false);
      queryClient.invalidateQueries([QUERY_KEYS.companies, "current"]);
      console.log(res);
      toast({
        title: "Company Resources Updated.",
        status: "success",
      });
      onClose?.();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    }
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      onClose={() => onClose?.()}
      proceedText="Update Company Resources"
      isLoading={isLoading}
    />
  );
}

CompanyResourcesForm.defaultProps = {
  onClose: () => {},
};

import OnboarderWelcomeFlow from "components/OnboarderWelcomeFlow";
import NoSidebarPage from "layouts/NoSidebarPage";
import { Box, useColorModeValue } from "@chakra-ui/react";

function WelcomeFlowPage() {
  const bg = useColorModeValue("gray.200", "gray.800");
  return (
    <NoSidebarPage>
      <Box bg={bg} w="100vw" h="100vh" overflow="scroll">
        <OnboarderWelcomeFlow />
      </Box>
    </NoSidebarPage>
  );
}

export default WelcomeFlowPage;

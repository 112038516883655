import apiClient from "services/ApiClient";
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { User } from "models/user";
import useCaminoStore from "hooks/useCaminoStore";

export default function AuthenticatePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const setValue = useCaminoStore((state) => state.setValue);

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      apiClient
        .post<User>(API_ROUTES.auth.complete, { token })
        .then((resp) => {
          const { company, onboardingJourney, ...currentUser } = resp;
          setValue({ currentCompany: company, currentNewHireJourney: onboardingJourney, currentUser });
          navigate(SHARED_ROUTES.home, { replace: true });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("No token found");
    }
  }, [navigate, searchParams, setValue]);

  return (
    <>
      Authenticating...
      <Spinner />
    </>
  );
}

import AssignedActionsTable from "components/Table/AssignedActionsTable";
import { Box, Center, Flex, Stack } from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import { TimeRangeValue, QUERY_KEYS, TimeRangeKeys } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { createQueryString } from "helpers/queryString";
import { ActionableType, BaseAssignedAction } from "models/automation";
import { useState, useMemo } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import timePeriodFilter, { actionTypeFilter } from "helpers/filters";
import { RandomEmptyActivityFeedState } from "components/EmptyComponents/RandomEmptyState";
import { Filter } from "models/filter";
import SelectableTimePeriod from "./SelectableTimePeriod";
import SelectableActionType from "./SelectableActionType";

interface ActivityListProps {
  defaultTimePeriod: TimeRangeValue["value"];
  timePeriodOptions?: Array<TimeRangeValue>;
  sortDesc?: boolean;
  defaultFilters?: Filter[];
}

export default function ActivityList({
  timePeriodOptions,
  defaultTimePeriod,
  sortDesc,
  defaultFilters = [],
}: ActivityListProps) {
  const [searchText, setSearchText] = useState("");
  const [timePeriodValue, setTimePeriodValue] = useState<string>(defaultTimePeriod);
  const [actionType, setActionType] = useState<ActionableType | "">("");

  const filters = useMemo(
    () => [
      ...defaultFilters,
      ...timePeriodFilter(timePeriodValue as TimeRangeKeys, "trigger.datetime"),
      ...actionTypeFilter(actionType, "action_type"),
    ],
    [timePeriodValue, defaultFilters, actionType],
  );

  const queryString = useMemo(
    () => createQueryString({
      pagination: { pageIndex: 0, pageSize: 100 },
      filters,
      additionalQueries: {
        view: "extended",
        q: searchText && searchText.length > 2 ? searchText : undefined,
      },
    }),
    [searchText, filters],
  );

  const { data: actionsData, isLoading } = useQuery<BaseAssignedAction[]>({
    queryKey: [QUERY_KEYS.assignedActions, queryString],
    queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.assignedActions.base)}${queryString.length ? `?${queryString}` : ""}`),
  });

  console.log("actionsData", actionsData);

  return (
    <>
      <Center>
        <Stack direction="column" w="100%" gap="4" mt="4" alignItems="flex-start">
          <SearchBar
            border="1px solid var(--stroke-vibes, #F1F1F1)"
            minWidth="500"
            name="actionsSearch"
            onInput={setSearchText}
          />
          <Flex gap="4" direction={{ base: "column", xl: "row" }}>
            <SelectableActionType actionType={actionType} setActionType={setActionType} />
            {timePeriodOptions && (
              <SelectableTimePeriod
                options={timePeriodOptions}
                setTimePeriodValue={setTimePeriodValue}
                timePeriodValue={timePeriodValue}
              />
            )}
          </Flex>
        </Stack>
      </Center>
      <Box mt={4}>
        <AssignedActionsTable
          tableDefaultSort={{ id: "trigger", desc: sortDesc as boolean }}
          data={actionsData || []}
          emptyStateComponent={<RandomEmptyActivityFeedState />}
          isLoaded={!isLoading}
        />
      </Box>
    </>
  );
}

ActivityList.defaultProps = {
  sortDesc: true,
  defaultFilters: [],
  timePeriodOptions: undefined,
};

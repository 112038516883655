import { CreateToastFnReturn, Button } from "@chakra-ui/react";
import { QueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { MessageServiceChannel } from "models/integration/messageService";

export default function SyncChannelsButton() {
  const {
    performRequest: syncChannels,
    state: { loading: isLoading },
  } = useDeferredApiCall<MessageServiceChannel[]>(API_ROUTES.messageServices.channels.sync, {
    method: "POST",
    onSuccess: (_, queryClient, toast) => {
      queryClient.invalidateQueries([QUERY_KEYS.integrations]);
      toast({ title: "Channels synced" });
    },
    onFailure: (_, _2: QueryClient, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return (
    <Button onClick={() => syncChannels()} isLoading={isLoading} loadingText="Syncing Channels">
      Sync Channels
    </Button>
  );
}

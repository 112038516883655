import {
  Card,
  Stack,
  CardBody,
  Heading,
  Text,
  CardProps,
  Button,
  CardFooter,
  Flex,
  FormLabel,
  Input,
  CreateToastFnReturn,
  Switch,
} from "@chakra-ui/react";
import { QueryClient } from "@tanstack/react-query";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { useState } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";

interface PathTemplateCardProps extends CardProps {
  id: string;
  name: string;
  description: string;
  emoji: string;
  destination: string;
}

export default function PathTemplateCard({
  id,
  name,
  description,
  destination,
  emoji,
  ...rest
}: PathTemplateCardProps) {
  const [customName, setCustomName] = useState(name);
  const [autoAdd, setAutoAdd] = useState(false);

  const handleToggle = () => {
    setAutoAdd(a => !a);
  };

  const { performRequest: onCreate } = useDeferredApiCall<{ id: string }>(API_ROUTES.paths.template, {
    method: "POST",
    onSuccess: (resp, queryClient, toast, navigate) => {
      queryClient.invalidateQueries([QUERY_KEYS.paths]);
      queryClient.invalidateQueries([QUERY_KEYS.defaultPaths]);
      toast({
        title: "Template copied successfully!",
      });
      navigate(generatePath(PEOPLE_TEAM_ROUTES.paths.show, { id: resp.id }));
    },
    onFailure: (_, _2: QueryClient, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return (
    <Card direction="row" overflow="hidden" variant="outline" {...rest}>
      <Stack>
        <CardBody>
          <Flex direction="row" align="center">
            {emoji && (
              <Text mr={4} fontSize="30px" height="100%">
                {emoji}
              </Text>
            )}
            <Heading size="xxs">{name}</Heading>
          </Flex>

          <Text py="2">{description}</Text>
        </CardBody>
        <CardFooter position="absolute" bottom={0} width="100%" pt={0} display="flex" justify="space-between" gap="2">
          <Button width="47%" size="sm" as={RouterLink} to={destination} variant="outline" colorScheme="brand">
            Preview
          </Button>
          <ButtonWithConfirm
            buttonText="Use Template"
            header="Use Template"
            confirmButtonText="Confirm"
            body={(
              <>
                <FormLabel>Name</FormLabel>
                <Input placeholder="Enter a name" value={customName} onChange={(e) => setCustomName(e.target.value)} />
                <Flex alignItems="center" mt="2">
                  <FormLabel>Auto Assign to All New Hires?</FormLabel>
                  <Switch id="autoAdd" onChange={handleToggle} isChecked={autoAdd} />
                </Flex>
              </>
            )}
            handleConfirm={() => onCreate({ templateWorkflowId: id, name: customName, autoAdd })}
            intent="confirmation"
          >
            <Button width="47%" size="sm" variant="solid" colorScheme="brand">
              Use Template
            </Button>
          </ButtonWithConfirm>
        </CardFooter>
      </Stack>
    </Card>
  );
}

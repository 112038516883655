/* eslint-disable no-param-reassign */
import { useMemo } from "react";
import { SystemStyleObject } from "@chakra-ui/react";
import "./styles.css";
import GenericTable, { GenericTableProps } from "components/Table/GenericTable";
import { FilterMeta, ResponseModel } from "services/ApiClient/responseModel";
import { COMMUNICATION_TYPE, QUERY_KEYS } from "definitions/constants";
import { EmptyNewHireJourneyState } from "components/EmptyComponents/RandomEmptyState";
import { range } from "lodash";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { NewHireJourneyWithAssignedActions } from "models/joinedModels";
import { ColumnDefinition, StageInfo, makeColumns } from "./definition";

interface OverviewTableProps {
  searchText?: string;
  filters?: FilterMeta[];
  sx?: SystemStyleObject;
  getCardProps?: () => object;
  setTable?: () => object;
  stages: StageInfo[];
  columnFilterFn: (columnDefinitios: ColumnDefinition[]) => ColumnDefinition[];
  stageFilterFn: (stages: StageInfo[]) => StageInfo[];
  selectedStageId?: string;
}

export default function OverviewTable({
  filters,
  searchText,
  setTable,
  getCardProps,
  stages,
  sx,
  columnFilterFn = (columns) => columns,
  stageFilterFn = (inputStages) => inputStages,
  selectedStageId,
  ...rest
}: OverviewTableProps) {
  const additionalQueries = useMemo(() => {
    const queries: Record<string, string | string[]> = {
      includes: ["assigned_actions"],
    };
    if (searchText && searchText.length > 2) {
      queries.q = searchText;
    }
    return queries;
  }, [searchText]);

  const tableProps: GenericTableProps<NewHireJourneyWithAssignedActions> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<NewHireJourneyWithAssignedActions[]>>(
      `${API_ROUTES.newHireJourneys.base}${query}`,
      signal,
    )
      .then((response) => response.payload)
      .then((data) => {
        data.data = data?.data.map((newHireJourney: NewHireJourneyWithAssignedActions) => {
          newHireJourney.actionsById = newHireJourney.assignedActions.reduce((acc, action) => {
            if (action.actionType !== COMMUNICATION_TYPE.TASK_REMINDER) {
              acc[action.workflowActionId] = action;
            }
            return acc;
          }, {});
          return newHireJourney;
        });
        return data;
      }),
    queryKey: [QUERY_KEYS.newHireJourneys],
    columns: makeColumns({
      stages,
      columnFilterFn,
      stageFilterFn,
      selectedStageId,
    }),
    meta: { pagination: { perPage: 20 }, sort: [{ sortBy: "startDate", direction: "desc" }], filters },
    additionalQueries,
  };

  return (
    <GenericTable
      {...tableProps}
      emptyStateComponent={<EmptyNewHireJourneyState />}
      getTdProps={() => ({ py: "6px" })}
      pageSizeOptions={range(20, 100, 20)}
      setTable={setTable}
      textWrap
      containerProps={{
        width: "fit-content",
        maxHeight: "100vh",
      }}
      sx={{
        "& tbody tr:first-of-type td:first-of-type": {
          paddingTop: "20px",
        },
        "& thead tr:first-of-type th, & thead tr:nth-of-type(2) th": {
          position: "sticky",
          top: 0,
          zIndex: 5,
        },
        "& thead tr:nth-of-type(2) th": {
          top: "44px",
          backgroundColor: "var(--chakra-colors-fg-accent-subtle)",
        },
        "& thead tr:first-of-type th:first-of-type, & thead tr:first-of-type th:nth-of-type(2), & thead tr:nth-of-type(2) th:first-of-type, & thead tr:nth-of-type(2) th:nth-of-type(2)":
          {
            zIndex: 6,
            left: 0,
          },
        "& thead tr:first-of-type th:nth-of-type(2), & thead tr:nth-of-type(2) th:nth-of-type(2)": {
          left: "32px",
        },
        "& thead tr:first-of-type th:first-of-type, & thead tr:first-of-type th:nth-of-type(2)": {
          backgroundColor: "var(--chakra-colors-chakra-body-bg)",
          zIndex: 7,
        },
        "& thead tr:nth-of-type(2) th:first-of-type, & thead tr:nth-of-type(2) th:nth-of-type(2)": {
          backgroundColor: "var(--chakra-colors-fg-accent-subtle)",
          top: "44px",
          zIndex: 6,
        },
        "& tbody tr:nth-of-type(even) td:nth-of-type(1), & tbody tr:nth-of-type(even) td:nth-of-type(2)": {
          background: "var(--chakra-colors-chakra-body-bg)",
        },
        "& tbody td:nth-of-type(1), & tbody td:nth-of-type(2)": {
          background: "var(--chakra-colors-chakra-body-bg) !important",
          position: "sticky",
          zIndex: 2,
          left: 0,
        },
        "& tbody td:nth-of-type(2)": {
          left: "32px",
        },
        "& table": {
          borderCollapse: "separate",
          borderSpacing: 0,
        },
        mt: "2px",
        ...sx,
      }}
      {...rest}
    />
  );
}

OverviewTable.defaultProps = {
  getCardProps: () => ({}),
  setTable: () => ({}),
  searchText: null,
  filters: undefined,
  sx: undefined,
  selectedStageId: undefined,
};

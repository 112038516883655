import { Box, Link, SystemStyleObject } from "@chakra-ui/react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { BaseTask } from "models/task";
import { taskDateDisplay } from "helpers/time";
import { MarkCompleteToggleButton } from "layouts/Task/TaskCardDropDown";
import LinkButton from "components/Button/LinkButton";
import { useCallback } from "react";
import useCaminoStore from "hooks/useCaminoStore";
import { useQueryClient } from "@tanstack/react-query";
import { ResponseModel } from "services/ApiClient/responseModel";
import { QUERY_KEYS } from "definitions/constants";
import apiClient from "services/ApiClient";
import { truncateEnd } from "helpers/string";
import GenericTable, { GenericTableProps } from "./GenericTable";

const columnHelper = createColumnHelper<BaseTask>();
// Task Name
// Task Description
// Plan It
// Mark it as done
// Marked as done

const makeColumns = (onSuccess: (response: BaseTask) => void) => [
  columnHelper.accessor((row) => row, {
    id: "dueDate",
    header: "Due On",
    cell: (row) => (row.getValue().dueDate ? taskDateDisplay(row.getValue().dueDate as Date) : "No Due Date"),
    minSize: 40,
  }),
  columnHelper.accessor((row) => row, {
    id: "title",
    header: "Title",
    cell: (row) => (
      <Link
        as={RouterLink}
        to={{
          pathname: generatePath(SHARED_ROUTES.tasks.show, {
            id: row.getValue().id,
          }),
        }}
      >
        {row.getValue().title}
      </Link>
    ),
    size: 80,
  }),
  columnHelper.accessor((row) => row, {
    id: "description",
    header: "Description",
    cell: (row) => truncateEnd(row.getValue().description, 100),
    maxSize: 80,
  }),
  columnHelper.accessor((row) => row, {
    id: "view",
    header: "",
    cell: (row) => (
      <Box>
        <LinkButton variant="outline" to={generatePath(SHARED_ROUTES.tasks.show, { id: row.getValue()?.id })}>
          View
        </LinkButton>
      </Box>
    ),
    minSize: 79,
    maxSize: 79,
  }),
  columnHelper.accessor((row) => row, {
    id: "markComplete",
    header: "",
    cell: (row) => <MarkCompleteToggleButton task={row.getValue()} onSuccess={onSuccess} />,
    minSize: 40,
    maxSize: 40,
  }),
];

interface NewHireTaskTableProps {
  sx?: SystemStyleObject;
}

export default function NewHireTaskTable({ sx }: NewHireTaskTableProps) {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    async (resTask: BaseTask) => {
      queryClient.setQueriesData([QUERY_KEYS.tasks, "me", "new_hire"], (previous: any) => ({
        ...(previous as ResponseModel<BaseTask[]>),
        data: (previous as ResponseModel<BaseTask[]>).data.map((task) => (task.id === resTask.id ? resTask : task)),
      }));
    },
    [queryClient],
  );

  const filters = [
    {
      fieldName: "tasks.user_id",
      value: user?.id as string,
      operator: "=",
    },
    {
      fieldName: "onboarding_journeys.user_id",
      value: user?.id as string,
      operator: "=",
    },
  ];

  const columns = makeColumns((res) => onSuccess(res));

  const tableProps: GenericTableProps<BaseTask> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<BaseTask[]>>(`${API_ROUTES.tasks.base}${query}`, signal)
      .then((response) => response.payload),
    queryKey: [QUERY_KEYS.tasks, "me", "new_hire"],
    columns,
    meta: {
      pagination: { pageIndex: 0, pageSize: 1000, perPage: 10 },
      sort: [{ sortBy: "dueDate", direction: "desc" }],
      filters,
    },
    textWrap: true,
  };

  return <GenericTable sx={sx} {...tableProps} />;
}

NewHireTaskTable.defaultProps = {
  sx: undefined,
};

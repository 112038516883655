import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  Stack,
  ButtonGroup,
  AccordionItemProps,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { BaseTask } from "models/task";
import { useCallback } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

interface MarkCompleteToggleButtonProps {
  task: BaseTask | undefined;
  onSuccess?: (response: any) => void;
}

export function MarkCompleteToggleButton({ task, onSuccess }: MarkCompleteToggleButtonProps) {
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });

  const { mutate } = useMutation(
    async () => {
      const taskIncompleteStatus = task?.notifiedAt ? "notified" : "assigned";
      const res = await apiClient.put<BaseTask>(generatePath(API_ROUTES.tasks.show, { id: task?.id }), {
        task: { status: task?.status === "completed" ? taskIncompleteStatus : "completed" },
      });
      return res;
    },
    {
      onSuccess: async (res) => {
        if (onSuccess) {
          onSuccess(res);
        }
        toast({ title: `Task ${res.status === "completed" ? "completed" : "re-opened"}` });
      },
      onError: (e) => {
        console.log(e);
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
        });
      },
    },
  );

  if (task?.skippedAt) {
    return null;
  }
  if (task?.completedAt) {
    return (
      <Button minWidth="130px" colorScheme="green" onClick={() => mutate()}>
        Re-Open
      </Button>
    );
  }
  return (
    <Button minWidth="130px" colorScheme="brand" onClick={() => mutate()}>
      Mark Complete
    </Button>
  );
}

MarkCompleteToggleButton.defaultProps = {
  onSuccess: null,
};

interface MarkSkippedToggleButtonProps {
  task: BaseTask | undefined;
  onSuccess?: (response: any) => void;
}

export function MarkSkippedToggleButton({ task, onSuccess }: MarkSkippedToggleButtonProps) {
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });

  const { mutate } = useMutation(
    async () => {
      const taskIncompleteStatus = task?.notifiedAt ? "notified" : "assigned";
      const res = await apiClient.put<BaseTask>(generatePath(API_ROUTES.tasks.show, { id: task?.id }), {
        task: { status: task?.status === "skipped" ? taskIncompleteStatus : "skipped" },
      });
      return res;
    },
    {
      onSuccess: async (res) => {
        if (onSuccess) {
          onSuccess(res);
        }
        toast({ title: `Task ${res.status === "skipped" ? "skipped" : "re-opened"}` });
      },
      onError: (e) => {
        console.log(e);
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
        });
      },
    },
  );

  if (task?.completedAt) {
    return null;
  }
  if (task?.skippedAt) {
    return (
      <Button minWidth="130px" colorScheme="gray" onClick={() => mutate()}>
        Unskip
      </Button>
    );
  }
  return (
    <Button minWidth="130px" colorScheme="gray" onClick={() => mutate()}>
      Skip
    </Button>
  );
}

MarkSkippedToggleButton.defaultProps = {
  onSuccess: null,
};

interface AccordionSectionProps extends AccordionItemProps {
  task: BaseTask;
}

export function TaskCardDropdown({ task, ...rest }: AccordionSectionProps) {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    async (resTask: BaseTask) => {
      queryClient.setQueryData([QUERY_KEYS.task, resTask?.id], resTask);
    },
    [queryClient],
  );

  return (
    <AccordionItem {...rest}>
      {({ isExpanded }) => (
        <>
          <Heading>
            <AccordionButton>
              <AccordionIcon mr="8" />
              <Box as="span" flex="1" textAlign="left">
                <HStack justify="space-between" mr={16}>
                  <VStack>
                    <Text fontSize="sm" color="fg.muted">
                      {task.estimatedTime}
                    </Text>
                    <Text fontSize="xl" fontWeight="600" color="brand">
                      {task.title}
                    </Text>
                  </VStack>
                  {isExpanded ? (
                    task.createdBy && <Text color="fg.muted">{`Content Provided by ${task.createdBy}`}</Text>
                  ) : (
                    <MarkCompleteToggleButton task={task} onSuccess={onSuccess} />
                  )}
                </HStack>
              </Box>
            </AccordionButton>
          </Heading>
          <AccordionPanel pb={4}>
            <Stack mx={12}>
              <HStack justifyContent="space-between">
                <Box width="80%">
                  <Text color="fg.muted">{task.description}</Text>
                </Box>
                <MarkCompleteToggleButton task={task} onSuccess={onSuccess} />
              </HStack>
              <ButtonGroup width="25%">
                <Button variant="ghost" onClick={() => null}>
                  Report an Issue
                </Button>
                <Button variant="ghost" onClick={() => null}>
                  Submit Feedback
                </Button>
              </ButtonGroup>
            </Stack>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}

interface TaskGroupProps {
  tasks: BaseTask[];
}

export default function TaskGroup({ tasks }: TaskGroupProps) {
  return (
    <Box borderWidth="2px" borderColor="border.default" borderRadius="5px">
      <Accordion allowMultiple>
        {(tasks || []).map((task) => (
          <TaskCardDropdown key={task.id} task={task} />
        ))}
      </Accordion>
    </Box>
  );
}

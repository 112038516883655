import Step from "components/StepContainer/Step";
import StepContainer from "components/StepContainer/BottomStepper";
import useApiClient from "hooks/useApiClient";
import { ONBOARDER_ROUTES } from "definitions/constants/routeConstants";
import LoadingCard from "components/Card/LoadingCard";
import { User } from "models/user";
import { Connection } from "models/connection";
import { CompanyValue } from "models/welcomeFlow";
import { Box } from "@chakra-ui/react";
import { generatePath, useNavigate } from "react-router-dom";
import useCaminoStore from "hooks/useCaminoStore";
import EmbeddedHtmlLetter from "./EmbeddedHtmlLetter";
import TeamOverview from "./TeamOverview";
import InitialWelcome from "./InitialWelcome";
import TeammatesWelcome from "./TeammatesWelcome";
import LeadershipWelcome from "./LeadershipWelcome";
import CompanyValues from "./CompanyValues";

interface WelcomeFlowStepBase {
  id: string;
  title: string;
  description: string;
  headerText: string;
}

interface WelcomeMessage {
  id: string;
  message: string;
  author: string;
  authorTitle: string;
  authorTeam: string;
  imageUrl?: string;
  authorAvatarUrl?: string;
}

type WelcomeFlowStepType =
  | {
    type: "camino_intro" | "company_welcome" | "manager_welcome";
    messageHtml: string;
  }
  | {
    type: "initial_welcome";
    message: WelcomeMessage;
  }
  | {
    type: "teammates_welcome" | "leadership_welcome";
    messages: WelcomeMessage[];
  }
  | {
    type: "team_overview";
    manager: User;
    connections: Connection[];
  }
  | {
    type: "what_to_expect";
  }
  | {
    type: "company_values";
    values: CompanyValue[];
  };

function OnboarderWelcomeFlow() {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const navigate = useNavigate();
  const { loading, data, error } = useApiClient<{
    steps:(WelcomeFlowStepBase & WelcomeFlowStepType)[];
  }>(ONBOARDER_ROUTES.welcomeFlow, { method: "GET" });

  if (loading) {
    return <LoadingCard />;
  }

  if (error) {
    return <div>Error</div>;
  }

  console.log(data);

  const steps: Step[] = data.steps.map((step) => {
    const base: WelcomeFlowStepBase = {
      id: step.id,
      title: step.title,
      description: step.description,
      headerText: step.headerText,
    };
    switch (step.type) {
      case "camino_intro":
      case "company_welcome":
      case "manager_welcome":
        return {
          ...base,
          component: <EmbeddedHtmlLetter messageHtml={step.messageHtml} />,
        };
      case "initial_welcome":
        return {
          ...base,
          component: <InitialWelcome user={user} message={step.message} />,
        };
      case "leadership_welcome":
        return {
          ...base,
          component: <LeadershipWelcome user={user} messages={step.messages} />,
        };
      case "teammates_welcome":
        return {
          ...base,
          component: <TeammatesWelcome user={user} messages={step.messages} />,
        };
      case "company_values":
        return {
          ...base,
          component: <CompanyValues user={user} values={step.values} />,
        };
      case "team_overview":
        return {
          ...base,
          component: <TeamOverview manager={step.manager} connections={step.connections} />,
        };
      case "what_to_expect":
        return {
          id: step.id,
          title: step.title,
          description: step.description,
          headerText: step.headerText,
          component: <Box color="chakra-subtle-text">Stuff will happen this week!</Box>,
        };
      default:
        throw Error(`Unknown step type: ${step}`);
    }
  });

  return <StepContainer steps={steps} onFinish={() => navigate(generatePath(ONBOARDER_ROUTES.dashboard))} />;
}

export default OnboarderWelcomeFlow;

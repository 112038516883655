import Otter from "assets/SadOtter.png";
import Empty from "./Empty";

interface UnfoundResourceProps {
  resourceId?: string;
  resourceName?: string;
}

export default function UnfoundResource({ resourceId, resourceName }: UnfoundResourceProps) {
  let returnText = "We're sorry. This sad otter cannot find what you were looking for.";
  if (resourceId) {
    returnText = resourceName
      ? `This sad otter could not find the ${resourceName} with the id ${resourceId}`
      : `This sad otter could not find the id ${resourceId}`;
  }
  return <Empty heading={returnText} image={{ src: Otter, alt: "Sad Otter" }} />;
}

UnfoundResource.defaultProps = {
  resourceId: undefined,
  resourceName: undefined,
};

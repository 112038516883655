import { useToast } from "@chakra-ui/react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { AssignedAction } from "models/automation/scheduledWorkflow";

interface UseSubmitAssignedActionUpdate {
  assignedActionId: string;
  onSuccess?: () => void;
}

export default function useSubmitUpdateForm({ assignedActionId, onSuccess }: UseSubmitAssignedActionUpdate) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate: onSubmit, isLoading } = useMutation({
    mutationFn: (data: any) => apiClient.put<AssignedAction>(
      generatePath(API_ROUTES.assignedActions.show, { id: assignedActionId || "" }),
      data,
    ),
    onSuccess: (res) => {
      queryClient.invalidateQueries([QUERY_KEYS.assignedActions, assignedActionId]);
      toast({
        title: "Updated!",
      });
      onSuccess?.();
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Update Error", mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return { onSubmit, isLoading };
}

import Otter from "assets/SadOtter.png";
import Empty from "./Empty";

interface UnfoundResourceProps {
  resourceId?: string;
  resourceName?: string;
}

export default function UnknownError({ resourceId, resourceName }: UnfoundResourceProps) {
  let returnText = "We're sorry. This sad otter ";
  if (resourceId) {
    returnText += resourceName
      ? `had an unidentified error while accessing the ${resourceName} with the id ${resourceId}`
      : `had an unidentified error while accessing the id ${resourceId}`;
  } else {
    returnText += "experienced an unidentified error.";
  }
  return <Empty heading={returnText} image={{ src: Otter, alt: "Sad Otter" }} />;
}

UnknownError.defaultProps = {
  resourceId: undefined,
  resourceName: undefined,
};

/* eslint-disable max-len */
// eslint-disable-next-line object-curly-newline
import { Box, Flex, Heading } from "@chakra-ui/react";
import { HorizontalWelcomeMessageProfileCard } from "components/Card/WelcomeProfileCard";
import { User } from "models/user";
import { WelcomeMessage } from "models/welcomeFlow";

interface InitialWelcomeProps {
  user: User | null;
  message: WelcomeMessage;
}

export default function InitialWelcome({ user, message }: InitialWelcomeProps) {
  const { firstName } = user || { firstname: null };
  return (
    <Box
      pt="12"
      px="32"
      pb="32"
    >
      <Flex justifyContent="center" mt="14">
        <Heading as="h3" fontWeight="400" mb="32px">
          Welcome to the Team!
        </Heading>
      </Flex>
      <Flex justifyContent="center">
        <HorizontalWelcomeMessageProfileCard message={message} />
      </Flex>
    </Box>
  );
}

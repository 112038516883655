import { TabPanel, Heading } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import ControlledTabs from "components/Form/ControlledTabs";
import { useEffect } from "react";
import Scheduling from "./Scheduling";
import { Schema } from "../schema";
import TaskCompletion from "./TaskCompletion";
import { TRIGGER_TYPE_FIELD } from "../definitions";

export default function Trigger({
  workflowId,
  workflowActionId,
  form,
}: {
  workflowId: string;
  workflowActionId?: string;
  form: UseFormReturn<Schema>;
}) {
  const {
    control,
    watch,
    setValue,
    formState: { errors, defaultValues },
  } = form;
  const triggerType = watch("trigger.type");

  useEffect(() => {
    if (triggerType === "dependency") {
      setValue("trigger.data", undefined);
      setValue("trigger", {
        type: "dependency",
        data: { event: "task.completed", dependencyType: "WorkflowAction", dependencyId: "" },
      });
    } else if (triggerType === "timing") {
      setValue("trigger.data", undefined);
      setValue("trigger.type", "timing");
    }
    if (defaultValues?.trigger?.type === triggerType && defaultValues?.trigger?.data) {
      setValue("trigger.data", defaultValues.trigger.data);
    }
  }, [triggerType, setValue, defaultValues]);

  return (
    <>
      <Heading size="xs" mt="4">
        Trigger
      </Heading>
      <ControlledTabs
        control={control}
        {...TRIGGER_TYPE_FIELD}
        options={[
          { label: "Timing", value: "timing" },
          { label: "Task Completion", value: "dependency" },
        ]}
      >
        <TabPanel paddingX={0}>
          <Scheduling form={form} fieldName="trigger" />
        </TabPanel>
        <TabPanel paddingX={0}>
          <TaskCompletion
            control={control}
            errors={errors}
            workflowId={workflowId}
            workflowActionId={workflowActionId}
          />
        </TabPanel>
      </ControlledTabs>
    </>
  );
}

Trigger.defaultProps = {
  workflowActionId: undefined,
};

import { CreateToastFnReturn, Button } from "@chakra-ui/react";
import { QueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { User } from "models/user";

interface SyncUsersButtonProps {
  onSuccess?: () => void;
}

export default function SyncUsersButton({ onSuccess }: SyncUsersButtonProps) {
  const {
    performRequest: syncChannels,
    state: { loading: isLoading },
  } = useDeferredApiCall<User[]>(API_ROUTES.messageServices.users.sync, {
    method: "POST",
    onSuccess: (_, queryClient, toast) => {
      if (onSuccess) {
        onSuccess();
      }
      queryClient.invalidateQueries([QUERY_KEYS.integrations]);
      toast({ title: "Users synced" });
    },
    onFailure: (_, _2: QueryClient, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return (
    <Button onClick={() => syncChannels()} isLoading={isLoading} loadingText="Syncing Users">
      Sync Users
    </Button>
  );
}

SyncUsersButton.defaultProps = {
  onSuccess: () => {},
};

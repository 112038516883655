/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
// eslint-disable-next-line object-curly-newline
import { Box, Container, HStack, IconButton, Link, Stack, StackDivider, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { OnboardingPlan, Resource } from "models/onboarding";
import NewResourceForm from "components/ModalForm/NewResourceForm";

interface ResourcesSectionProps {
  onboardingPlan: OnboardingPlan;
}

interface EditableResourceCardProps {
  resource: Resource;
}

function EditableResourceCard({
  resource: { id, name, description, resourceType, url, contact },
}: EditableResourceCardProps) {
  return (
    <Stack key={id} justify="space-between" direction="row" spacing="4">
      <Stack spacing="0.5" fontSize="sm">
        <HStack justify="space-between" spacing="20">
          <Text color="fg.emphasized" fontWeight="medium">
            {name}
          </Text>
          <Text color="fg.emphasized">{resourceType}</Text>
        </HStack>
        <Link color="fg.subtle" href={url} isExternal>
          {url}
          <ExternalLinkIcon mx="2px" />
        </Link>
        <Text color="fg.muted">{description}</Text>
        <Text color="fg.subtle">{contact && `Contact: ${contact?.fullName}`}</Text>
      </Stack>

      <Stack direction={{ base: "column", sm: "row" }} spacing={{ base: "0", sm: "1" }}>
        <IconButton icon={<FiEdit2 />} variant="tertiary" aria-label="Edit experience" />
        <IconButton icon={<FiTrash2 />} variant="tertiary" aria-label="Delete experience" />
      </Stack>
    </Stack>
  );
}

export default function ResourcesSection({ onboardingPlan }: ResourcesSectionProps) {
  return (
    <Box as="section" width="100%" pb={{ base: "4", md: "8" }}>
      <Container width="100%" maxW="4xl">
        <NewResourceForm onboardingPlanId={onboardingPlan.id} />
        <Stack spacing="5" divider={<StackDivider />}>
          {(onboardingPlan.resources || []).map((resource) => (
            <EditableResourceCard key={resource.id} resource={resource} />
          ))}
        </Stack>
      </Container>
    </Box>
  );
}

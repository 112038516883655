import { useToast } from "@chakra-ui/react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { Task } from "models/joinedModels";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

interface UseSubmitUpdateTask {
  taskId: string;
  onSuccess?: () => void;
}

export default function useSubmitUpdateTaskForm({ taskId, onSuccess }: UseSubmitUpdateTask) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate: onSubmit, isLoading } = useMutation({
    mutationFn: (data: any) => apiClient.put<Task>(generatePath(API_ROUTES.tasks.show, { id: taskId || "" }), { task: data }),
    onSuccess: (res) => {
      queryClient.invalidateQueries([QUERY_KEYS.tasks, taskId]);
      toast({
        title: "Task updated!",
      });
      onSuccess?.();
      return res;
    },
    onError: (mutateError) => {
      console.log("Task Update Error", mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return { onSubmit, isLoading };
}

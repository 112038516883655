import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { NewHireJourney } from "models/onboarding";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { generatePath } from "react-router-dom";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { QUERY_KEYS } from "definitions/constants";
import { User } from "models/user";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";

interface WrapperNewHireConnectionFormProps extends PropsWithChildren {
  label: string;
  attributeName: string;
  attributeValue?: User;
  newHireUserId: string;
  newHireJourneyId: string;
}

export default function NewHireConnectionFormWrapper({
  label,
  attributeName,
  attributeValue,
  newHireUserId,
  newHireJourneyId,
  children,
}: WrapperNewHireConnectionFormProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const excludedIds = useMemo(() => (newHireUserId ? [newHireUserId] : []), [newHireUserId]);
  const initialRef = React.useRef(null);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({
    mode: "onBlur",
  });

  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async (formData: any) => {
      const res = await apiClient.put<NewHireJourney>(
        generatePath(API_ROUTES.newHireJourneys.show, { id: newHireJourneyId }),
        { onboarding_journey: formData },
      );
      return res;
    },
    {
      onSuccess: async (res) => {
        queryClient.setQueryData([QUERY_KEYS.newHireJourney, res.id], res);
        toast({ title: "Updated" });
        onClose();
      },
      onError: (e) => {
        console.log(e);
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
        });
      },
    },
  );

  const [cacheKey, setCacheKey] = useState(excludedIds.join("-"));

  useEffect(() => {
    setCacheKey(excludedIds.join("-"));
  }, [setCacheKey, excludedIds]);

  return (
    <>
      {React.cloneElement(children as JSX.Element, { onClick: onOpen })}

      <Modal initialFocusRef={initialRef} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={30}>{`Update ${label}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Stack spacing="5">
              <UserSelectField
                cacheKey={cacheKey}
                updateCacheKey={setCacheKey}
                {...(attributeValue ? { defaultValue: attributeValue } : {})}
                excludedIds={excludedIds}
                control={control}
                name={attributeName}
                label={label}
                errors={errors}
              />
            </Stack>
            <HStack mt={12} justify="end">
              <ProceedCancelButtons
                handleSubmit={handleSubmit(mutate)}
                onClose={onClose}
                proceedText="Update"
                proceedDisabled={!isDirty}
                isLoading={isLoading}
              />
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

NewHireConnectionFormWrapper.defaultProps = {
  attributeValue: null,
};

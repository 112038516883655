import useStep from "hooks/useStep";
import StepProgressBar from "./StepProgressBar";
import Step from "./Step";
import StepContent from "./StepContent";

function StepContainer({ steps, onFinish }: { steps: Step[], onFinish: () => void }) {
  const [currentStepIndex, { setStep, ...rest }] = useStep({ maxStep: steps.length - 1 });
  const currentStep = steps[currentStepIndex];

  return (
    <>
      <StepContent>{currentStep.component}</StepContent>
      <StepProgressBar steps={steps} onFinish={onFinish} currentStepIndex={currentStepIndex} setNextStep={setStep} {...rest} />
    </>
  );
}

export default StepContainer;

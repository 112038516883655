import { Tag } from "@chakra-ui/react";
import { issuesPath } from "helpers/url";
import { Link as RouterLink } from "react-router-dom";

interface IssueTagProps {
  issuesCount: number;
  newHireJourneyId: string;
}

export default function IssueTag({ issuesCount, newHireJourneyId }: IssueTagProps) {
  const singular = issuesCount === 1 ? "" : "s";
  return !issuesCount ? (
    <Tag
      variant="outline"
      color="tableUnderlineBorder.DEFAULT"
      border="1px solid var(--chakra-colors-tableUnderlineBorder-DEFAULT)"
      bg="rgba(217, 217, 217, 0.05)"
      borderRadius="md"
      boxShadow="none"
    >
      {`${issuesCount} error${singular}`}
    </Tag>
  ) : (
    <RouterLink to={issuesPath(newHireJourneyId)}>
      <Tag
        variant="outline"
        color="error.DEFAULT"
        border="1px solid var(--chakra-colors-error-DEFAULT)"
        bg="#fff5f5"
        borderRadius="md"
        boxShadow="none"
      >
        {`${issuesCount} error${singular}`}
      </Tag>
    </RouterLink>
  );
}

import {
  Box, Link, Tag, Text, Tooltip, useToast, PopoverTrigger,
} from "@chakra-ui/react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { API_ROUTES, PEOPLE_TEAM_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { timeAtSpecificDayMonth } from "helpers/time";
import { BaseAssignedAction } from "models/automation";
import apiClient from "services/ApiClient";
import { automationTriggerText } from "helpers/workflowActionableConverters";
import { scheduledActionStatusConverter, scheduledActionToStatusColor } from "helpers/scheduledWorkflowConverters";
import { scheduledTriggerSort } from "helpers/trigger";
import { useCallback, useState } from "react";
import Alert from "components/Alert/Alert";
import useCaminoStore from "hooks/useCaminoStore";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import DateSelectForm from "components/Forms/DateSelectForm";
import ScheduledActionRowButton from "components/ScheduledActionRowButton";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { EMOJI_ICONS } from "definitions/constants/icons";
import moment from "moment";
import ControlledDataTable, { ControlledDataTableProps } from "./ControlledDataTable";
import ReceiverLinkOrTBD, { DisplayTypeAndTarget } from "./CellDisplayHelpers";

const ALERT_SUCCESS_TITLE = "Message Sent!";
const ALERT_SUCCESS_BODY = (destination: string) => `A test was sent to ${destination}`;
const ALERT_FAILURE_TITLE = "Error";
const ALERT_FAILURE_BODY = (destination: string) => `There was an error trying to send to ${destination}`;
const ALERT_BUTTON_TITLE = "Close";

const columnHelper = createColumnHelper<BaseAssignedAction | AssignedAction>();

function TriggerComponent({
  action,
  onUpdateSuccess,
}: {
  action: AssignedAction;
  onUpdateSuccess: (action: AssignedAction, newHireJourneyId: string) => void;
}) {
  const { trigger, status, scheduledWorkflow, id } = action;
  const newHireJourney = action.onboardingJourney;
  const newHireJourneyId = newHireJourney?.id ?? scheduledWorkflow?.onboardingJourneyId;

  switch (trigger.type) {
    case "date_time":
      return (
        <DateSelectForm
          name="trigger.data.datetime"
          label=""
          submitType="PUT"
          isDisabled={status === "processed"}
          initialValue={trigger.data.datetime}
          minDate={moment().subtract(1, "days").toDate()}
          preserveTime
          submitUrl={generatePath(API_ROUTES.assignedActions.show, { id })}
          onSuccess={() => onUpdateSuccess(action, newHireJourneyId)}
        >
          {!["processed", "skipped", "removed"].includes(status) ? (
            <PopoverTrigger>
              <Text as="u" cursor="pointer">{`${timeAtSpecificDayMonth(trigger.data.datetime)} `}</Text>
            </PopoverTrigger>
          ) : (
            <Box>
              <Text>{`${timeAtSpecificDayMonth(trigger.data.datetime)} `}</Text>
            </Box>
          )}
        </DateSelectForm>
      );
    case "dependency":
      return <Text>Task Completion</Text>;
    default:
      return null;
  }
}
// "Scheduled At" (future)
// "Sent At" (past)
// attempted_at
// first_attempted_at
// trigger_at_customized

const makeColumns = (
  onPreview: (id: string) => void,
  onActionSend: (action: AssignedAction | BaseAssignedAction) => void,
  onActionPause: (action: AssignedAction | BaseAssignedAction) => void,
  onActionDelete: (action: AssignedAction | BaseAssignedAction) => void,
  onUpdateSuccess: (action: AssignedAction, newHireJourneyId: string) => void,
) => {
  const columnDefs = [
    columnHelper.accessor((row) => row, {
      id: "actionType",
      header: "Type",
      cell: (row) => (
        <DisplayTypeAndTarget
          actionType={row.getValue()?.actionType}
          taskType={row.getValue()?.task?.taskType}
          relationTarget={row.getValue()?.workflowAction.relationTarget}
        />
      ),
      maxSize: 20,
      minSize: 20,
      size: 20,
      meta: {
        sx: {
          paddingLeft: 6,
          paddingRight: 4,
        },
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "target",
      header: "Recipient/Assignee",
      cell: (row) => {
        const rowValue = row.getValue();
        const { targetable } = rowValue;
        return <ReceiverLinkOrTBD receiver={targetable} />;
      },
      minSize: 139,
      maxSize: 139,
    }),
    columnHelper.accessor((row) => row, {
      id: "newHireJourney",
      header: "New Hire",
      cell: (row) => (
        <Link
          as={RouterLink}
          to={{
            pathname: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, {
              id: row.getValue()?.scheduledWorkflow?.onboardingJourneyId || "",
            }),
          }}
        >
          {(row.getValue() as AssignedAction)?.onboardingJourney?.user?.fullName}
        </Link>
      ),
      minSize: 139,
      maxSize: 139,
    }),
    columnHelper.accessor((row) => row, {
      id: "taskName",
      header: "Name",
      cell: (row) => (
        <Link
          as={RouterLink}
          to={{
            pathname: generatePath(PEOPLE_TEAM_ROUTES.assignedActions.show, {
              id: row.getValue()?.id,
            }),
          }}
        >
          {row.getValue().workflowAction.actionable.name}
        </Link>
      ),
      size: 60,
      minSize: 60,
      maxSize: 60,
    }),
    columnHelper.accessor((row) => row, {
      id: "referenceTrigger",
      cell: (row) => (row.getValue()?.trigger.data.customized
        ? `${EMOJI_ICONS.triggerAt} Manually Set`
        : `${EMOJI_ICONS.triggerAt} ${automationTriggerText({
          ...row.getValue()?.workflowAction?.actionable.trigger,
        })}`),
      minSize: 40,
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row, {
      id: "trigger",
      header: "Scheduled",
      cell: (row) => <TriggerComponent action={row.getValue()} onUpdateSuccess={onUpdateSuccess} />,
      minSize: 40,
      sortingFn: (a, b) => scheduledTriggerSort(a.original.trigger, b.original.trigger),
    }),
    columnHelper.accessor((row) => row, {
      id: "status",
      header: "Status",
      cell: (row) => (
        <Tooltip label={(row.getValue().issues || []).map((i) => i.message).join(", ")}>
          <Tag
            textAlign="center"
            paddingY="4px"
            size="md"
            variant="solid"
            colorScheme={scheduledActionToStatusColor(row.getValue())}
          >
            {scheduledActionStatusConverter(row.getValue().status)}
          </Tag>
        </Tooltip>
      ),
      maxSize: 139,
      minSize: 139,
    }),
    columnHelper.accessor((row) => row, {
      id: "moreOptions",
      header: "",
      cell: (row) => (
        <ScheduledActionRowButton
          scheduledAction={row.getValue()}
          status={row.getValue().status}
          onPause={() => onActionPause(row.getValue() as AssignedAction)}
          onRemove={() => onActionDelete(row.getValue() as AssignedAction)}
          onTestSend={() => onPreview(row.getValue().id)}
          testSendIsDisabled={
            !["email", "chat", "task"].includes(row.getValue()?.workflowAction?.actionable?.actionType)
          }
          onSendNow={() => onActionSend(row.getValue() as AssignedAction)}
        />
      ),
    }),
  ];
  return columnDefs;
};

interface AssignedActionsTableProps {
  data: BaseAssignedAction[];
  tableDefaultSort?: { id: string; desc: boolean };
  emptyStateComponent?: React.ReactNode;
  isLoaded?: boolean;
}

interface AlertState {
  isOpen: boolean;
  isFailure: boolean;
}

export default function AssignedActionsTable({
  data,
  isLoaded,
  tableDefaultSort,
  emptyStateComponent,
}: AssignedActionsTableProps) {
  const [alertState, setAlertState] = useState<AlertState>({ isOpen: false, isFailure: false });
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const queryClient = useQueryClient();
  const toast = useToast({
    title: "Success!",
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });

  const handleTestSend = async (id: string) => {
    try {
      const resp = await apiClient.post<{ success: boolean }>(
        generatePath(API_ROUTES.assignedActions.testSend, { id }),
        {},
      );
      console.log(resp);
      setAlertState({ ...{ isOpen: true, isFailure: false } });
    } catch (error) {
      setAlertState({ ...{ isOpen: true, isFailure: true } });
    }
  };

  const resetActionQueries = useCallback(
    (scheduledWorkflowId: string, newHireJourneyId: string) => {
      queryClient.invalidateQueries([QUERY_KEYS.assignedPaths, scheduledWorkflowId]);
      queryClient.invalidateQueries([QUERY_KEYS.newHireJourneyActions, newHireJourneyId]);
      queryClient.invalidateQueries([QUERY_KEYS.assignedActions, "issues"]);
    },
    [queryClient],
  );

  const createActionRequest = useCallback(
    (activityType: "DELETE" | "PAUSE" | "UPDATE" | "SEND", actionUrlString: string, successMessage?: string) => async (action: AssignedAction, payload = {}) => {
      try {
        let resp;
        switch (activityType) {
          case "DELETE":
            resp = await apiClient.delete<{ success: boolean }>(generatePath(actionUrlString, { id: action.id }));
            break;
          case "UPDATE":
            resp = await apiClient.put<{ success: boolean }>(
              generatePath(actionUrlString, { id: action.id }),
              payload,
            );
            break;
          case "PAUSE":
          case "SEND":
            resp = await apiClient.post<{ success: boolean }>(
              generatePath(actionUrlString, { id: action.id }),
              payload,
            );
            break;
          default:
            throw new Error("Invalid method");
            break;
        }
        console.log(resp);
        if (successMessage) {
          toast({ title: successMessage });
        }
        resetActionQueries(action.scheduledWorkflowId, action?.scheduledWorkflow?.onboardingJourneyId);
      } catch (error) {
        console.log(error);
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
        });
      }
    },
    [resetActionQueries, toast],
  );

  const onClose = () => {
    setAlertState({ isOpen: false, isFailure: false });
  };

  const handleSend = createActionRequest("SEND", API_ROUTES.assignedActions.forceSend, "Action Successfully Sent");
  const handlePause = createActionRequest("PAUSE", API_ROUTES.assignedActions.pause, "Success");
  const handleDestroy = createActionRequest("DELETE", API_ROUTES.assignedActions.show, "Action Deleted");
  const handleUpdate = createActionRequest("UPDATE", API_ROUTES.assignedActions.show, "Action Updated");

  const columns = makeColumns(
    (id) => handleTestSend(id),
    (action) => handleSend(action),
    (action) => handlePause(action),
    (action) => handleDestroy(action),
    (action, payload) => handleUpdate(action, payload),
  );

  const tableProps: ControlledDataTableProps<BaseAssignedAction | AssignedAction> = {
    data,
    columns,
    initialPagination: { pageSize: 20, pageIndex: 0 },
    defaultSort: [tableDefaultSort],
    textWrap: true,
    emptyStateComponent: isLoaded ? emptyStateComponent : null,
    isLoading: !isLoaded,
  };

  return (
    <>
      <ControlledDataTable {...tableProps} />
      <Alert
        title={alertState.isFailure ? ALERT_FAILURE_TITLE : ALERT_SUCCESS_TITLE}
        body={
          alertState.isFailure
            ? ALERT_FAILURE_BODY(user?.fullName as string)
            : ALERT_SUCCESS_BODY(user?.fullName as string)
        }
        buttonTitle={ALERT_BUTTON_TITLE}
        isOpen={alertState.isOpen}
        onClose={onClose}
      />
    </>
  );
}

AssignedActionsTable.defaultProps = {
  tableDefaultSort: { id: "trigger", desc: false },
  emptyStateComponent: null,
  isLoaded: true,
};

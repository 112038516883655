import { Text, VStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface EmptyStateProps extends PropsWithChildren {
  message: string;
  textProps?: object;
}

export default function EmptyState({ message, textProps = {}, children }: EmptyStateProps) {
  return (
    <VStack p={16} spacing={4} justifyContent="center" alignItems="center" h="100%" w="100%">
      {children}
      <Text fontSize="2xl" color="fg.subtle" whiteSpace="pre-line" textAlign="center" {...textProps}>
        {message}
      </Text>
    </VStack>
  );
}

EmptyState.defaultProps = {
  textProps: {},
};

/* eslint-disable no-nested-ternary */
import { generatePath, useLocation, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import NewOnboardingPlanStep from "components/OnboardingPlanWizard/NewOnboardingPlanStep";
import OnboardingPlanWizard from "components/OnboardingPlanWizard";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import BasicInfoCard, { AttributeAccessor } from "components/Card/BasicInfoCard";
import { QUERY_KEYS } from "definitions/constants";
import { OnboardingPlan } from "models/onboarding";

// If the URL path is "onboarding-plans/new", it will render the New Onboarding Plan Step (where the user can create a new
// plan or edit an old one. If the URL path is "onboarding-plans/{some id}/edit", it will render the onboarding plan wizard
// to either create or edit the onboarding plan

const fieldDefinitions: AttributeAccessor<Partial<OnboardingPlan>>[] = [
  {
    label: "ID",
    attribute: "id",
  },
  {
    label: "New Hire",
    attribute: (plan) => plan.user?.fullName || "",
  },
  {
    label: "Manager",
    attribute: (plan) => plan.manager?.fullName || "",
  },
  {
    label: "Buddy",
    attribute: (plan) => plan.buddy?.fullName || "",
  },
  {
    label: "# Connections",
    attribute: (plan) => `${plan.connections?.length || 0}`,
  },
  {
    label: "Created At",
    attribute: "createdAt",
  },
  {
    label: "Updated At",
    attribute: "updatedAt",
  },
];

export default function OnboardingPlanPage() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const { data, isLoading, error } = useQuery<OnboardingPlan>({
    queryKey: [QUERY_KEYS.onboardingPlan, id],
    queryFn: () => apiClient.get(generatePath(API_ROUTES.onboardingPlans.show, { id })),
    enabled: id !== "new",
  });

  if (id !== "new") {
    if (isLoading) return "Loading...";
    if (error) return `An error has occurred: ${(error as any).message}`;
    if (!isLoading && !data) return `An error has occurred: ${(error as any).message}`;
  }

  return (
    <AppPage>
      {data ? (
        location.pathname.endsWith("edit") ? (
          <OnboardingPlanWizard onboardingPlan={data} />
        ) : (
          <BasicInfoCard fieldDefinitions={fieldDefinitions} header="Onboarding Plan" data={data} />
        )
      ) : id === "new" ? (
        <NewOnboardingPlanStep />
      ) : null}
    </AppPage>
  );
}

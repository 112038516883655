import { Box, BoxProps } from "@chakra-ui/react";

function NotificationBadge({ count, ...rest }: { count: number } & BoxProps) {
  return (
    <Box
      position="relative"
      top="-2rem"
      right="-1.25rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="1.25rem"
      height="1.25rem"
      bg="red.500"
      color="white"
      fontSize="xs"
      fontWeight="bold"
      lineHeight="1"
      borderRadius="full"
      {...rest}
    >
      {count}
    </Box>
  );
}

export default NotificationBadge;

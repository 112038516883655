import {
  Menu, MenuButton, MenuList, MenuItem, Avatar, MenuDivider,
} from "@chakra-ui/react";
import { API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { Link, redirect } from "react-router-dom";
import useCaminoStore from "hooks/useCaminoStore";

export default function AvatarDropdown() {
  const [user, setValue] = useCaminoStore((state) => [state.currentUser, state.setValue]);

  const logout = () => {
    apiClient.post(API_ROUTES.auth.logout, {}).then(() => {
      setValue({
        currentUser: null, currentCompany: null, currentNewHireJourney: null, currentRole: null,
      });
      redirect(SHARED_ROUTES.login);
      // Reload to refresh the CSRF token
      window.location.reload();
    });
  };

  if (!user) {
    console.log(`ERROR: AVATAR DROPDOWN WAS UNABLE TO FIND USER`);
    return null;
  }

  return (
    <Menu>
      <MenuButton>
        <Avatar size="md" src={user.avatarUrl} name={user.fullName} />
      </MenuButton>
      <MenuList>
        <MenuItem as={Link} to={SHARED_ROUTES.profile}>
          Profile
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={logout}>Sign Out</MenuItem>
      </MenuList>
    </Menu>
  );
}

import { OptionBase, Select } from "chakra-react-select";
import { Box } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import ROLE from "models/role";
import { areSameRoleFromPaths, redirectPathByRole } from "utils/role";
import useCaminoStore from "hooks/useCaminoStore";

export interface RoleSelectionOption extends OptionBase {
  label: string;
  value: ROLE;
}

const selectOptions: RoleSelectionOption[] = [
  {
    label: "Basic Employee",
    value: ROLE.BASIC,
  },
  {
    label: "Manager",
    value: ROLE.MANAGER,
  },
  {
    label: "People Team",
    value: ROLE.PEOPLE_TEAM,
  },
  {
    label: "Admin",
    value: ROLE.COMPANY_ADMIN,
  },
  {
    label: "Onboarder",
    value: ROLE.ONBOARDER,
  },
];

export function getAvailableRolesOptions(userRoles: ROLE[]) {
  return selectOptions.filter(({ value }) => (userRoles as string[]).includes(value));
}

export function SelectCurrentRole() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentRole, setCurrentRole, userRoles] = useCaminoStore((state) => [
    state.currentRole,
    (arg0: ROLE) => state.setValue({ currentRole: arg0 }),
    state.currentUser?.permission || [],
  ]);
  const availableRolesOptions = getAvailableRolesOptions(userRoles);
  const currentOption = availableRolesOptions.find((option) => option.value === currentRole) || availableRolesOptions[0];

  const setCurrentUserRoleAndRedirect = (role: ROLE) => {
    if (!role) {
      console.log(`ERROR: ROLE SELECTOR WAS UNABLE TO FIND ROLE`);
      return;
    }
    setCurrentRole(role);
    const navigatePath = redirectPathByRole(role);
    // prevent a repainting of the dom if they are already in the same path
    // otherwise, redirect
    if (navigatePath && !areSameRoleFromPaths(location.pathname, navigatePath)) {
      navigate(navigatePath);
    }
  };

  if (availableRolesOptions.length <= 1) return null;

  return (
    <Box width="160px">
      <Select
        useBasicStyles
        name="CurrentRole"
        options={availableRolesOptions}
        onChange={(value) => {
          if (value) setCurrentUserRoleAndRedirect(value.value);
        }}
        value={currentOption}
        defaultValue={currentOption}
        colorScheme="blue"
        chakraStyles={{
          valueContainer: (provided) => ({
            ...provided,
            className: "single-select",
          }),
        }}
      />
    </Box>
  );
}

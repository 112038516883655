import { ExtendedNewHireJourney } from "models/joinedModels";
import { Card, CardBody, Flex, Text } from "@chakra-ui/react";
import { Steps } from "components/Steps";
import { useSearchParams } from "react-router-dom";
import { relativeDate } from "helpers/time";
import AddProfileInfo from "./AddProfileInfoStep";
import AddPathsStep from "./AddPathsStep";
import AddAdditionalProfileInfo from "./AddAdditionalProfileInfoStep";
import AddSupportTeamStep from "./AddSupportTeamStep";
import ReviewEmailsStep from "./ReviewEmailsStep";
import ReviewChatMessagesStep from "./ReviewChatMessagesStep";
import ReviewTasksStep from "./ReviewTasksStep";
import ReviewEverythingStep from "./ReviewEverythingStep";
import AddOnboardingPlanStep from "./AddOnboardingPlanStep";
import AddStartDateAndEmailStep from "./AddStartDateAndEmailStep";
import InitialStep from "./InitialStep";

const STEP_QUERY_PARAM = "step";

interface NewHireJourneyEditFormProps {
  newHireJourney: ExtendedNewHireJourney;
  onClose?: () => void;
}

export default function NewHirePendingForm({ newHireJourney, onClose = () => {} }: NewHireJourneyEditFormProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const stepIndex = Number(searchParams.get(STEP_QUERY_PARAM)) || 0;
  const setStepIdx = (index: number) => setSearchParams(
    (params) => {
      params.set(STEP_QUERY_PARAM, String(index));
      return params;
    },
    { replace: true },
  );

  return (
    <Card width="700px" {...(stepIndex !== 0 ? { position: "relative" } : {})}>
      {stepIndex !== 0 && (
        <Flex
          width="100%"
          position="absolute"
          top="8"
          left="50%"
          transform="translateX(-50%)"
          textAlign="center"
          gap="4"
          justifyContent="center"
        >
          <Text color="fg.subtle" fontWeight="bold">
            {newHireJourney?.user?.fullName}
          </Text>
          {newHireJourney?.user?.title && <Text color="fg.subtle">{newHireJourney?.user?.title}</Text>}
          <Text color="fg.subtle">{relativeDate(newHireJourney?.startDate)}</Text>
        </Flex>
      )}
      <CardBody>
        <Steps
          handleComplete={() => {
            onClose();
          }}
          submitButtonTitle="Kickoff Pre-Boarding"
          onChange={setStepIdx}
          defaultIndex={stepIndex}
          controlProps={{
            bottom: "0",
            position: "relative",
            mb: "8",
            mr: "12",
          }}
        >
          {InitialStep({ newHireJourney })}
          {AddPathsStep({ newHireJourney })}
          {AddStartDateAndEmailStep({ newHireJourney })}
          {AddProfileInfo({ newHireJourney })}
          {AddAdditionalProfileInfo({ newHireJourney })}
          {AddSupportTeamStep({ newHireJourney })}
          {AddOnboardingPlanStep({ newHireJourney })}
          {ReviewEmailsStep({ newHireJourney })}
          {ReviewChatMessagesStep({ newHireJourney })}
          {ReviewTasksStep({ newHireJourney })}
          {ReviewEverythingStep({ newHireJourney })}
        </Steps>
      </CardBody>
    </Card>
  );
}

NewHirePendingForm.defaultProps = {
  onClose: () => {},
};

/* eslint-disable object-curly-newline */
import { Avatar, Card, CardProps, HStack, Heading, IconButton, Link, Skeleton, Text, VStack } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { User } from "models/user";
import { Link as RouterLink } from "react-router-dom";
import { MdOutlineModeEditOutline } from "react-icons/md";
import NewHireConnectionFormWrapper from "components/ModalForm/UpdateNewHireConnectionForm";
import { NewHireJourney } from "models/onboarding";

interface SupportProfileCardProps extends CardProps {
  connectionType: string;
  newHireJourney: NewHireJourney;
  employee?: User;
  tbd?: boolean;
  messageProvider?: string;
  useFullname?: boolean;
  attributeName?: string;
  linkTo?: string;
  editable?: boolean;
}

export default function SupportProfileCard({
  employee,
  useFullname,
  tbd,
  connectionType,
  attributeName,
  newHireJourney,
  messageProvider,
  linkTo,
  editable,
  ...rest
}: SupportProfileCardProps) {
  const avatarProps = employee ? { src: employee?.avatarUrl, name: employee?.fullName } : { name: "TBD" };
  // eslint-disable-next-line no-nested-ternary
  const displayName = employee ? (useFullname ? employee?.fullName : employee?.firstName) : "TBD";

  return (
    <Card
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius="8px"
      border="1px solid var(--stroke-vibes, #F1F1F1)"
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
      paddingLeft="5"
      paddingRight="3"
      paddingY={4}
      maxW="260"
      minW="260"
      mt={4}
      {...rest}
    >
      <Skeleton isLoaded={!!employee || !!tbd} height="100%">
        <VStack justifyContent="flex-start" alignItems="start" height="100%">
          <HStack width="100%" justify={editable ? "space-between" : "flex-start"}>
            <Heading as="h6" size="xxs">
              {connectionType}
            </Heading>
            {editable && (
              <NewHireConnectionFormWrapper
                newHireJourneyId={newHireJourney?.id}
                newHireUserId={newHireJourney?.user?.id}
                attributeValue={employee}
                label={connectionType}
                attributeName={attributeName as string}
              >
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="See menu"
                  icon={<MdOutlineModeEditOutline />}
                />
              </NewHireConnectionFormWrapper>
            )}
          </HStack>
          <HStack gap="24px" alignItems="center" justify="start">
            <Avatar size="md" {...avatarProps} />
            <VStack alignItems="start" gap="0">
              {linkTo && displayName !== "TBD" ? (
                <RouterLink to={linkTo}>
                  <Text fontWeight="400" fontSize={18}>
                    {displayName}
                  </Text>
                </RouterLink>
              ) : (
                <Text fontWeight="400" fontSize={18}>
                  {displayName}
                </Text>
              )}
              {messageProvider && employee?.slack && (
                <Link href={employee?.slack} isExternal>
                  {messageProvider}
                  {" "}
                  <ExternalLinkIcon mx="2px" />
                </Link>
              )}
            </VStack>
          </HStack>
        </VStack>
      </Skeleton>
    </Card>
  );
}

SupportProfileCard.defaultProps = {
  messageProvider: "",
  tbd: false,
  useFullname: false,
  employee: null,
  linkTo: null,
  editable: false,
  attributeName: null,
};

import { useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import CohortCard from "components/Card/CohortCard";
import { Cohort } from "models/cohort";
import { Spinner } from "@chakra-ui/react";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useApiClient from "hooks/useApiClient";

export default function CohortPage() {
  const { id } = useParams<{ id: string }>();

  if (!id) throw new Error("No cohort id provided");

  const { data: cohort, loading, error } = useApiClient<Cohort>(`${API_ROUTES.cohorts}/${id}`, { method: "GET" });

  if (loading) {
    return (
      <AppPage>
        <Spinner />
      </AppPage>
    );
  }

  if (error) return <AppPage>{error.errors}</AppPage>;

  return (
    <AppPage>
      <CohortCard cohort={cohort} />
    </AppPage>
  );
}

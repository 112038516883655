// eslint-disable-next-line object-curly-newline
import { Box, Stack, HStack, Avatar, Text } from "@chakra-ui/react";
import { Connection } from "models/connection";

interface ConnectionCardProps {
  connection: Connection;
}

export default function ConnectionCard({ connection }: ConnectionCardProps) {
  const { contact } = connection;
  if (!contact) {
    return (
      <Text as="h1" color="fg.muted">
        Waiting for Contact
      </Text>
    );
  }
  return (
    <Stack
      key={connection.id}
      fontSize="sm"
      spacing="4"
      bg="bg.surface"
      p="4"
      boxShadow="sm"
      borderRadius="lg"
      maxWidth={500}
    >
      <Stack direction="row" justify="space-between" spacing="4">
        <HStack spacing="3">
          <Avatar bg="brand.500" name={contact.fullName || ""} src={contact.avatarUrl} boxSize="10" />
          <Box>
            <Text fontWeight="medium" color="fg.emphasized">
              {contact.fullName}
            </Text>
            <Text color="fg.muted">{contact.title}</Text>
          </Box>
        </HStack>
        <Text color="fg.muted">{contact.workEmail}</Text>
      </Stack>
      <Text
        color="fg.muted"
        sx={{
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: "2",
          overflow: "hidden",
          display: "-webkit-box",
        }}
      >
        {connection.description}
      </Text>
    </Stack>
  );
}

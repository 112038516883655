import {
  Button, ButtonGroup, Container, Stack,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { Helpers } from "hooks/useStep";
import Stepper from "./Stepper";
import Step from "./Step";

interface StepProgressBarProps extends PropsWithChildren, Partial<Helpers> {
  steps: Step[];
  currentStepIndex: number;
  setNextStep: (step: number) => void;
  onFinish?: () => void;
}

function StepProgressBar({
  steps,
  currentStepIndex,
  setNextStep,
  goToPrevStep,
  goToNextStep,
  canGoToNextStep,
  canGoToPrevStep,
  onFinish,
}: StepProgressBarProps) {
  return (
    <Container py={{ base: "8", md: "16" }}>
      <Stack direction={{ base: "column", md: "row" }} spacing="4">
        {steps.map((step, idx) => (
          <Stepper
            key={step + step.id}
            cursor="pointer"
            onClick={() => setNextStep(idx)}
            title={step.title}
            description={step.description}
            isActive={currentStepIndex === idx}
            isCompleted={currentStepIndex > idx}
          />
        ))}
        <ButtonGroup>
          <Button w="165px" h="48px" variant="outline" isDisabled={!canGoToPrevStep} onClick={goToPrevStep}>
            Previous
          </Button>
          {/* If "onFinish" is defined, on the last step, it will not disable but instead handle "onFinish" with a "Finish"  */}
          {/* text instead of "Next"  */}
          {onFinish ? (
            <Button
              w="165px"
              h="48px"
              colorScheme={canGoToNextStep ? "brand" : "cyan"}
              onClick={canGoToNextStep ? goToNextStep : onFinish}
            >
              {canGoToNextStep ? "Next" : "Finish"}
            </Button>
          ) : (
            <Button w="165px" h="48px" isDisabled={!canGoToNextStep} onClick={goToNextStep}>
              Next
            </Button>
          )}
        </ButtonGroup>
      </Stack>
    </Container>
  );
}
StepProgressBar.defaultProps = {
  onFinish: null,
};

export default StepProgressBar;
